import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";

interface ProgressElementProps {
    title: string;
    percent: number;
    isTableComponent?: boolean
}

export const ProgressElement = ({ title, percent, isTableComponent = false }: ProgressElementProps) => {
    return (
        <Box padding={isTableComponent ? "0px" : "10px 24px"} bg={isTableComponent ? "none" : "#FFFFFF"} borderRadius={8} border={isTableComponent ? "none" : "1px solid #E3E7EF"} width="100%" >
            <Flex gap={isTableComponent ? "8px" : "24px"} align="center">
                <CircularProgress value={percent} size={isTableComponent ? "20px" : "44px"} color="#2AB6A5" trackColor="#E3E7EF" />
                <Text>{title} — {percent}%</Text>
            </Flex>
        </Box>
    );
}