import { IDetail } from "../api/estimates/types";
import { IWorkCompletedActDetail } from "../api/workCompletedActs/types";

export const estimateDetailsWithVat = (
  estimatesDetails: IDetail[] | undefined,
  actDetails: IWorkCompletedActDetail[] | undefined
) => {
  if (!estimatesDetails || !actDetails) {
    return estimatesDetails;
  }

  const map2 = new Map(
    actDetails.map((item) => [
      `${item.type_of_work_guid}-${item.construction_object_guid}`,
      item,
    ])
  );

  const newArray1 = estimatesDetails.map((item) => {
    const key = `${item.TypeofWorkID}-${item.ConstructionObjectID}`;
    if (map2.has(key)) {
      const vat = map2.get(key)?.vat;
      if (!item.hasOwnProperty("vat")) {
        return { ...item, vat: vat };
      }
    }
    return item;
  });
  return newArray1;
};