import { AxiosPromise } from "axios";
import { ISupplierResponse } from "./types";
import { axiosInstance } from "../instance";

export const getSupplier = (id: string): AxiosPromise<ISupplierResponse> =>
  axiosInstance.get(`/supplier/by-inn/${id}`);

export const getSupplierByGuid = (
  id: string
): AxiosPromise<ISupplierResponse> =>
  axiosInstance.get(`/supplier/by-guid/${id}`);
