import { AxiosResponse } from "axios";
import { IExportLzkRequest } from "../api/limitFenceCards/types";
import { exportMaterials } from "../api/limitFenceCards";

export const downloadExportMaterials = (
  data: IExportLzkRequest,
  setIsSpinnerRuning?: (arg: boolean) => void
) => {
  setIsSpinnerRuning && setIsSpinnerRuning(true);
  exportMaterials(data)
    .then((res: AxiosResponse<Blob>) => {
      const contentDisposition = res.headers["content-disposition"];
      let filename = "unknown";

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=([^;]+)/);
        filename = filenameMatch[1];
      }

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .finally(() => setIsSpinnerRuning && setIsSpinnerRuning(false));
};