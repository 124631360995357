import { AuthLayout } from "../../components/AuthLayout/AuthLayout";
import { AuthTitle } from "../../components/AuthTitle/AuthTitle";
import { CustomLink } from "../../components/lib/customLink/CustomLink";
import Vector from "../../images/svg/vector/vector.svg";

export const PasswordRecovery = () => {
  return (
    <AuthLayout>
      <AuthTitle
        title="Запрос принят"
        subtitle="Ссылка на восстановление пароля будет отправлена на указанный e-mail."
        isSuccess
      />
      <CustomLink content="Назад к авторизации" href="/" icon={Vector} />
    </AuthLayout>
  );
};
