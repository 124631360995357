import { useFormik } from "formik";
import * as Yup from "yup";
import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/lib/button/Button";
import { PasswordInput } from "../../components/lib/input/PasswordInput";
import { AuthLayout } from "../../components/AuthLayout/AuthLayout";
import { AuthTitle } from "../../components/AuthTitle/AuthTitle";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { IPasswordResetRequest } from "../../api/auth/types";
import { useAppDispatch } from "../../store";
import { resetUserPassword } from "../../store/slices/auth";

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.[a-zA-Z]).{8,}$/;

export const PasswordCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');

  const onSubmit = (values: IPasswordResetRequest) => {
    dispatch(resetUserPassword(values))
      .then(() => {
        navigate("/login")
      })
  };

  const formik = useFormik({
    initialValues: {
      email: email || "",
      token: token || "",
      password: "",
      c_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          PASSWORD_REGEX,
          "Пароль должен включать минимум 1 заглавную букву, цифру и быть не короче 8-ми символов"
        )
        .required("Заполните все поля"),
        c_password: Yup.string()
        .oneOf([Yup.ref("password")], "Пароли не совпадают")
        .required("Заполните все поля"),
    }),
    onSubmit,
  });

  const renderErrorMessage = () => {
    if (formik.touched.password && formik.errors.password) {
      return <ErrorMessage message={formik.errors.password} />;
    }
    if (formik.touched.c_password && formik.errors.c_password) {
      return <ErrorMessage message={formik.errors.c_password} />;
    }

    return <></>;
  };

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit}>
        <AuthTitle title="Создание пароля" subtitle="Придумайте новый пароль" />
        <Flex direction="column" gap={4}>
          {renderErrorMessage()}
          <PasswordInput
            placeholder="Введите новый пароль"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
          />
          <PasswordInput
            placeholder="Повторите новый пароль"
            name="c_password"
            value={formik.values.c_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.c_password &&
              !!formik.errors.c_password
            }
          />
        </Flex>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </form>
    </AuthLayout>
  );
};
