import { SelectHTMLAttributes } from "react";
import classNames from "classnames";
import { ReactComponent as ArrowDown } from "../../../images/svg/arrows/arrow-down-green.svg";
import styles from "./select.module.scss";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  error?: boolean;
  options?: string[];
}

export function Select(props: SelectProps) {
  const {
    label,
    name,
    onChange,
    value = "",
    options = [],
    error,
    onBlur,
    ...rest
  } = props;

  const selectClass = classNames(styles.container, {
    [styles.error]: error,
  });

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={selectClass}>
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        >
          <option disabled value="">
            Выберите из списка
          </option>
          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className={styles.image_wrapper}>
          <ArrowDown />
        </div>
      </div>
    </div>
  );
}
