import { Link } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { PrimaryButton } from "../../components/lib/button/Button";

export function NotFoundPage() {
  return (
    <MainLayout>
      <Box p={6} h="full">
        <Flex
          align="center"
          justify="center"
          bg="white"
          border="1px solid #ebeef5"
          rounded="lg"
          h="full"
        >
          <Flex gap={4} direction="column">
            <Text
              textAlign="center"
              fontSize="120px"
              lineHeight="120px"
              fontWeight={700}
              letterSpacing="4px"
              color="#E8363D"
            >
              404
            </Text>
            <Title textAlign="center">Страница не найдена</Title>
            <Text lineHeight="16px" color="#8089A2">
              Возможно, она была удалена, перемещена, или ещё не создана
            </Text>
            <Flex mt={5} justify="center">
              <Link to="/profile">
                <PrimaryButton>На главную</PrimaryButton>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </MainLayout>
  );
}
