import { Input } from "../../lib/input/Input";
import { useFormik } from "formik";
import { fetchChangeFIO } from "../../../store/slices/profile";
import { useAppDispatch } from "../../../store";
import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../../lib/button/Button";

type Props = {
  afterSubmitActions?: () => void
}

export function ChangeNameModal({ afterSubmitActions }: Props) {
  const dispatch = useAppDispatch();
  const onSubmit = async (values: { name: string; surname: string; patronymic: string }) => {
      await dispatch(fetchChangeFIO(values)) 
      afterSubmitActions?.();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      patronymic: "",
    },

    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex flexDirection={'column'} gap={4}>
        <Input
          placeholder="Введите фамилию"
          name="surname"
          value={formik.values.surname}
          onChange={formik.handleChange}
        />
        <Input
          placeholder="Введите имя"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <Input
          placeholder="Введите отчество"
          name="patronymic"
          value={formik.values.patronymic}
          onChange={formik.handleChange}
        />
      </Flex>
      <PrimaryButton type="submit">Сохранить</PrimaryButton>
    </form>
  );
}
