import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react";
import { ReactComponent as Book } from "../../images/svg/sidebar/book.svg";
import { TutorialModal } from "../Modal/ModalCenter/TutorialModal";
import { useState } from "react";
import { IOnboardingScreen, IUserScreensResponse } from "../../api/onboarding/types";
import { AxiosResponse } from "axios";
import { getScreensByPermission } from "../../api/onboarding";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { RootState, useAppSelector } from "../../store";
import { checkPermission } from "../../helpers/checkPermission";

interface OnboardingByPermissionProps {
  permission: AccessRights
}

export function OnboardingByPermission(props: OnboardingByPermissionProps) {

  const { existOnboarding } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [screens, setScreens] = useState<IOnboardingScreen[]>([]);

  const handleClick = async () => {
    try {
      const response: AxiosResponse<IUserScreensResponse> = await getScreensByPermission(props.permission);
      setScreens(response.data.onboarding_screens);
      onOpen();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    checkPermission(props.permission, existOnboarding) 
    ?
    <Box mt={6}>
      <Link
        w="min-content"
        isExternal
        _hover={{ color: "#343B4C", svg: { fill: "#343B4C" } }}
        display="flex"
        alignItems="center"
        lineHeight="20px"
        gap={2}
        // marginTop={10}
        color="#5C657E"
        onClick={handleClick}
      >
        <ExternalLinkIcon as={Book} fill="#5C657E" />
        <Text>Онбординг</Text>
      </Link>
      <TutorialModal
        active={isOpen}
        onClose={onClose}
        screens={screens.map((screen) => screen.content)}
        permission={props.permission}
      />
    </Box>
    :
    <></>
  );
}
