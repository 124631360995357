import { PrimaryButton } from "../lib/button/Button";
import { useEffect, useState } from "react";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { ReportsDropdownList } from "./ReportsDropdownList";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchContractReport, fetchReportContractsList } from "../../store/slices/reports";
import { IReportDocument } from "../../api/reports/types";
import { Box } from "@chakra-ui/react";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

export function ExecutionContractsComponent() {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [resultContract, setResultContract] = useState<
  IReportDocument
  >();
  const { contractsList } = useAppSelector(
    (state: RootState) => state.report
  );

  const handleSendReport = () => {
    setIsOpenModal(true);
    dispatch(fetchContractReport({ contract_id: resultContract?.id ? resultContract.id : null }))
      .finally(() => {
        setIsOpenModal(false)
      })
  }

  useEffect(() => {
    dispatch(fetchReportContractsList())
  }, [dispatch])

  return (
    <>
      <ReportsDropdownList
        selectAllButtonText="Все договоры"
        returnResult={setResultContract}
        inputPlaceholder="Например «Договор 123»"
        documents={contractsList}
      />
      <PrimaryButton onClick={handleSendReport} w="165px">
        Сформировать
      </PrimaryButton>
      <Box>
        <OnboardingByPermission permission={AccessRights.ReportContract_RW} />
      </Box>
      <FullScreenWaitingModal openModal={isOpenModal} />
    </>
  );
}
