import { toast, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalModal } from 'react-global-modal'
import { PrimaryButton } from '../lib/button/Button';

export const ShowNotification = (status:number) => {

    const notify = () => toast("Что-то пошло не так. Попробуйте снова", 
        {transition: Zoom, closeOnClick:false, className: "notify__class", autoClose: 2000,
        hideProgressBar: true,})


    const openModalPermission = async () => {
        GlobalModal.push({
          component: PermissionErrorModal,
          hideHeader: true,
          modalSize: "xs",
          contentClassName: "modalError",
          onClose: () => {
            window.location.href="/profile"
        },
        })
      }
    const openModalAuth = async () => {
        GlobalModal.push({
          component: AuthErrorModal,
          hideHeader: true,
          modalSize: "xs",
          contentClassName: "modalError",
          onClose: () => {
            localStorage.removeItem("token");
            window.location.href="/"
        },
        })
      }

    if(status == 403){
        openModalPermission()
    }
    else if(status == 401){
        openModalAuth()
    }
    else{
        notify()
    }

}

const PermissionErrorModal = () => {
    return (
        <div>
            <p className='text'>Доступ запрещен</p>
            <PrimaryButton className='button' onClick={() => {
                GlobalModal.closeAll()
                window.location.href = "/profile"
                }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}

const AuthErrorModal = () => {
    return (
        <div>
            <p className='text'>Ошибка авторизации</p>
            <PrimaryButton className='button' onClick={() => {
                localStorage.removeItem("token");
                GlobalModal.closeAll()
                window.location.href="/"
                }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}


