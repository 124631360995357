import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, AddIcon } from "@chakra-ui/icons";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { BlockTitle, Title } from "../lib/title/Title";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import SuccessLogo from "../../images/png/success.png";
import { ChakraModalProps } from "../../shared/types/modalProps";
import { useEffect, useState } from "react";
import close from "../../images/svg/close/close.svg"


interface CommentProps {
  isEdited?: boolean;
  handleChangeComment?: (text:string) => void;
  currentComment?: string;
  title?: string;
  setDisableApprove?: (value: boolean) => void;
}

export function Comment(props: Readonly<CommentProps>) {
  const {isEdited, handleChangeComment, currentComment = "", title, setDisableApprove} = props
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ comment,setComment ] = useState('');
  const [ inputValue,setInputValue ] = useState('');

  function handleChangeCommentInput(text:string) {
    setInputValue(text);
    setDisableApprove && setDisableApprove(true);
  }

  const handleSaveComment = () => {
    setComment(inputValue)
  }

  useEffect(() => {
    if (handleChangeComment){
      handleChangeComment(comment)
    }
  },[handleChangeComment, comment])

  useEffect(() => {
    setComment(currentComment);
  }, [currentComment]);

  return (
    <>
      <Flex
        bg="white"
        direction="column"
        p="24px"
        gap="24px"
        borderRadius="8px"
        border="1px solid #E3E7EF"
        h="fit-content"
      >
        <Flex align="center" justify="space-between">
          <Text color="#5C657E" lineHeight="20px">
            {title ? title : "Комментарий"}
          </Text>
          {isEdited && 
            comment ? 
            (
              <EditIcon cursor="pointer" color="#8089A2" onClick={onOpen} />
            ) : (isEdited  &&
              <AddIcon cursor="pointer" color="#8089A2" onClick={onOpen} />
            )
          }
        </Flex>
        <Flex gap="16px" flexDirection="column">
          {!comment && isEdited ? (
            <>
              <Title align="center" color="#8089A2" lineHeight="18px" fontSize="14px">Комментария пока нет, но вы можете его добавить</Title>
              <OutlineButton alignSelf="center" onClick={onOpen}>Добавить</OutlineButton>
            </> ) : (
              <Text align="left">{comment}</Text>
            )
          }
          {!comment && !isEdited ? (
            <Title align="center" color="#8089A2" lineHeight="18px" fontSize="14px">Комментарий отсутствует</Title>
            ) : (
              <></>
            )
          }
          
        </Flex>
      </Flex>
      {isEdited && <EditModal isOpen={isOpen} onClose={onClose} handleSaveComment={handleSaveComment} handleChangeCommentInput={handleChangeCommentInput} comment={comment} />}
    </>
  );
}

function EditModal(props: Readonly<ChakraModalProps>) {
  const {onClose, isOpen, handleChangeCommentInput, handleSaveComment, comment} = props
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();
  const handleCommentModalClose = () => {
    onClose();
    onSuccessOpen();
    if (handleSaveComment) {
      handleSaveComment();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Image cursor="pointer" src={close} float="right" onClick={onClose} />
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Комментарий</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Textarea h="360px" defaultValue={comment} onChange={(e) => {
              if (handleChangeCommentInput) {
                handleChangeCommentInput(e.target.value)
              }
            }}/>
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton
              w="full"
              fontSize="16px"
              onClick={handleCommentModalClose}
            >
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const {isOpen, onClose} = props
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Комментарий успешно изменён</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
