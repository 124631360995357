import { Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { ChakraModalProps } from "../../shared/types/modalProps";

export interface IConfirmImportModalProps extends ChakraModalProps {
   inputRef: any
}

export const ConfirmImportModal = ({isOpen, onClose, inputRef}: IConfirmImportModalProps) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay backdropFilter="blur(3px)" />
                <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
                    <ModalHeader p={0}>
                        <Flex flexDirection="column" gap="12px" align="center">
                            <Text
                                fontSize="24px"
                                color="#343b4c"
                                lineHeight="28px"
                                letterSpacing="-1.5%"
                                fontWeight={500}
                                align="center">
                                Предупреждение
                            </Text>
                            <Text fontSize={16} fontWeight={400} color="#5C657E" align="center">При загрузке файла заполненные ранее данные будут удалены</Text>
                        </Flex>
                    </ModalHeader>
                    <ModalFooter p={0}>
                        <Flex w="100%" gap="12px" align="center">
                            <OutlineButton w="full" onClick={onClose}>
                                Отмена
                            </OutlineButton>
                            <PrimaryButton w="full" onClick={() =>{
                                inputRef.current.click()
                                onClose()
                                }}>
                                Подтвердить
                            </PrimaryButton>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )
};