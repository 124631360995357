import {useRef, useState} from "react";
import { Input, InputProps } from "@chakra-ui/react";
import calendar from "../../images/svg/calendar/calendar.svg";

enum InputTypes {
  Date = "date",
}

export function InformationItemDateInput(props: Readonly<InputProps>) {
  const refInput = useRef<HTMLInputElement | null>(null)
  const [type] = useState<InputTypes>(InputTypes.Date);
  const inputStyle = `url(${calendar}) no-repeat 100% 50% content-box`;

  const handleFocus = () => {
    if (refInput.current) {
      refInput.current?.showPicker()
    }
  }

  return (
    <Input
      ref={refInput}
      h="36px"
      rounded="md"
      type={type}
      borderColor="#E3E7EF"
      onFocus={handleFocus}
      bg={inputStyle}
      onClick={(e) => {
        e.stopPropagation()
      }}
      {...props}
    />
  );
}
