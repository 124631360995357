import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import {
  IRequirement,
  IRequirementsListResponse,
  ISingleRequirementResponse,
} from "../../api/requirements/types";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { IPrintFormResponse } from "../../api/directionLetters/types";
import { IFile } from "../../api/files/types";

interface IReportsInitialState extends ICommonInitialState {
  requirementsList: IRequirement[];
  currentReq: IRequirement | null;
  currentReqFiles: IFile[]
}

const initialState: IReportsInitialState = {
  requirementsList: [],
  currentReqFiles: [],
  currentReq: null,
  isLoading: false,
  error: null,
};

export const fetchRequirementsList = createAsyncThunkInstance<
  IRequirementsListResponse,
  undefined
>("requirements/fetchRequirementsList", api.requirements.getRequirementsList);

export const fetchReqFiles = createAsyncThunkInstance<
  ISingleRequirementResponse,
  number
>("requirements/fetchReqFiles", api.requirements.getPrintForm);

const requirementsSlice = createSlice({
  name: "savingsCompensationActs",
  initialState,
  reducers: {
    resetCurrentReqFiles(state) {
      state.currentReqFiles = [];
    },
    setCurrentReq(state, action) {
      state.currentReq = action.payload;
    },
    resetCurrentReq(state) {
      state.currentReq = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequirementsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRequirementsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requirementsList = action.payload.data;
      })
      .addCase(fetchReqFiles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReqFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentReqFiles = action.payload.data.files;
      });
  },
});

export const requirementsReducer = requirementsSlice.reducer;

export const { resetCurrentReqFiles, setCurrentReq, resetCurrentReq } = requirementsSlice.actions;
