import { SortDirection } from "@tanstack/react-table";
import classNames from "classnames";
import { ReactComponent as Sort } from "../../images/svg/sort/sort.svg";
import styles from "./sort-icon.module.scss";

export enum SortingTypes {
  Asc = "asc",
  Desc = "desc",
}

interface SortIconProps {
  sort: SortDirection | boolean;
}

export function SortIcon(props: SortIconProps) {
  const sortClass = classNames(styles.sort_container, {
    [styles.asc]: props.sort === SortingTypes.Asc,
    [styles.desc]: props.sort === SortingTypes.Desc,
  });
  return (
    <div className={sortClass}>
      <Sort />
    </div>
  );
}
