import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { ISigning, ISigningListResponse } from "../../api/signing/types";

interface ISigningInitialState extends ICommonInitialState {
  signingList: ISigning[];
}

const initialState: ISigningInitialState = {
  signingList: [],
  isLoading: false,
  error: null,
};

export const fetchSigningList = createAsyncThunkInstance<
  ISigningListResponse,
  undefined
>("signing/fetchSigningList", api.signing.getSigningList);

const signingSlice = createSlice({
  name: "signing",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSigningList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSigningList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.signingList = action.payload.data;
      });
  },
});

export const signingReducer = signingSlice.reducer;
