import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { ITooltip, ITooltipsResponse } from "../../api/tooltips/types";

interface ITooltipsInitialState extends ICommonInitialState {
  tooltipList: ITooltip[];
}

const initialState: ITooltipsInitialState = {
  tooltipList: [],
  isLoading: false,
  error: null,
};

export const fetchTooltipList = createAsyncThunkInstance<
  ITooltipsResponse,
  undefined
>("tooltips/fetchTooltipList", api.tooltips.getTooltipList);

const tooltipsSlice = createSlice({
  name: "tooltips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTooltipList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTooltipList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tooltipList = action.payload.data;
      });
  },
});

export const tooltipsReducer = tooltipsSlice.reducer;
