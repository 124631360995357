
import { Flex } from "@chakra-ui/react"
import { ReactComponent as Message } from "../../images/svg/sidebar/remark.svg";
import { ReactComponent as Eye } from "../../images/svg/sidebar/plus.svg";
import { ReactComponent as Requirement } from "../../images/svg/sidebar/document.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/sign.svg";
import { BoxItem } from "./BoxItem";
import { InformationBlock } from "./InformationBlock";
import { RootState, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";

export const MainPageComponents = () => {
    const { mainInfo } = useAppSelector(
        (state: RootState) => state.mainInfo
    );
    const navigate = useNavigate();
    return (
        <Flex gap="12px">
            <BoxItem
                headerTitle="Визирование"
                headerIcon={<Eye fill="#8089A2" />}
                onClick={() => navigate("/add-request")}
            >
                <InformationBlock title="Всего" count={mainInfo?.additional_request.count} />
                <InformationBlock title="Просроченные" count={mainInfo?.additional_request.expired} />
            </BoxItem>
            <BoxItem
                headerTitle="Замечания"
                headerIcon={<Message fill="#8089A2" />}
            >
                <InformationBlock
                    onClick={() => navigate("/remarks/construction")}
                    title="При строительстве"
                    count={mainInfo?.construction_control_note.count}
                />
                <InformationBlock
                    onClick={() => navigate("/remarks/transmission")}
                    title="При передаче ОН"
                    count={mainInfo?.transfer_note.count}
                />
            </BoxItem>
            <BoxItem
                headerTitle="Требования"
                headerIcon={<Requirement fill="#8089A2" />}
                onClick={() => navigate("/requirements-account")}
            >
                <InformationBlock title="Всего" count={mainInfo?.requirement.count} />
            </BoxItem>
            <BoxItem
                headerTitle="Подписать"
                headerIcon={<Document fill="#8089A2" />}
                onClick={() => navigate("/sign")}
            >
                <InformationBlock title="Всего" count={mainInfo?.signing.count} />
            </BoxItem>
        </Flex>
    )
}