import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {Files, SavingsCompensationActsEndpoints} from "../endpoints";
import {
  IAddCompensationActResponse,
  ISavingsCompensationActRequest,
  ISavingsCompensationActsResponse,
  ISingleSavingsCompensationActResponse,
  IUpdateCompensationActRequest,
} from "./types";
import { IContractsResponse } from "../contracts/types";

export const getSavingsCompensationActs =
  (): AxiosPromise<ISavingsCompensationActsResponse> =>
    axiosInstance.get(
      SavingsCompensationActsEndpoints.SAVINGS_COMPENSATION_ACTS
    );

export const getCompensationActContracts =
  (): AxiosPromise<IContractsResponse> =>
    axiosInstance.get(
      SavingsCompensationActsEndpoints.SINGLE_SAVINGS_COMPENSATION_ACT + `/contracts`
    );

export const getSavingsCompensationActsByProject = (
  id: number
): AxiosPromise<ISavingsCompensationActsResponse> =>
  axiosInstance.get(
    SavingsCompensationActsEndpoints.SAVINGS_COMPENSATION_ACTS_BY_PROJECT +
      `/${id}`
  );

export const getSavingsCompensationActsByContract = (
  id: number
): AxiosPromise<ISavingsCompensationActsResponse> =>
  axiosInstance.get(
    SavingsCompensationActsEndpoints.SAVINGS_COMPENSATION_ACTS_BY_CONTRACT +
      `/${id}`
  );

export const getSingleSavingsCompensationAct = (
  id: number
): AxiosPromise<ISingleSavingsCompensationActResponse> =>
  axiosInstance.get(
    SavingsCompensationActsEndpoints.SINGLE_SAVINGS_COMPENSATION_ACT + `/${id}`
  );

  export const createCompensationAct = (
    params: ISavingsCompensationActRequest
  ): AxiosPromise<IAddCompensationActResponse> => {
    return axiosInstance.post(
      SavingsCompensationActsEndpoints.CREATE_COMPENSATION_ACT,
      params
    );
  };

  export const updateCompensationAct = (
    id: number,
    params: IUpdateCompensationActRequest
  ): AxiosPromise<any> => {
    return axiosInstance.post(
      SavingsCompensationActsEndpoints.SINGLE_SAVINGS_COMPENSATION_ACT + `/${id}/update`,
      params
    );
  };

