import { useEffect, useMemo } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { fetchArchiveContracts, resetCurrentContract } from "../../store/slices/contracts";
import { IContract } from "../../api/contracts/types";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../helpers/formatAmount";
import { AttachmentIcon } from "@chakra-ui/icons";

const columnHelper = createColumnHelper<IContract>();

const TitleCell = ({ row: { original } }: CellContext<IContract, string>) => {
  return (
    <Flex align="center" justify="space-between" gap="16px">
      <span>{original.name}</span>
      <Flex minW={12} gap={3} color="#2AB6A5">
        {original.has_files && <AttachmentIcon cursor="pointer" />}
      </Flex>
    </Flex>
  );
};

const DateCell = ({ row: { original } }: CellContext<IContract, string>) => {
  let formationDate = '';
  if (original.expiration_date === null) {
    formationDate = 'Нет данных'
  } else {
    formationDate = dayjs(original.expiration_date).format("DD.MM.YYYY")
  };
  return (
      <span color="#8089A2">{formationDate}</span>
  );
};

const ProjectCell = ({ row: { original } }: CellContext<IContract, string>) => (
  <>
    <Text>{original.project_name}</Text>
    <Box lineHeight="22px" fontSize={14} color="#8089a2">
      {original.executor}
    </Box>
  </>
);

export function ArchiveTable() {
  const navigate = useNavigate();
  const { archiveContracts, currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCurrentContract());
    };
  }, []);

  function navigateToPage(id: number){
    navigate(`/my-documents/contracts/${id}`);
  }

  const columns = useMemo<Array<ColumnDef<IContract, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
        cell: TitleCell,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("closing_date", {
        header: "Дата окончания",
        cell: DateCell,
        enableSorting: true,
      }),
      {
        id: "project",
        header: "Проект и исполнитель",
        cell: ProjectCell,
      },
      columnHelper.accessor("short_description_construction_object", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("cost", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue())
      }),
    ],
    []
  );

  return <Table data={archiveContracts} columns={columns} fetchFunction={fetchArchiveContracts} lastViewedDocument={currentContract?.id} />;
}
