import { useFormik } from "formik";
import { PasswordInput } from "../../lib/input/PasswordInput";
import { useAppDispatch } from "../../../store";
import { fetchChangeEmail } from "../../../store/slices/profile";
import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../../lib/button/Button";
import { Input } from "../../lib/input/Input";

type Props = {
  afterSubmitActions?: () => void
}

export function ChangeEmailModal({ afterSubmitActions }: Props) {
  const dispatch = useAppDispatch();
  const onSubmit = async (values: { email: string; password: string}) => {
      await dispatch(fetchChangeEmail(values)) 
      afterSubmitActions?.();
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },

    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex flexDirection={'column'} gap={4}>
      <PasswordInput
        placeholder="Введите текущий пароль"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="Введите новый e-mail"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Flex>
    <PrimaryButton type="submit">Сохранить</PrimaryButton>
    </form>
  );
}
