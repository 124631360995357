export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return "0";
  } else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["б", "Кб", "Мб", "Гб", "Тб"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
