import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import {
  ISettlement,
  ISettlementsListResponse,
} from "../../api/settlements/types";
import { IPrintFormResponse } from "../../api/directionLetters/types";

interface ISettlementsInitialState extends ICommonInitialState {
  settlementsList: ISettlement[];
}

const initialState: ISettlementsInitialState = {
  settlementsList: [],
  isLoading: false,
  error: null,
};

export const fetchSettlementsList = createAsyncThunkInstance<
  ISettlementsListResponse,
  undefined
>("settlements/fetchSettlementsList", api.settlements.getSettlementsList);

export const fetchSettlementPrintForm = createAsyncThunkInstance<
  IPrintFormResponse,
  string
>(
  "settlements/fetchSettlementPrintForm",
  api.settlements.getSettlementPrintForm
);

const settlementsSlice = createSlice({
  name: "settlements",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSettlementsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSettlementsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settlementsList = action.payload.data;
      });
  },
});

export const settlementsReducer = settlementsSlice.reducer;
