import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {Files, LimitFenceCardsEndpoints} from "../endpoints";
import {
  ICreateLimitFenceCardRequest,
  IExportLzkRequest,
  ILimitFenceCardsResponse,
  ILzkSuppliersResponse,
  INewCreateLimitFenceCardRequest,
  INewSingleLimitFenceCardMaterialsResponse,
  ISingleLimitFenceCardMaterialsResponse,
  ISingleLimitFenceCardResponse, IUpdateLimitFenceCardRequest,
} from "./types";
import { IContractsResponse } from "../contracts/types";

export const getLimitFenceCards = (): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS);

export const getLimitFenceCardsByProject = (
  id: number
): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS_BY_PROJECT + `/${id}`
  );

export const getLimitFenceCardsByContract = (
  id: number
): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS_BY_CONTRACT + `/${id}`
  );

export const getLimitFenceCardContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/contracts`
  );

export const getSingleLimitFenceCard = (
  id: number
): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}`
  );

export const getLzkSuplliers = (
  id: number
): AxiosPromise<ILzkSuppliersResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}/suppliers`
  );

export const getSingleLimitFenceCardMaterials = (
  id: number,
  page: number
): AxiosPromise<ISingleLimitFenceCardMaterialsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD +
      `/${id}/materials?page=${page}`
  );

export const getNewSingleLimitFenceCardMaterials = (
  id: number
): AxiosPromise<INewSingleLimitFenceCardMaterialsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}/materials`
  );

export const getMaterialsForSupplier   = (
  lzk_id: number,
  supplier_id: string
): AxiosPromise<INewSingleLimitFenceCardMaterialsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${lzk_id}/materials-for-supplier?supplier_guid=${supplier_id}`
  );

export const createLimitFenceCard = (
  params: INewCreateLimitFenceCardRequest
): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.CREATE_LIMIT_FENCE_CARD, params);

export const approveLimitFenceCard = (id: number): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.APPROVE_LIMIT_FENCE_CARD, {id});

export const updateLimitFenceCard = (params: IUpdateLimitFenceCardRequest): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.UPDATE_LIMIT_FENCE_CARD, params);

export const exportMaterials = (
  params: IExportLzkRequest
): AxiosPromise<Blob> =>
  axiosInstance.post(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/export`,
    params,
    { responseType: "blob" }
  );

export const importMaterials = (
  limit_card_guid: string,
  contract_id: string,
  file: File,
  purchase_request: boolean
) => {
  const formData = new FormData();
  formData.append("limit_card_guid", limit_card_guid);
  formData.append("contract_id", contract_id);
  formData.append("file", file);
  formData.append("purchase_request", String(purchase_request));

  return axiosInstance.post(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};


export const deleteLimitFenceCard = (id: number): AxiosPromise<undefined> =>
  axiosInstance.post(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}/delete`
  );

