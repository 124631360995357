import { RootState, useAppSelector } from "../../store";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";

export function SingleConstructionRemarkInfo() {
  const { currentRemarkConstruction } = useAppSelector(
    (state: RootState) => state.remarks
  );
  return (
    <InformationBlock>
      <InformationItem title="Вид замечания">{currentRemarkConstruction?.type_of_remark}</InformationItem>
      <InformationItem title="Проект">{currentRemarkConstruction?.project_name}</InformationItem>
      <InformationItem title="Объект строительства">
        {currentRemarkConstruction?.construction_object}
      </InformationItem>
      <InformationItem title="Дефект">{currentRemarkConstruction?.defect}</InformationItem>
      <InformationItem title="Категория">{currentRemarkConstruction?.category}</InformationItem>
      <InformationItem title="Описание">
        {currentRemarkConstruction?.description}
      </InformationItem>
      <InformationItem title="Предложение по устранению">
        {currentRemarkConstruction?.suggestion_for_elimination}
      </InformationItem>
    </InformationBlock>
  );
}
