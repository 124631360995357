import { Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { ChakraModalProps } from "../../../shared/types/modalProps";
import { IErrorMaterialElement } from "../../../api/workCompletedActs/types";

interface WorkActErrorModalProps extends ChakraModalProps {
}

const details: IErrorMaterialElement[] = [
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Брусчатка",
        Codifier: "1.1.1",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Кирпич АЕ22",
        materialQuantity: "122",
        materialUnitOfMeasurement: "м^2"
    },
    {
        TypeOfWork: "809124c6-0853-11ef-acc2-48df37a0c7e0",
        TypeOfWorkName: "Прокладка трубопровода",
        Codifier: "1.3.4",
        materialGuid: "809124ec-0853-11ef-acc2-48df37a0c7e0",
        materialName: "Краска BB12",
        materialQuantity: "16",
        materialUnitOfMeasurement: "л"
    },
]
export const WorkActErrorModal = ({ isOpen, onClose }: WorkActErrorModalProps) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent w="700px" maxW="700px">
                <ModalBody w="700px">
                    <Flex gap="12px" flexDirection="column" w="100%" alignItems="center" justifyContent="center">
                        <Text
                            fontSize="18px"
                            color="#1F222A"
                            lineHeight="28px"
                            letterSpacing="-1.5%"
                            textAlign="left"
                            fontWeight={400}>
                            По работам полностью или частично не закуплен материал по распорядительным письмам, компенсация будет выполняться по цене из сметы. Продолжить?
                        </Text>
                        <Flex maxH="400px" w="100%" justifyContent="left" flexDirection="column" overflow="auto">
                            <Text>Список материалов без компенсации:</Text>
                            {details.map((el) => {
                                return (
                                    <Text>{`Позиция ${el.Codifier}: ${el.TypeOfWorkName} (${el.materialName}) - ${el.materialQuantity} ${el.materialUnitOfMeasurement},`}</Text>
                                )
                            })}
                        </Flex>
                    </Flex>

                </ModalBody>
                <ModalFooter>
                    <Flex gap="16px">
                        <PrimaryButton onClick={onClose}>Да</PrimaryButton>
                        <OutlineButton onClick={onClose}>
                            Нет
                        </OutlineButton>
                    </Flex>

                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}