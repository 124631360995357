import { Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BoxContentProps {
    headerTitle: string;
    headerIcon: ReactNode;
    children: ReactNode;
    onClick?: () => void
}

export const BoxItem = ({ headerTitle, headerIcon, children, onClick }: BoxContentProps) => {
    return (
        <Flex onClick={onClick} flexDirection="column" justifyContent="space-between" width="301px" height="240px" bgColor="white" border="1px solid #E3E7EF" borderRadius="8px" padding="16px 24px">
            <Flex gap="8px" alignItems="center">
                {headerIcon}
                <Text fontWeight={500} color="#8089A2">{headerTitle}</Text>
            </Flex>
            <Flex fontWeight={500} color="#343B4C" gap="8px" flexDirection="column">
                {children}
            </Flex>
        </Flex>
    )
};