import { ITab } from "../../components/lib/tabs/Tabs";
import { AccessRights } from "./sidebarContent";

export const myDocumentsPageTabs: ITab[] = [
  {
    id: 1,
    href: "contracts",
    label: "Договоры",
    permission: [AccessRights.Contract_RO, AccessRights.Contract_RW]
  },
  { id: 2, href: "access-acts", label: "Акты допуска", permission: AccessRights.AccessAct_RW },
];

export const singleAccessActPageTabs2: ITab[] = [
  { id: 1, label: "Объекты строительства" },
  { id: 2, label: "Файлы", notice: 1 },
];

export const singleAccessActPageTabs = (notice: number) => {
  return[
    { id: 1, label: "Объекты строительства" },
    { id: 2, label: "Файлы", notice: notice },
  ];
} 