interface ImagesListItem {
  src: string;
  text: string;
}

interface ImagesListToolData {
  images: ImagesListItem[];
}

class ImagesListTool {
  data: ImagesListToolData;
  api: object;
  readOnly: boolean;
  container?: HTMLDivElement;

  static get toolbox() {
    return undefined;
  }

  constructor({
    data,
    api,
    readOnly,
  }: {
    data: ImagesListToolData;
    api: object;
    readOnly: boolean;
  }) {
    this.data = {
      images: data.images || [],
    };
    this.api = api;
    this.readOnly = readOnly;
  }

  render() {
    this.container = document.createElement("div");
    this.container.classList.add("images-list-container");
    const imgData = this.data.images[0] || {};
    this.addImageWithText(imgData.src || "", imgData.text || "");

    return this.container;
  }

  addImageWithText(imgSrc = "", text = "") {
    const row = document.createElement("div");
    row.classList.add("images-list-row");

    const imgContainer = document.createElement("div");
    imgContainer.classList.add("img-container");

    if (imgSrc) {
      const imgElem = document.createElement("img");
      imgElem.src = imgSrc;
      imgContainer.appendChild(imgElem);
    }

    row.appendChild(imgContainer);

    const textContainer = document.createElement("div");
    textContainer.textContent = text;
    row.appendChild(textContainer);
    this.container?.appendChild(row);
  }

  static get isReadOnlySupported() {
    return true;
  }
}

export default ImagesListTool;
