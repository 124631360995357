import { AxiosResponse } from "axios";
import { getRequestedFile } from "../api/files";

function getFileName(disposition: string): string {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string = "";
  const utf8Matches = utf8FilenameRegex.exec(disposition);
  if (utf8Matches !== null && utf8Matches[1]) {
    fileName = decodeURIComponent(utf8Matches[1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf("filename=");
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches !== null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
}

export function downloadRequestedFile(
  fileGuid: string,
  setIsSpinnerRuning: (isOpen: boolean) => void
) {
  setIsSpinnerRuning(true);
  getRequestedFile(fileGuid).then((res: AxiosResponse<Blob>) => {
    if (Math.floor(res.status / 100) === 2) {
      if (res.data?.size) {
        const contentDisposition = res.headers["content-disposition"];
        const filename = getFileName(contentDisposition);

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsSpinnerRuning(false);
      } else {
        setTimeout(
          () => downloadRequestedFile(fileGuid, setIsSpinnerRuning),
          2000
        );
      }
    } else {
      setIsSpinnerRuning(false);
    }
  });
}
