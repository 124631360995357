import { useState, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Flex } from "@chakra-ui/react";
import { AuthLayout } from "../../components/AuthLayout/AuthLayout";
import { AuthTitle } from "../../components/AuthTitle/AuthTitle";
import { PrimaryButton } from "../../components/lib/button/Button";
import { Input } from "../../components/lib/input/Input";
import { CustomLink } from "../../components/lib/customLink/CustomLink";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import Vector from "../../images/svg/vector/vector.svg";
import { emailUserPassword } from "../../store/slices/auth";
import { IPasswordEmailRequest } from "../../api/auth/types";

export const PasswordRestore = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState<string | null>(null);

  const onSubmit = async (values: IPasswordEmailRequest) => {
    dispatch(emailUserPassword(values))
      .unwrap()
      .then(() => navigate("/recovery-password"))
      .catch((err) => setEmailError(err));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Поле обязательно для заполнения!")
        .email("Некорректный формат e-mail"),
    }),
    onSubmit,
  });

  const handleEmailInputChange = (event: SyntheticEvent) => {
    if (emailError) {
      setEmailError(null);
    }
    formik.handleChange(event);
  };

  const renderErrorMessage = () => {
    if (formik.touched.email && !!formik.errors.email) {
      return <ErrorMessage message={formik.errors.email} />;
    }
    if (emailError) {
      return <ErrorMessage message={emailError} />;
    }

    return <></>;
  };

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit}>
        <AuthTitle
          title="Забыли пароль?"
          subtitle="Мы вышлем ссылку на восстановление пароля на ваш e-mail."
        />
        <Flex direction="column" gap={4}>
          {renderErrorMessage()}
          <Input
            name="email"
            placeholder="Введите свой e-mail"
            value={formik.values.email}
            onChange={handleEmailInputChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.email && !!formik.errors.email) || !!emailError
            }
          />
        </Flex>
        <PrimaryButton type="submit">Восстановить пароль</PrimaryButton>
        <CustomLink content="Назад к авторизации" href="/" icon={Vector} />
      </form>
    </AuthLayout>
  );
};
