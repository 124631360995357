import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Flex, List, ListItem, Tab, TabList, Text } from "@chakra-ui/react";
import { Notice } from "../notice/Notice";
import {checkPermission} from "../../../helpers/checkPermission";
import {RootState, useAppSelector} from "../../../store";
import { useEffect } from "react";

export type ITab = {
  id: number;
  label: string;
  href?: string;
  notice?: number;
  permission?: string | string [];
  element?: React.ReactNode
};

interface TabsProps {
  tabs: ITab[];
}

export function ChakraTabs(props: TabsProps) {
  const { tabs } = props;

  return (
    <TabList borderBottom="none">
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          fontWeight={400}
          borderRadius={0}
          color="#5C657E"
          _selected={{ color: "#E8363D", borderColor: "#E8363D" }}
        >
          <Flex gap={3} h={5}>
            <Text>{tab.label}</Text>
            {tab.notice ? (
              <Notice number={tab.notice} />
            ) : (
              <div></div>
            )}
          </Flex>
        </Tab>
      ))}
    </TabList>
  );
}

export function LinkTabs(props: TabsProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const filteredTabs = props.tabs.filter(tab => ((tab.permission && checkPermission(tab.permission, permissions)) || !("permission" in tab)))

  useEffect(() => {
    const isRootTab = location.pathname.split("/").length - 1 === 1;
    if (isRootTab) {
      const firstValidTab = filteredTabs.find(
        (tab) =>
          (tab.permission && checkPermission(tab.permission, permissions)) ||
          !("permission" in tab)
      );
      if(firstValidTab?.href){
        navigate(firstValidTab?.href)
      }
    }
  }, [permissions, location])

  return (
    <List display="flex">
      {filteredTabs.map((tab) => (
          <ListItem color="#5C657E" key={tab.id} className="tab-link-item">
            <NavLink
              to={tab.href ?? "#"}
              className={({ isActive }) => (isActive ? "active-tab-link" : "")}
            >
              <Flex gap={3} h={5} align="center">
                <Text>{tab.label}</Text>
                {tab.notice ? (
                  <Notice number={tab.notice} />
                ) : (
                  <div></div>
                )}
              </Flex>
            </NavLink>
          </ListItem>
        )
      )}
    </List>
  );
}
