import React, {useEffect, useState} from "react";
import {Flex, Input, InputGroup} from "@chakra-ui/react";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {InformationBlock} from "../InformationBlock/InformationBlock";
import { RootState, useAppSelector } from "../../store";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { IAdvancedPayment, ICreateWorkCompletedActRequest } from "../../api/workCompletedActs/types";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import { reverseDate } from "../../helpers/reverseDate";

interface DocumentsFieldsProps {
  formik?: FormikProps<ICreateWorkCompletedActRequest>;
}

export function DocumentsFields({formik}: DocumentsFieldsProps) {
  const [inputs, setInputs] = useState<IAdvancedPayment[]>([{name:"", date: ""}]);
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const isDisabledByPermission = !checkPermission(AccessRights.WorkCompletedAct_RW, permissions) || (!!currentWorkCompletedAct && !currentWorkCompletedAct?.can_edit)
  const handleChange = (value: string, fieldName: keyof IAdvancedPayment, index: number) => {
    let onChangeValue = [...inputs];
    onChangeValue[index] = JSON.parse(JSON.stringify(onChangeValue[index]));
    if (fieldName === "date") {
      onChangeValue[index][fieldName] = value !== "" ? dayjs(value).format("DD.MM.YYYY") : ""
    } else {
      onChangeValue[index][fieldName] = value;
    }
    setInputs(onChangeValue)
  }

  useEffect(() => {
    if (formik) {
      formik.values.advance_payments && setInputs(formik?.values.advance_payments)
      formik.values.advance_payments?.length === 0 && setInputs([{name:"", date: ""}])
    }
  },[])

  useEffect(() => {
    if (inputs.length > 0) {
      if (inputs[inputs.length - 1].date && inputs[inputs.length - 1].name) {
        const duplicateArray = [...inputs, { name: "", date: "" }]
        setInputs(duplicateArray)
      }
    }
  }, [inputs])

  useEffect(() => {
    const resultInputs = inputs.filter((el) => el.name !== "" || el.date !== "")
    formik?.setFieldValue("advance_payments", resultInputs)
  }, [inputs])

  const PeriodCell = ({ index }: { index: number }) => {
    const [value, setValue] = useState(inputs[index].date !== "" ? dayjs(reverseDate(inputs[index].date)).format("YYYY-MM-DD") : "");

    const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValue(newValue);
    };

    return (
      <InformationItemDateInput
        isDisabled={isDisabledByPermission}
        id={`document_field_date_${index}`}
        name={`document_field_date_${index}`}
        value={value}
        w="240px"
        placeholder="От"
        onBlur={(event) => handleChange(event.target.value, "date", index)}
        onChange={onDateChange}
        isInvalid={inputs[index].name !== "" && inputs[index].date === ""}
      />
    )
  }

  return <InformationBlock>
    <Flex direction="column" gap={4}>
      {inputs.map((input, index) => {
        return (
          <InputGroup key={index} gap={4}>
            <Input
              disabled={isDisabledByPermission}
              id={`document_field_name_${index}`}
              name={`document_field_name_${index}`}
              value={inputs[index].name}
              onChange={(event) => handleChange(event.target.value, "name", index)}
              placeholder="Название документа"
              width="100%"
              h="36px"
              rounded="md"
              borderColor="#E3E7EF"
              isInvalid={inputs[index].date !== "" && inputs[index].name === ""}
            />
            <PeriodCell index={index}/>
          </InputGroup>
        )
      })}
    </Flex>
  </InformationBlock>
}
