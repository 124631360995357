import { PrimaryButton } from "../../lib/button/Button";
import { Modal, ModalProps } from "../Modal";
import { AuthTitle } from "../../AuthTitle/AuthTitle";
import styles from ".././modal.module.scss";

type SuccessChangePasswordModalProps = Omit<ModalProps, "children">;

export function SuccessChangePasswordModal(
  props: SuccessChangePasswordModalProps
) {
  const { active, onClose } = props;

  return (
    <Modal active={active} onClose={onClose}>
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={styles.center_modal_container}
      >
        <AuthTitle title="Пароль успешно изменён" isSuccess />
        <PrimaryButton onClick={onClose}>Закрыть</PrimaryButton>
      </div>
    </Modal>
  );
}
