import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { attachFilesToSingleWorkCompletedAct, fetchSingleWorkCompletedActs, fetchWorkCompletedActs, resetCurrenWorkCompletedAct } from "../../../store/slices/workCompletedActs";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
  Row,
} from "@tanstack/react-table";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { ExternalLinkIcon} from "@chakra-ui/icons";
import { Table } from "../../lib/table/Table";
import { ReactComponent as EditPen } from "../../../images/svg/edit/edit-pen.svg";
import { IWorkCompletedAct } from "../../../api/workCompletedActs/types";
import { formatAmount } from "../../../helpers/formatAmount";
import { Input } from "../../lib/input/Input";
import { DownloadFilesModal } from "../../Modal/ModalRight/DownloadFilesModal";
import { IFilesAttachResponse } from "../../../api/files/types";
import {ContractCell} from "../../ContractCell/ContractCell";
import { ProgressElement } from "../../SingleWorkCompletedActComponents/ProgressElement";
import { OnboardingByPermission } from "../../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../../shared/mock/sidebarContent";
import { useNavigate } from "react-router-dom";
import { fetchSingleEstimate } from "../../../store/slices/estimates";
import dayjs from "dayjs";
import { reverseDate } from "../../../helpers/reverseDate";
import { StatusCellTable } from "../../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../../helpers/filterFunction";
import { FilterHeader } from "../../Filter/FilterHeader";

const columnHelper = createColumnHelper<IWorkCompletedAct>();

interface TitleProps {
  onChange: (inp: string) => void;
}

const TitleHeader = ({ onChange }: TitleProps) => {
  return (
    <Flex align="center" justify="space-between" w="full" gap="16px">
      <span>Договор</span>
      <Input
        small
        search
        placeholder="Поиск"
        onChange={(e) => onChange(e.target.value)}
      />
    </Flex>
  );
};

const NameCell = (info: CellContext<IWorkCompletedAct, string>) => (
  <Flex gap={4} justify="space-between">
    {info.getValue()}
  </Flex>
);

const ReconciliationCell = (info: CellContext<IWorkCompletedAct, string>) => {
  const progressBlocks = [
    { t: "РС", p: info.row.original.progress_rs },
    { t: "ТН", p: info.row.original.progress_tn },
    { t: "Бэк-офис", p: info.row.original.progress_back_office }
  ]
  return (
    <Flex gap="8px" flexDirection="column">
      {progressBlocks.map(el => <ProgressElement title={el.t} percent={el.p} isTableComponent={true} />)}
    </Flex>

  );
};

export function CompletedWorkActsTable({ dispatch, filesModal, setFilesModal }: any) {
  const { workCompletedActs } = useAppSelector(
    (state) => state.workCompletedActs
  );
  const { currentWorkCompletedAct } = useAppSelector(
    (state) => state.workCompletedActs
  );

  const [search, setSearch] = useState("");
  const [isDisableAttach, setIsDisableAttach] = useState(false);
  const [files, setFiles] = useState(currentWorkCompletedAct?.files ?? []);
  const navigate = useNavigate()

  const fileAttach = (files: File[]) => {
    if (currentWorkCompletedAct) {
      dispatch(attachFilesToSingleWorkCompletedAct({id: currentWorkCompletedAct.id, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  const onChange = (str: string) => {
    setSearch(str);
  };

  const handleDblClick = (id: number, row?: Row<IWorkCompletedAct>) => {
    if (row?.original.status === "Черновик ЛКП") {
      navigate(`/my-documents/contracts/work-completed-act/${row?.original.id}/draft`)
    } else {
      navigate(`/my-documents/contracts/work-completed-act/${row?.original.id}`)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCurrenWorkCompletedAct())
    }
  }, [])

  const ProjectCell = useCallback(
    ({ row: { original } }: CellContext<IWorkCompletedAct, string>) => (
      <>
        <Text noOfLines={1}>{original.project_name}</Text>
        <Box noOfLines={1} lineHeight="21px" fontSize={14} color="#8089a2">
          {original.executor}
        </Box>
      </>
    ),
    []
  );

  const FilesCell = ({ row: { original } }: CellContext<IWorkCompletedAct, string>) => {
    return(
    <Center cursor="pointer">
      <ExternalLinkIcon
        onClick={() => {
          setIsDisableAttach(original.status == "Утверждена")
          setFilesModal(true);
          dispatch(fetchSingleWorkCompletedActs(original.id))
        }}
        color="#2AB6A5"
      />
    </Center>
    );
  };


  const columns = useMemo<Array<ColumnDef<IWorkCompletedAct, string>>>(
    () => [
      columnHelper.accessor("document_date", {
        header: "Дата создания",
        cell: (info) => info.renderValue() ? dayjs(info.renderValue()).format("DD.MM.YYYY") : "",
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const dateA = dayjs(rowA.original.document_date);
          const dateB = dayjs(rowB.original.document_date);

          const isValidA = dateA.isValid();
          const isValidB = dateB.isValid();

          if (!isValidA && !isValidB) {
            return 0;
          } else if (!isValidA) {
            return 1;
          } else if (!isValidB) {
            return -1;
          } else {
            return dateA.isBefore(dateB) ? -1 : (dateA.isAfter(dateB) ? 1 : 0); // Обе даты валидны - стандартная сортировка
          }
        },
      }),
      columnHelper.accessor("name", {
        header: "Название",
        cell: NameCell,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table,  "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("contract_name", {
        header: () => TitleHeader({ onChange }),
        cell: (info) => ContractCell(info, info.row.original.contract_id),
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("agreement", {
        header: "Согласование",
        cell: ReconciliationCell,
      }),
      columnHelper.accessor("project_name", {
        header: (info) => FilterHeader(info.column, info.table,  "Проект и исполнитель"),
        cell: ProjectCell,
        enableColumnFilter: true,
        filterFn: ({ original: { project_name } }, id, filterValues) =>
          filterFunction({ value: project_name, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("file", {
        header: "Файлы",
        cell: FilesCell,
      }),
    ],
    [ProjectCell]
  );
  return (
    <Flex flexDirection="column" gap="8px">
      <Table
        data={
          search.trim() === ""
            ? workCompletedActs
            : workCompletedActs.filter((it) =>
                it.contract_name.toLowerCase().includes(search.toLowerCase())
              )
        }
        columns={columns}
        isWorkCompletedActs={true}
        fetchFunction={fetchWorkCompletedActs}
        lastViewedDocument={currentWorkCompletedAct?.id}
        dblClickHandler={handleDblClick}
        defaultSorting={[{ id: "document_date", desc: true }]}
        minHeight="300px"
      />
      <Box>
        <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RO} />
      </Box>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
        isDisabled={isDisableAttach}
      />
    </Flex>
  );
}

