import { LiHTMLAttributes, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Flex } from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import { ISidebarItem } from "../../shared/types/sidebarTypes";
import styles from "./sidebar.module.scss";
import { Notice } from "../lib/notice/Notice";
import { RootState, useAppSelector } from "../../store";

interface SidebarItemProps extends LiHTMLAttributes<HTMLLIElement> {
  item: ISidebarItem;
}

export function SidebarItem(props: SidebarItemProps) {
  const { item } = props;
  const { pathname } = useLocation();
  const [open] = useState<boolean>(!!pathname.includes(item.href));
  const { isSidebarClosed } = useAppSelector(
    (state: RootState) => state.profile.profileData
  );

  const itemClass = classNames(styles.item, {
    [styles.open]: open,
  });

  return (
    <li key={item.id} className={itemClass}>
      {item.isDisabled ? (
        <div className={styles.item_link}>
          <Flex gap={3} w="full">
            <div className={styles.logo_container}>
              <item.logo fill="#343B4C" />
            </div>
            <Flex
              align="center"
              justify="space-between"
              w="full"
              className={styles.link_name}
            >
              {item.title} <LockIcon />
            </Flex>
          </Flex>
        </div>
      ) : (
        <NavLink to={item.href} className={styles.item_link}>
          <Flex alignItems="center" gap={3}>
            <div className={styles.logo_container}>
              <item.logo fill="#343B4C" />
            </div>
            <span className={styles.link_name}>{item.title}</span>
            <div style={{
              position: isSidebarClosed ? "absolute" : "initial",
              left: "25px",
              top: "0"
            }}>
              {item.notice > 0 && <Notice number={item.notice} overdueNotice={!isSidebarClosed ? item.overdueNotice : 0} />}
            </div>
          </Flex>
        </NavLink>
      )}
    </li>
  );
}
