import { Flex } from "@chakra-ui/react";
import { DropFileInput } from "../../lib/input/drop-file-input/DropFileInput";
import { PrimaryButton } from "../../lib/button/Button";
import { FilesTable } from "./FilesTable";

export function FilesBlock() {
  return (
    <Flex direction="column" gap={4}>
      <DropFileInput
        text="Перетащите сюда файл(ы)"
        name="attachments"
        onChange={() => {}}
        isDisabled
      />
      <FilesTable />
      <PrimaryButton w="124px">Отправить</PrimaryButton>
    </Flex>
  );
}
