import {Flex, ListItem, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, UnorderedList} from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { ChakraModalProps } from "../../../shared/types/modalProps";

interface AllPositionModalProps extends ChakraModalProps{
    content: string
}

export function AllPositionModal({ isOpen, onClose, content}: AllPositionModalProps) {

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              lineHeight="28px"
              color="#343b4c"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Все должности
            </Text>
            <UnorderedList>
            {content.split(",").map((profession, index) => <ListItem fontSize={16} lineHeight="20px" fontWeight={400} color="#343B4C" key={index}>{profession}</ListItem>)}
            </UnorderedList>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <PrimaryButton w="full" onClick={() => onClose()}>
              Закрыть
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
