import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { ICounters, ICountersResponse, IMainInfo, IMainInfoResponse } from "../../api/mainInfo/types";

interface IMainInfoState extends ICommonInitialState {
  mainInfo: IMainInfo | null;
  counters: ICounters | null;
}

const initialState: IMainInfoState = {
  mainInfo: null,
  counters: null,
  isLoading: false,
  error: null,
};

export const fetchMainInfo = createAsyncThunkInstance<
  IMainInfoResponse,
  undefined
>("mainInfo/fetchMainInfo", api.mainInfo.getMainInfo);

export const fetchCounters = createAsyncThunkInstance<
  ICountersResponse,
  undefined
>("mainInfo/fetchCounters", api.mainInfo.getMainStatus);

const mainInfoSlice = createSlice({
  name: "mainInfo",
  initialState,
  reducers: {
    changeCounters(state, action) {
      state.counters = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMainInfo.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMainInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mainInfo = action.payload.data;
      })

      .addCase(fetchCounters.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCounters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.counters = action.payload.data;
      });
  },
});

export const mainInfoReducer = mainInfoSlice.reducer;
export const { changeCounters } = mainInfoSlice.actions
