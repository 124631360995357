import { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../store";
import { ITooltip } from "../api/tooltips/types";

export const useTooltipData = () => {
  const { tooltipList } = useAppSelector((state: RootState) => state.tooltips);
  const [tooltipData, setTooltipData] = useState({});

  useEffect(() => {
    const dataObject: Record<string, string> = {};
    tooltipList.forEach((item: ITooltip) => 
      dataObject[item.key] = item.text
    );

    setTooltipData(dataObject);
  }, [tooltipList]);

  return tooltipData;
};
