import ErrorLogo from "../../images/svg/error/error.svg";
import styles from "./errorMessage.module.scss";

interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div className={styles.error_container}>
      <img src={ErrorLogo} alt="Error" />
      <p>{props.message}</p>
    </div>
  );
}
