import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { ISettlementsListResponse } from "./types";
import { SettlementsEndpoints } from "../endpoints";
import { IPrintFormResponse } from "../directionLetters/types";

export const getSettlementsList = (): AxiosPromise<ISettlementsListResponse> =>
  axiosInstance.get(SettlementsEndpoints.SETTLEMENTS);

export const getSettlementPrintForm = (
  id: string
): AxiosPromise<IPrintFormResponse> =>
  axiosInstance.post(
    SettlementsEndpoints.SETTLEMENT + `/${id}/request-print-form`
  );