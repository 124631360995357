import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { adminLogin } from "../../store/slices/auth";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

export function AdminLogin() {
  const { loginError,token } = useAppSelector((state: RootState) => state.auth.authData);

  const dispatch = useAppDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const jwtToken = query.get("token");

  useEffect(() => {
    if (jwtToken) {
      dispatch(adminLogin({ token: jwtToken }));
    }
  }, [dispatch, jwtToken]);

  if (token) {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      {loginError && <ErrorMessage message={loginError} />}
    </>
  );
}
