import {useState, useEffect, useCallback} from "react";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { Modal, ModalProps } from "../Modal";
import { Input } from "../../lib/input/Input";
import { NoMatches } from "../../NoMatches/NoMatches";
import { Accordion } from "../../Accordion/Accordion";
import styles from ".././modal.module.scss";
import {getQuestions} from "../../../api/faqs";
import { IQuestion } from "../../../api/faqs/types";

type FAQModalProps = Omit<ModalProps, "children"> & {
  title: string;
};

export function FAQModal(props: FAQModalProps) {
  const { active, onClose, title } = props;
  const [searchValue, setSearchValue] = useState("");
  const [filteredAccordionData, setFilteredAccordionData] = useState<
    IQuestion[]
  >([]);
  const [accordionData, setAccordionData] = useState<IQuestion[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getQuestions();
      return data;
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    fetchData().then((res) => setAccordionData(res.faqs));
  }, [fetchData]);

  useEffect(() => {
    setFilteredAccordionData(
      accordionData.filter((item) =>
        item.content.blocks[0]?.data.text.toLowerCase().includes(searchValue.toLowerCase().trim())
      )
    );
  }, [accordionData, searchValue]);

  return (
    <Modal active={active} onClose={onClose}>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={onClose} />
          <div className={styles.modal_info}>
            <Input
              placeholder="Поиск"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              search
            />
            {!!filteredAccordionData.length ? (
              filteredAccordionData.map((item) => (
                <Accordion
                  key={item.id}
                  content={item.content.blocks}
                />
              ))
            ) : (
              <NoMatches />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
