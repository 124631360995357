import { RootState, useAppSelector } from "../../store";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { ProfileName } from "./ProfileName/ProfileName";
import { ProfileInfoCard } from "./ProfileInfo/ProfileInfoCard";
import { ReactComponent as Profile } from "../../images/svg/profile/profile-name.svg";
import { ReactComponent as Phone } from "../../images/svg/profile/phone.svg";
import { ReactComponent as Email } from "../../images/svg/profile/email.svg";
import { ReactComponent as RoleIcon } from "../../images/svg/profile/roleIcon.svg";

export const ProfileEmployeeContainer = () => {
  const profile = useAppSelector(
    (state: RootState) => state.profile.profileData.profile
  );

  return (
    <Box overflowY="scroll" maxH="100%" p={6}>
      <Grid
        h="404px"
        gap={4}
        gridTemplateColumns="360px 1fr 1fr"
        gridTemplateRows="repeat(2, 1fr)"
      >
        <GridItem colSpan={1} rowSpan={2}>
          <ProfileName name={"Профиль"} />
        </GridItem>
        <ProfileInfoCard
          Icon={Profile}
          title="ФИО"
          content={`${profile?.surname} ${profile?.name} ${profile?.patronymic}`}
        />
        <ProfileInfoCard
          Icon={Phone}
          title="Телефон"
          content={profile?.phone_number ?? ""}
        />
        <ProfileInfoCard
          Icon={Email}
          title={"E-mail"}
          content={profile?.email ?? ""}
        />
        <ProfileInfoCard
          Icon={RoleIcon}
          title="Роль"
          content={profile?.position ?? ""}
        />
      </Grid>
    </Box>
  );
};
