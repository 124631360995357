import { AxiosPromise } from "axios";
import {
  IReconciliationStatementResponse,
  IUpdateReconciliationStatementRequest,
} from "./types";
import { axiosInstance } from "../instance";
import { ReconciliationStatementEndpoints } from "../endpoints";
import { ICommonResponse } from "../commonResponseType";
import { IPrintFormResponse } from "../directionLetters/types";

export const getReconciliationStatement = (
  id: string
): AxiosPromise<IReconciliationStatementResponse> =>
  axiosInstance.get(ReconciliationStatementEndpoints.REQ_STATE + `/${id}`);

export const updateReconciliationStatement = (
  params: IUpdateReconciliationStatementRequest,
  id: string
): AxiosPromise<ICommonResponse> => {
  return axiosInstance.post(
    ReconciliationStatementEndpoints.REQ_STATE + `/${id}/update`,
    params
  );
};

export const getReconciliationPrintForm = (
  id: string
): AxiosPromise<IPrintFormResponse> =>
  axiosInstance.post(
    ReconciliationStatementEndpoints.REQ_STATE + `/${id}/request-print-form`
  );
