import { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import styles from "./customLink.module.scss";

interface CustomLinkProps {
  content: string;
  href?: string;
  icon?: string;
  onClick?: (e: SyntheticEvent) => void;
}

export function CustomLink(props: CustomLinkProps) {
  const { content, href = "#", icon, onClick } = props;

  return (
    <div className={styles.link_wrapper}>
      <Link to={href} onClick={onClick}>
        {icon && <img src={icon} alt="Link" />}
        {content}
      </Link>
    </div>
  );
}
