import { Flex, Link } from "@chakra-ui/react";
import { BlockTitle } from "../../lib/title/Title";
import { BuildingObjectsTable } from "./BuildingObjectsTable";
import { useState } from "react";

export function BuildingObjectsBlock() {
  const [isCollapsedList, setIsCollapsedList] = useState<boolean>(false);
  return (
    <Flex direction="column" gap={4}>
      <BlockTitle>Объекты строительства</BlockTitle>
      <BuildingObjectsTable isCollapsedList={isCollapsedList} />
      <Link color="#2AB6A5" onClick={() => setIsCollapsedList(!isCollapsedList)}>
        {!isCollapsedList ? "Развернуть список" : "Свернуть"}
      </Link>
    </Flex>
  );
}
