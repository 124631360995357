import { useAppDispatch } from "../../../store";
import { Flex } from "@chakra-ui/react";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { Modal, ModalProps } from "../Modal";
import { Title } from "../../lib/title/Title";
import { Subtitle } from "../../lib/subtitle/Subtitle";
import { removeUser } from "../../../store/slices/profile";
import styles from ".././modal.module.scss";

type ConfirmDeleteModalProps = Omit<ModalProps, "children"> & {
  id?: number;
};

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const dispatch = useAppDispatch();
  const { active, onClose, id } = props;

  const handleDeleteUser = async () => {
    if (id) {
      await dispatch(removeUser(id));
      onClose();
    }
  };

  return (
    <Modal active={active} onClose={onClose}>
      <div
        className={styles.center_modal_container}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.delete_title}>
          <Title textAlign="center">Удалить сотрудника?</Title>
          <Subtitle text="Вы действительно хотите удалить сотрудника?" />
        </div>
        <Flex w="full" gap={2} justify="space-between">
          <OutlineButton flex={1} onClick={onClose}>
            Отмена
          </OutlineButton>
          <PrimaryButton flex={1} onClick={handleDeleteUser}>
            Удалить
          </PrimaryButton>
        </Flex>
      </div>
    </Modal>
  );
}
