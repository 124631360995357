import { Flex, Text, Box } from "@chakra-ui/react";
import {AppTable} from "./AppTable";

export function AppBlock() {
    return (
        <Flex
            bg="white"
            direction="column"
            px="24px"
            py="10px"
            borderRadius="8px"
            border="1px solid #E3E7EF"
            h="fit-content"
        >
            <Box py="14px" borderBottom="1px solid #E3E7EF">
                <Text color="#5C657E">Приложения</Text>
            </Box>
            <AppTable />
        </Flex>
    );
}
