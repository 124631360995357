import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { MultiValue } from "react-select";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { DropFileInput } from "../../lib/input/drop-file-input/DropFileInput";
import { Input, InputThemes } from "../../lib/input/Input";
import { PasswordInput } from "../../lib/input/PasswordInput";
import { RadioInput, StatusActivityValues } from "../../lib/input/RadioInput";
import { Modal, ModalProps } from "../Modal";
import { IProfileUser } from "../../../api/profile/types";
import { downloadPowerAttorneyTemplate } from "../../../api/staff";
import {
  addUser,
  editUser,
  resetCurrentUser,
  resetError,
} from "../../../store/slices/profile";
import { MultiSelect } from "../../lib/select/MultiSelect";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import styles from ".././modal.module.scss";
import { Checkbox } from "@chakra-ui/react";

type AddEditModalProps = Omit<ModalProps, "children"> & {
  title: string;
  user?: IProfileUser | null;
};

type Option = {
  label: string;
  value: number;
};

const NAME_REGEX = /^([а-яё\s]+|[a-z\s]+)$/iu;
const PHONE_REGEX = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.[a-zA-Z]).{8,}$/;

export function AddEditModal(props: AddEditModalProps) {
  const { active, onClose, title, user } = props;
  const { roleList, error, currentUser } = useAppSelector(
    (state: RootState) => state.profile.profileData
  );

  const dispatch = useAppDispatch();
  const [rolesOptions, setRolesOptions] = useState<Option[]>([]);

  useEffect(() => {
    setRolesOptions(
      roleList?.map((role) => ({ label: role.name, value: role.id })) || []
    );
  }, [roleList]);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
    dispatch(resetError());
    dispatch(resetCurrentUser());
  };

  const onSubmit = async () => {
    let formData = new FormData();

    formik.values.role_id.forEach((id: number) => {
      formData.append("role_id[]", id.toString());
    });
    formik.values.power_of_attorney.forEach((file: File) => {
      formData.append("power_of_attorney[]", file);
    });

    formData.append("surname", formik.values.surname);
    formData.append("name", formik.values.name);
    formData.append("patronymic", formik.values.patronymic);
    formData.append("email", formik.values.email);
    formData.append("phone_number", formik.values.phone_number);
    formData.append("status_activity", formik.values.status_activity);
    formData.append(
      "power_of_attorney_end_date",
      formik.values.power_of_attorney_end_date
    );
    formData.append(
      "indefinite_power_of_attorney",
      formik.values.indefinite_power_of_attorney.toString()
    );
    formData.append("password", formik.values.password);

    if (user) {
      dispatch(editUser({ params: formData, id: user.user_id }))
        .unwrap()
        .then(() => handleCloseModal())
        .catch((err) => console.error(err));
    } else {
      dispatch(addUser(formData))
        .unwrap()
        .then(() => handleCloseModal())
        .catch((err) => console.error(err));
    }
  };

  const minDate = dayjs().add(1, "day").format("YYYY-MM-DD");
  const maxDate = dayjs().add(1, "day").add(10, "year").format("YYYY-MM-DD");
  const dateError = `Вы указали неверную дату. Выберите вариант «Бессрочно», либо укажите дату от ${dayjs(
    minDate
  ).format("DD.MM.YYYY")} до ${dayjs(maxDate).format("DD.MM.YYYY")}`;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;

  const formik = useFormik({
    initialValues: {
      surname: user?.surname || "",
      name: user?.name || "",
      patronymic: user?.patronymic || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
      status_activity:
        user?.status_activity === "Активный"
          ? StatusActivityValues.True
          : StatusActivityValues.False,
      role_id: user?.id_roles || [],
      power_of_attorney: [],
      power_of_attorney_end_date:
        currentUser?.profile.power_of_attorney_end_date || "",
      indefinite_power_of_attorney:
        !!currentUser?.profile.is_indefinite_power_of_attorney,
      password: "",
    },
    validationSchema: Yup.object({
      surname: Yup.string().matches(NAME_REGEX).required(),
      name: Yup.string().matches(NAME_REGEX).required(),
      patronymic: Yup.string().matches(NAME_REGEX).required(),
      email: Yup.string().matches(emailRegex).required(),
      phone_number: Yup.string().matches(PHONE_REGEX).required(),
      status_activity: Yup.string().required(),
      role_id: Yup.array().of(Yup.number()).min(1).required(),
      power_of_attorney: Yup.mixed(),
      power_of_attorney_end_date: Yup.date()
        .min(minDate, dateError)
        .max(maxDate, dateError),
      indefinite_power_of_attorney: Yup.boolean().required(),
      password: Yup.string().matches(PASSWORD_REGEX).required(),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const handleSelectChange = async (options: MultiValue<Option>) => {
    await formik.setFieldValue(
      "role_id",
      options.map((option) => option.value)
    );
    await formik.setFieldTouched("role_id");
  };

  const selectedValue = rolesOptions.filter((role) =>
    formik.values.role_id.includes(role.value)
  );

  const handleRadioChange = (e: SyntheticEvent) => {
    formik.handleChange(e);
    formik.setFieldTouched("status_activity");
  };

  const handleFileInputChange = (files: File[]) => {
    formik.setFieldValue("power_of_attorney", files);
  };

  const handleDownloadClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    downloadPowerAttorneyTemplate();
  }, []);

  const isInputDateError =
    formik.touched.power_of_attorney_end_date &&
    !!formik.errors.power_of_attorney_end_date &&
    !formik.values.indefinite_power_of_attorney;

  useEffect(() => {
    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      const firstErrorField = Object.keys(formik.errors)[0];
      const element = document.getElementById(firstErrorField);
      
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.focus();
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  return (
    <Modal active={active} onClose={handleCloseModal}>
      <div
        className={styles.modal_content}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={handleCloseModal} />

          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.modal_info}>
              <Input
                id="surname"
                label="Фамилия"
                placeholder="Введите фамилию"
                name="surname"
                className={InputThemes.High}
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.surname && !!formik.errors.surname}
              />
              <Input
                id="name"
                label="Имя"
                placeholder="Введите имя"
                name="name"
                className={InputThemes.High}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && !!formik.errors.name}
              />
              <Input
                id="patronymic"
                label="Отчество"
                placeholder="Введите отчество"
                name="patronymic"
                className={InputThemes.High}
                value={formik.values.patronymic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.patronymic && !!formik.errors.patronymic}
              />
              <Input
                id="email"
                label="E-mail"
                placeholder="Введите e-mail"
                name="email"
                className={InputThemes.High}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && !!formik.errors.email}
              />
              <InputMask
                value={formik.values.phone_number}
                mask={"+\\7 (999) 999-99-99"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone_number"
                disabled={false}
              >
                <Input
                  id="phone_number"
                  label="Телефон"
                  placeholder="Введите телефон"
                  name="phone_number"
                  type="tel"
                  className={InputThemes.High}
                  value={formik.values.phone_number}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phone_number && !!formik.errors.phone_number
                  }
                />
              </InputMask>
              <RadioInput
                label="Статус"
                name="status_activity"
                value={formik.values.status_activity}
                onChange={handleRadioChange}
              />
              <MultiSelect
                options={rolesOptions}
                value={selectedValue}
                onChange={handleSelectChange}
                label="Роль"
                error={formik.touched.role_id && !!formik.errors.role_id}
              />
              <DropFileInput
                text="Перетащите сюда скан/фото доверенности или приказа, заполненной(го)
            по шаблону, по данному сотруднику"
                label="Доверенность или приказ"
                onChange={handleFileInputChange}
                name="power_of_attorney"
                isLimitedFormat
                linkText="Скачать шаблон доверенности/приказа"
                onLinkClick={handleDownloadClick}
              />
              <div className={styles.power_of_attorney}>
                <div className={styles.date_wrapper}>
                  <Input
                    type="date"
                    id="power_of_attorney_end_date"
                    name="power_of_attorney_end_date"
                    className={
                      formik.values.indefinite_power_of_attorney
                        ? InputThemes.Disabled
                        : InputThemes.Primary
                    }
                    label="Дата окончания действия доверенности/приказа"
                    error={isInputDateError}
                    value={formik.values.power_of_attorney_end_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    max={maxDate}
                  />
                  {isInputDateError && (
                    <p className={styles.error}>
                      {formik.errors.power_of_attorney_end_date}
                    </p>
                  )}
                </div>
                <div className={styles.checkbox}>
                  <Checkbox
                    name="indefinite_power_of_attorney"
                    isChecked={formik.values.indefinite_power_of_attorney}
                    onChange={formik.handleChange}
                    borderColor="#adb5bd"
                    colorScheme="teal"
                    size={"lg"}
                  />
                  <label htmlFor="indefinite_power_of_attorney">Бессрочно</label>
                </div>
              </div>

              <PasswordInput
                id="password"
                label="Для подтверждения создания профиля сотрудника введите свой пароль"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && !!formik.errors.password}
              />
            </div>
            {error && <ErrorMessage message={error} />}
            <ModalFooter shadow>
              <OutlineButton onClick={handleCloseModal}>Отмена</OutlineButton>
              <PrimaryButton type="submit" isDisabled={!formik.dirty}>
                Сохранить
              </PrimaryButton>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Modal>
  );
}
