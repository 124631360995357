import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../lib/button/Button";
import { BlockTitle } from "../lib/title/Title";
import { IndemnityLetterTable } from "./IndemnityLetterTable";
import { RootState, useAppSelector } from "../../store";
import { DownloadIcon } from "@chakra-ui/icons";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { useState } from "react";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { fetchLetterGuaranteePrintForm } from "../../store/slices/contracts";

export function IndemnityLetterBlock() {
  const { currentLetterGuarantees } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false)
  const { downloadFile } = useUniversalDownloadFile();

  return (
    <Flex direction="column" gap={4}>
      <Flex justify="space-between" align="center">
        <BlockTitle>Гарантийные письма</BlockTitle>
        <PrimaryButton
          isDisabled={!currentLetterGuarantees}
          leftIcon={<DownloadIcon />}
          onClick={() => currentLetterGuarantees && downloadFile(currentLetterGuarantees, setIsSpinnerRunning, fetchLetterGuaranteePrintForm)}>Скачать</PrimaryButton>
      </Flex>
      <IndemnityLetterTable />
      <FullScreenWaitingModal openModal={isSpinnerRunning} />
    </Flex>
  );
}
