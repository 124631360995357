import { Text } from "@chakra-ui/react";

export const StatusCellTable = (value: string | null, color?: string, colorText?: string) => (
    <Text
      fontSize={14}
      py={2}
      px={3}
      color={colorText ? colorText : "#000"}
      bg={color ? color : "#FEE7AC"}
      borderRadius={6}
      display="inline-flex"
      lineHeight="14px"
      w="max-content"
      maxW="150px"
    >
      {value}
    </Text>
  );