import { Flex } from "@chakra-ui/react";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Title } from "../../components/lib/title/Title";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { Outlet } from "react-router-dom";
import { reportPageTabs } from "../../shared/mock/reportPageTabs";

export function ReportPage() {
  return (
    <MainLayout>
      <Flex overflowY="scroll" h="100%" maxH="100%" direction="column" gap="24px" p="6">
        <Flex justifyContent="space-between">
          <Title>Отчеты</Title>
        </Flex>
        <LinkTabs tabs={reportPageTabs} />
        <Outlet />
      </Flex>
    </MainLayout>
  );
}