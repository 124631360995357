import { useEffect, useMemo, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Table } from "../../lib/table/Table";
import { RootState, useAppSelector } from "../../../store";
import { IAccessActConstructionObject } from "../../../api/accessActs/types";
import { getCollapsedData } from "../../../helpers/getCollapsedData";


const columnHelper = createColumnHelper<IAccessActConstructionObject>();

interface BuildingObjectsTableProps {
  isCollapsedList: boolean
}

export function BuildingObjectsTable({isCollapsedList}: BuildingObjectsTableProps) {
  const [resultData, setResultData] = useState<IAccessActConstructionObject[] | undefined>([]);
  const { currentAccessAct } = useAppSelector(
    (state: RootState) => state.accessActs
  );

  useEffect(() => {
    setResultData(getCollapsedData(currentAccessAct?.construction_objects, isCollapsedList));
  }, [isCollapsedList, currentAccessAct?.construction_objects]);
  
  const columns = useMemo<Array<ColumnDef<IAccessActConstructionObject, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("address", {
        header: "Адрес",
      }),
    ],
    []
  );

  return <Table data={resultData ?? []} columns={columns} />;
}
