import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosPromise } from "axios";

export const createAsyncThunkInstance = <T, K>(
  actionTypeString: string,
  callback: (param: K) => AxiosPromise<T>
) =>
  createAsyncThunk<T, K, { rejectValue: AxiosError }>(
    actionTypeString,
    async (param: K, { rejectWithValue }) => {
      try {
        const { data } = await callback(param);

        return data;
      } catch (error: unknown) {
        console.error(error);

        throw rejectWithValue(error as AxiosError);
      }
    }
  );
