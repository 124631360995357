import { Navigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Flex } from "@chakra-ui/react";
import { AuthLayout } from "../../components/AuthLayout/AuthLayout";
import { AuthTitle } from "../../components/AuthTitle/AuthTitle";
import { PrimaryButton } from "../../components/lib/button/Button";
import { Input } from "../../components/lib/input/Input";
import { PasswordInput } from "../../components/lib/input/PasswordInput";
import { CustomLink } from "../../components/lib/customLink/CustomLink";
import { CheckboxInput } from "../../components/lib/input/CheckboxInput";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { ILoginRequest } from "../../api/auth/types";
import { loginUser } from "../../store/slices/auth";

export const Login = () => {
  const dispatch = useAppDispatch();
  const { loginError, token } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const onSubmit = (values: ILoginRequest) => dispatch(loginUser(values));

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailRegex, "Неверный логин или пароль")
        .required("Неверный логин или пароль"),
      password: Yup.string().required("Неверный логин или пароль"),
    }),

    onSubmit,
  });

  const renderErrorMessage = () => {
    if (formik.touched.email && !!formik.errors.email) {
      return <ErrorMessage message={formik.errors.email} />;
    }
    if (loginError) {
      return <ErrorMessage message={loginError} />;
    }

    return <></>;
  };

  if (token) {
    return <Navigate to="/main"  state={"/login"}/>;
  }

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit}>
        <AuthTitle title="Авторизация" />
        <Flex direction="column" gap={4}>
          {renderErrorMessage()}
          <Input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Введите e-mail"
            error={
              (formik.touched.email && !!formik.errors.email) || !!loginError
            }
          />
          <PasswordInput
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.password && !!formik.errors.password) ||
              !!loginError
            }
          />
        </Flex>
        <Flex justify="space-between" align="center">
          <CheckboxInput label="Запомнить меня" />
          <CustomLink content="Забыли пароль?" href="/restore-password" />
        </Flex>
        <PrimaryButton type="submit">Войти</PrimaryButton>
      </form>
    </AuthLayout>
  );
};
