import { Title } from "../../../lib/title/Title";
import { ReactComponent as Cross } from "../../../../images/svg/cross/cross.svg";
import styles from "./modal-header.module.scss";

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
}

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <div className={styles.modal_title}>
      <Title>{props.title}</Title>
      <span className={styles.cross_wrapper} onClick={props.onClose}>
        <Cross />
      </span>
    </div>
  );
}
