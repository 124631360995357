import { useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Flex, Text } from "@chakra-ui/react";
import { Table } from "../../lib/table/Table";
import { ReactComponent as FilePreview } from "../../../images/svg/file/file-preview.svg";

interface IFile {
  id: number;
  title: string;
  date: string;
  status: string;
}

const file: IFile = {
  id: 1,
  title: "Платежное поручение от 07 07 23.pdf",
  date: "11.08.2023",
  status: "Получен из 1С",
};

const columnHelper = createColumnHelper<IFile>();

const TitleCell = (info: CellContext<IFile, string>) => (
  <Flex align="center" gap={2}>
    <FilePreview />
    <Text>{info.renderValue()}</Text>
  </Flex>
);

export function FilesTable() {
  const columns = useMemo<Array<ColumnDef<IFile, string>>>(
    () => [
      columnHelper.accessor("title", {
        header: "Имя файла",
        cell: TitleCell,
      }),
      columnHelper.accessor("date", {
        header: "Дата создания",
      }),
      columnHelper.accessor("status", {
        header: "Статус",
      }),
    ],
    []
  );

  return <Table data={Array(6).fill(file)} columns={columns} />;
}
