import { Navigate, useLocation } from "react-router-dom";

interface RequireAuthProps {
  children: React.JSX.Element;
}

export function RequireAuth(props: RequireAuthProps) {
  const location = useLocation();

  return localStorage.getItem("token") ? (
    props.children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
