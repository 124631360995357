import { OutputData } from "@editorjs/editorjs";
import { Modal, ModalProps } from "../Modal";
import { ModalHeader } from "../ModalRight/ModalHeader/ModalHeader";
import { Carousel } from "../../Carousel/Carousel";
import styles from ".././modal.module.scss";
import { useAppDispatch } from "../../../store";
import { changeOnbordingCompeleted } from "../../../store/slices/onboarding";
import { AccessRights } from "../../../shared/mock/sidebarContent";

type TutorialModalProps = Omit<ModalProps, "children"> & {
  screens: OutputData[];
  permission?: AccessRights | null;
};

export function TutorialModal({active, onClose, screens, permission}: TutorialModalProps) {
  const dispatch = useAppDispatch();

  const handleClickLast = () => {
    dispatch(changeOnbordingCompeleted())
    onClose();
  }

  return (
    <Modal active={active} onClose={onClose}>
      <div
        className={styles.tutorial_container}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.header_wrapper}>
          <ModalHeader title={"Обучение"} onClose={onClose} />
        </div>
        <Carousel data={screens} handleClickLast={handleClickLast} permission={permission} />
      </div>
    </Modal>
  );
}
