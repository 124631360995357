import { Box, Flex } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { RequirementsAccountTable } from "../../components/RequirementsAccountTable/RequirementsAccountTable";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OutlineButton } from "../../components/lib/button/Button";
import { ReactComponent as File } from "../../images/svg/file/file-red.svg";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchReqFiles, resetCurrentReq, resetCurrentReqFiles } from "../../store/slices/requirements";
import { useEffect, useState } from "react";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { IFile } from "../../api/files/types";

export function RequirementsAccountPage() {
  const { currentReqFiles, currentReq } = useAppSelector((state: RootState) => state.requirements);

  const [files, setFiles] = useState<IFile[]>(currentReqFiles)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filesModal, setFilesModal] = useState<boolean>(false)

  const dispatch = useAppDispatch();

  const handleFilesBtnClick = () => {
    setIsLoading(true)
    currentReq && dispatch(fetchReqFiles(currentReq.id))
      .then((res: any) => {
        if (res.payload.success) {
          setFilesModal(true)
        }
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setFiles(currentReqFiles)
  }, [currentReqFiles])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentReq())
    }
  }, [])

  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Flex justifyContent="space-between">
          <Title>Учёт требований</Title>
          <OutlineButton leftIcon={<File />} onClick={handleFilesBtnClick} isLoading={isLoading} isDisabled={!currentReq}>Файлы</OutlineButton>
        </Flex>

        <RequirementsAccountTable />
        <Box>
          <OnboardingByPermission permission={AccessRights.Requirement_RW} />
        </Box>
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => {
          setFiles([])
          setFilesModal(false)
          dispatch(resetCurrentReqFiles())
        }}
        receivedFiles={files ?? []}
        isDisabled={true}
      />
    </MainLayout>
  );
}
