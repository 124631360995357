import { ChangeEvent, SyntheticEvent, useState } from "react";
import { CustomLink } from "../../customLink/CustomLink";
import { ReactComponent as FilePreview } from "../../../../images/svg/file/file-preview.svg";
import { ReactComponent as Cross } from "../../../../images/svg/cross/cross.svg";
import FileLink from "../../../../images/svg/file/file.svg";
import { formatBytes } from "../../../../helpers/formatBytes";
import styles from "./drop-file-input.module.scss";

interface DropFileInputProps {
  text: string;
  onChange: (files: File[]) => void;
  id?: string;
  label?: string;
  name?: string;
  value?: File[];
  isLimitedFormat?: boolean;
  linkText?: string;
  onLinkClick?: (e: SyntheticEvent) => void;
  isDisabled?: boolean;
}

export function DropFileInput(props: Readonly<DropFileInputProps>) {
  const {
    text,
    onChange,
    label = "",
    name,
    value = [],
    isLimitedFormat = false,
    linkText = "",
    onLinkClick,
    isDisabled = false,
  } = props;

  const [fileList, setFileList] = useState<File[]>(value);

  const onFileChange = (files: File[]) => {
    onChange(files);
  };

  const onFileDrop = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const newFile: File = (target.files as FileList)[0];

    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file: File) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.file_input}>
        <div className={styles.file_input_label}>
          <div>
            {text} или нажмите <CustomLink content={"загрузить"} />
          </div>
          {isLimitedFormat && <span>Формат: .jpg, .png, .pdf, до 5 Мб</span>}
        </div>
        <input
          id={name}
          type="file"
          value=""
          onChange={onFileDrop}
          multiple
          accept=".jpg,.png,.pdf"
          disabled={isDisabled}
        />
      </div>
      {fileList.length > 0 ? (
        <div className={styles.preview}>
          {fileList.map((item: File) => (
            <div key={item.name} className={styles.preview_item}>
              <FilePreview />
              <div className={styles.preview_item_info}>
                <p>{item.name}</p>
                <span>{formatBytes(item.size)}</span>
              </div>
              <div
                className={styles.preview_item_remove}
                onClick={() => fileRemove(item)}
                onKeyDown={() => fileRemove(item)}
              >
                <Cross />
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {linkText && (
        <div className={styles.link_wrapper}>
          <CustomLink
            content={linkText}
            icon={FileLink}
            onClick={onLinkClick}
          />
        </div>
      )}
    </div>
  );
}
