import { useState } from "react";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { DropFileInput } from "../../lib/input/drop-file-input/DropFileInput";
import { Modal, ModalProps } from "../Modal";
import { TextArea } from "../../lib/textarea/TextArea";
import { AuthTitle } from "../../AuthTitle/AuthTitle";
import { CustomLink } from "../../lib/customLink/CustomLink";
import styles from ".././modal.module.scss";
import { useFormik } from "formik";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { writeFeedbackExecutor } from "../../../store/slices/contracts";

type ExecutorMessageModalProps = Omit<ModalProps, "children"> & {
  title: string;
};

interface SuccessMessageProps {
  onClose: () => void;
}

export function ExecutorMessageModal(props: ExecutorMessageModalProps) {
  const { active, onClose, title } = props;
  const [success, setSuccess] = useState(false);
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    currentContract && dispatch(writeFeedbackExecutor({ id: currentContract?.id, files: formik.values.files, message: formik.values.message }))
      .then((res:any) => {
        if (res.payload?.success) {
          setSuccess(true)
          formik.resetForm()
        }
      })
  }

  const handleSendMessage = () => {
    onClose();
    setSuccess(false);
  };

  const formik = useFormik({
    initialValues: {
      files: [],
      message: ""
    },

    onSubmit,
  });

  const handleFileInputChange = (files: File[]) => {
    formik.setFieldValue("files", files);
  };

  return (
    <Modal active={active} onClose={() => {
      formik.resetForm();
      onClose();
    }
    }>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={() => {
            formik.resetForm();
            onClose();
          }
          } />

          <form className={styles.form}>
            {success ? (
              <SuccessMessage onClose={handleSendMessage} />
            ) : (
              <>
                <div className={styles.modal_info}>
                  <TextArea
                    label="Текст сообщения"
                    id="message"
                    placeholder="Введите текст сообщения"
                    name="message"
                    formik={formik}
                  />

                  <DropFileInput
                    text="Перетащите сюда файл(ы)"
                    label="Загрузка файлов"
                    onChange={handleFileInputChange}
                  />
                </div>
                <ModalFooter>
                  <OutlineButton onClick={() => {
                    formik.resetForm();
                    onClose();
                  }
                  }>Отмена</OutlineButton>
                  <PrimaryButton isDisabled={formik.values.message === ""} onClick={onSubmit}>
                    Отправить
                  </PrimaryButton>
                </ModalFooter>
              </>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}

export function SuccessMessage(props: SuccessMessageProps) {
  return (
    <div className={styles.success}>
      <AuthTitle title="Письмо успешно отправлено" isSuccess />
      <div onClick={props.onClose}>
        <CustomLink content="Закрыть" href="#" />
      </div>
    </div>
  );
}
