import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { FeedbackEndpoints } from "../endpoints";
import { IFeedbackResponse } from "./types";

export const sendSecurityService = (
  params: FormData
): AxiosPromise<IFeedbackResponse> => {
  return axiosInstance.post(FeedbackEndpoints.SECURITY_SERVICE, params);
};
export const sendTechSupport = (
  params: FormData
): AxiosPromise<IFeedbackResponse> => {
  return axiosInstance.post(FeedbackEndpoints.TECH_SUPPORT, params);
};
