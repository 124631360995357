import { Flex } from "@chakra-ui/react";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Title } from "../../components/lib/title/Title";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { Outlet } from "react-router-dom";
import { remarksPageTabs } from "../../shared/mock/remarksPageData";

export function RemarksPage() {
  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={4} p="6">
        <Flex justifyContent="space-between">
          <Title>Замечания</Title>
        </Flex>
        <LinkTabs tabs={remarksPageTabs} />
        <Outlet />
      </Flex>
    </MainLayout>
  );
}
