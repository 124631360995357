import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { AuthEndpoints } from "../endpoints";
import {
  IAdminLoginRequest,
  ILoginRequest,
  ILoginResponse,
  ILogoutResponse,
  IPasswordChangeRequest,
  IPasswordChangeResponse,
  IPasswordEmailRequest,
  IPasswordEmailResponse,
  IPasswordResetRequest,
  IPasswordResetResponse,
} from "./types";

export const login = (params: ILoginRequest): AxiosPromise<ILoginResponse> =>
  axiosInstance.post(AuthEndpoints.LOGIN, params);

export const adminLogin = (params: IAdminLoginRequest): AxiosPromise<ILoginResponse> =>
  axiosInstance.post(AuthEndpoints.ADMIN_LOGIN, params);

export const logout = (): AxiosPromise<ILogoutResponse> => {
  return axiosInstance.post(AuthEndpoints.LOGOUT);
};

export const emailPassword = (
  params: IPasswordEmailRequest
): AxiosPromise<IPasswordEmailResponse> => {
  return axiosInstance.post(AuthEndpoints.PASSWORD_EMAIL, params);
};

export const resetPassword = (
  params: IPasswordResetRequest
): AxiosPromise<IPasswordResetResponse> => {
  return axiosInstance.post(AuthEndpoints.PASSWORD_RESET, params);
};

export const changePassword = (
  params: IPasswordChangeRequest
): AxiosPromise<IPasswordChangeResponse> => {
  return axiosInstance.post(AuthEndpoints.PASSWORD_CHANGE, params);
};
