import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { NotificationsEndpoints } from "../endpoints";
import { INotificationRequest } from "./types";
import { ICommonResponse } from "../commonResponseType";

export const getNotifications = (): AxiosPromise<INotificationRequest> =>
  axiosInstance.get(NotificationsEndpoints.NOTIFICATIONS);

export const viewNotification = (id: number): AxiosPromise<ICommonResponse> =>
  axiosInstance.get(NotificationsEndpoints.NOTIFICATIONS + `/${id}/viewed`);