import Select, { MultiValue } from "react-select";
import styles from "./select.module.scss";

export type Option = {
  label: string;
  value: number;
};

export interface MultiSelectProps {
  options: Option[];
  value: Option[];
  onChange: (options: MultiValue<Option>) => void;
  label?: string;
  placeholder?: string;
  error?: boolean;
}

export function MultiSelect(props: MultiSelectProps) {
  const {
    options,
    value,
    onChange,
    label = "",
    placeholder = "Выберите из списка",
    error = false,
  } = props;
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <Select
        classNamePrefix={error ? "custom_select_error" : "custom_select"}
        placeholder={placeholder}
        options={options}
        isMulti
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
