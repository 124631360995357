import { axiosInstance } from "../api/instance";

export const downloadElement = (url: string, fileName: string): Promise<void> =>
  axiosInstance
    .get(url, { responseType: "blob" })
    .then((response) => {
      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.error("Ошибка загрузки: ", error);
    });
