import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, Text } from "@chakra-ui/react";
import { ReactComponent as Book } from "../../images/svg/sidebar/book.svg";

interface OnboardingLinkProps {
  onClick?: () => void;
}

export function OnboardingLink(props: OnboardingLinkProps) {
  return (
    <Link
      w="min-content"
      isExternal
      _hover={{ color: "#343B4C", svg: { fill: "#343B4C" } }}
      display="flex"
      alignItems="center"
      lineHeight="20px"
      gap={2}
      color="#5C657E"
      onClick={props.onClick}
    >
      <ExternalLinkIcon as={Book} fill="#5C657E" />
      <Text>Онбординг</Text>
    </Link>
  );
}
