import { Box, Flex } from "@chakra-ui/react";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Title } from "../../components/lib/title/Title";
import { SigningTable } from "../../components/SigningTable/SigningTable";
import { OutlineButton } from "../../components/lib/button/Button";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

export const SigningPage = () => {
    return (
        <MainLayout>
            <Flex direction="column" gap={4} p="6">
                <Flex>
                    <Title>Подписание</Title>
                    <OutlineButton leftIcon={<ExternalLinkIcon />} >
                        Инструкция ДИАДОК
                    </OutlineButton>
                </Flex>
                <SigningTable />
                <Box>
                    <OnboardingByPermission permission={AccessRights.Signing_RW} />
                </Box>
            </Flex>
        </MainLayout>
    );
}