import {
  Box,
  Flex,
  Menu,
  MenuButton,
  Text,
  MenuOptionGroup,
  Checkbox,
  MenuList,
  Link,
  Divider,
  Button,
  MenuItem
} from "@chakra-ui/react";
import {ReactComponent as Filter} from "../../images/svg/documents/filter.svg";
import {Column, Table} from "@tanstack/react-table";
import {MenuItemOptionProps} from "@chakra-ui/menu/dist/menu-item-option";
import { useEffect } from "react";

export const FilterHeader = (column: Column<any, unknown>, table: Table<any>, text: string) => {
  const uniqueValuesSet: Set<string> = new Set();
  table
    .getPreFilteredRowModel()
    .flatRows.forEach((row, index) => {
      if (row.getValue(column.id) !== "" && row.getValue(column.id) !== undefined) {
        const value = row.getValue(column.id);
        uniqueValuesSet.add(value as string);
      }
    });

  const uniqueValuesArray = Array.from(uniqueValuesSet);
  const isFiltered = column.getIsFiltered();
  let columnFilterValue = column.getFilterValue() as string[];

  const updateFilterValue = (value: string, isChecked: boolean) => {
    column.setFilterValue((old: string[]) =>  {
      if (!old) {
        return [value]
      }

      return isChecked
        ? old.filter((oldValue: string) => oldValue !== value)
        : [...old, value];
    })
  };

  useEffect(() => {
    if (columnFilterValue?.length === 0) {
      column.setFilterValue(undefined)
    }
  }, [columnFilterValue, column])

  return (
    <Flex justify="space-between" w="full" align="center">
      <Text>{text}</Text>
    <Menu closeOnSelect={false}>
      <MenuButton>
        <Box position="relative" >
        <Filter width={16} height={16} cursor="pointer"/>
          {isFiltered && (
            <Box
              w={2}
              h={2}
              borderRadius="50%"
              border="2px solid white"
              bg="#E8363D"
              position="absolute"
              top={-1}
              right={-1}
            />
          )}
        </Box>
      </MenuButton>
      <MenuList minWidth="240px" maxHeight="200px" overflowY="auto" border="1px solid #E3E7EF"
                boxShadow="0 0 1px 0 #1F222A05,0 2px 6px 0 #1F222A05, 0 8px 24px 0 #1F222A0D" p="4px"
                position="relative">
        <MenuOptionGroup title='Фильтры' color="#343B4C" type="checkbox" fontWeight="500"
                         fontSize={16} lineHeight="20px" textAlign="left" m="8px">
          <Link as={Button} color="#2AB6A5" fontWeight="400" fontSize={14} lineHeight="18px" position="absolute"
                top="14px" right="12px" bg="transparent" p={0} height="auto"
                _hover={{bg: "transparent", textDecoration: "underline"}}
                _focus={{bg: "transparent", textDecoration: "underline"}}
                onClick={() => column.setFilterValue("")}>
            Сбросить
          </Link>
          {uniqueValuesArray.map((value: string) => {
            const isChecked = (columnFilterValue as string[])?.includes(value);
            return (
            <CustomMenuItem value={value} key={value}
                            isChecked={isChecked}
                            onChange={() => updateFilterValue(value, isChecked)}/>
          )})}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
    </Flex>
  );
};

function CustomMenuItem(props: MenuItemOptionProps) {
  const {value, onChange, isChecked} = props;

  return (
    <>
      <Divider color="#E3E7EF"/>
      <MenuItem color="#343B4C" fontWeight="400" fontSize={14}
        lineHeight="18px" m="2px 0" p="8px" textAlign="left" onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onChange && onChange(event);
        }}>
        <Checkbox value={value} borderColor="#AAB0C1" colorScheme="teal" isChecked={isChecked}>{value}</Checkbox>
      </MenuItem>
    </>
  )
}
