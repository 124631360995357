import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import {
  IPrintFormRemarksResponse,
  IRemarksConstruction,
  IRemarksConstructionResponse,
  IRemarksPrintFormRequest,
  IRemarksTransmission,
  IRemarksTransmissionResponse,
  ISingleRemarkConstruction,
  ISingleRemarkConstructionResponse,
  ISingleRemarkTransmission,
  ISingleRemarkTransmissionRequest,
  ISingleRemarkTransmissionResponse,
} from "../../api/remarks/types";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { AxiosError } from "axios";
import { acceptRemarksTransmission, fixedRemarksTransmission, printFormRemarks } from "../../api/remarks";
import { IFilesAttachResponse } from "../../api/files/types";
import { attachFiles } from "../../api/files";
import { Files, RemarksEndpoints } from "../../api/endpoints";

interface IRemarksInitialState extends ICommonInitialState {
  remarksConstructionList: IRemarksConstruction[];
  currentRemarkConstruction: ISingleRemarkConstruction | null;
  remarksTransmissionList: IRemarksTransmission[];
  currentRemarkTransmission: ISingleRemarkTransmission | null;
}

const initialState: IRemarksInitialState = {
  remarksConstructionList: [],
  currentRemarkConstruction: null,
  remarksTransmissionList: [],
  currentRemarkTransmission: null,
  isLoading: false,
  error: null,
};

export const fetchRemarksConstructionList = createAsyncThunkInstance<
  IRemarksConstructionResponse,
  undefined
>(
  "remarks/fetchRemarksConstructionList",
  api.remarks.getRemarksConstructionList
);

export const fetchSingleRemarkConstruction = createAsyncThunkInstance<
  ISingleRemarkConstructionResponse,
  string
>(
  "remarks/fetchSingleRemarkConstruction",
  api.remarks.getSingleRemarkConstruction
);

export const applyRemarkCounstruction = createAsyncThunkInstance<
  undefined,
  string
>("remarks/applyRemarkCounstruction", api.remarks.applyRemarkConstruction);

export const fetchRemarksTransmissionList = createAsyncThunkInstance<
  IRemarksTransmissionResponse,
  undefined
>(
  "remarks/fetchRemarksTransmissionList",
  api.remarks.getRemarksTransmissionList
);

export const fetchSingleRemarkTransmission = createAsyncThunkInstance<
  ISingleRemarkTransmissionResponse,
  string
>(
  "remarks/fetchSingleRemarkTransmission",
  api.remarks.getSingleRemarkTransmission
);

export const acceptRemarks = createAsyncThunk<
  undefined,
  { params: ISingleRemarkTransmissionRequest },
  { rejectValue: AxiosError }
>("remarks/acceptRemarks", async ({ params }, { rejectWithValue }) => {
  try {
    const { data } = await acceptRemarksTransmission(params);

    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as AxiosError);
  }
});

export const fixedRemarks = createAsyncThunk<
  undefined,
  { params: ISingleRemarkTransmissionRequest },
  { rejectValue: AxiosError }
>("remarks/fixedRemarks", async ({ params }, { rejectWithValue }) => {
  try {
    const { data } = await fixedRemarksTransmission(params);

    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as AxiosError);
  }
});

export const fetchPrintFormRemarks = createAsyncThunk<
  IPrintFormRemarksResponse,
  { params: IRemarksPrintFormRequest },
  { rejectValue: AxiosError }
>(
  "workCompletedActs/fetchPrintFormWorkAct",
  async ({params }, { rejectWithValue }) => {
    try {
      const { data } = await printFormRemarks(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const rejectRemarkTransmission = createAsyncThunk<
  undefined,
  { id: string; params: { counterparty_comment: string } },
  { rejectValue: AxiosError }
>(
  "remarks/rejectRemarkTransmission",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await api.remarks.rejectRemarkTransmission(id, params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToRemarkConstruction = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "remarks/attachFilesToRemarkConstruction",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(
        RemarksEndpoints.REMARK_CONSTRUCTION + `/${id}` + Files.ATTACH_FILES,
        files
      );
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToRemarkTransmission = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "remarks/attachFilesToRemarkConstruction",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(
        RemarksEndpoints.REMARK_TRANSMISSION + `/${id}` + Files.ATTACH_FILES,
        files
      );
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const remarksSlice = createSlice({
  name: "remarks",
  initialState,
  reducers: {
    resetCurrentRemarkConstruction(state) {
      state.currentRemarkConstruction = null;
    },
    resetCurrentRemarkTransmission(state) {
      state.currentRemarkTransmission = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRemarksConstructionList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRemarksConstructionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.remarksConstructionList = action.payload.data;
      })
      .addCase(fetchSingleRemarkConstruction.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleRemarkConstruction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentRemarkConstruction = action.payload.data;
      })
      .addCase(fetchRemarksTransmissionList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRemarksTransmissionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.remarksTransmissionList = action.payload.data;
      })
      .addCase(fetchSingleRemarkTransmission.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleRemarkTransmission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentRemarkTransmission = action.payload.data;
      });
  },
});

export const remarksReducer = remarksSlice.reducer;
export const { resetCurrentRemarkConstruction, resetCurrentRemarkTransmission } = remarksSlice.actions;
