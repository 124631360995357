import React, { useEffect, useState } from "react";
import { Flex, Input, InputGroup } from "@chakra-ui/react";
import { InformationItemDateInput } from "../InformationBlock/InformationItemDateInput";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { useAppSelector } from "../../store";
import { IPaymentRp } from "../../api/workCompletedActs/types";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { CloseIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../lib/button/Button";

interface PaymentFiledsProps {
    setFieldValue?: any
    formik?: any
}

export function PaymentFileds({setFieldValue, formik}: PaymentFiledsProps) {
    const [inputs, setInputs] = useState<IPaymentRp[]>([{ amount: "", date: "" }]);
    const { currentDirectionLetter } = useAppSelector((state) => state.directionLetters);
    const isDisabled = currentDirectionLetter?.status !== "На согласовании (Подрядчик)" && !!currentDirectionLetter

    const handleChange = (value: string, fieldName: keyof IPaymentRp, index: number) => {
        let onChangeValue = [...inputs];
        onChangeValue[index] = JSON.parse(JSON.stringify(onChangeValue[index]));
        if (fieldName === "date") {
            if (value !== "") {
                onChangeValue[index][fieldName] = dayjs(value).format("DD.MM.YYYY")
            } else {
                onChangeValue[index][fieldName] = value
            }
            setFieldValue("advance_payment_schedule", onChangeValue)
        } else {
            onChangeValue[index][fieldName] = value;
        }
        setInputs(onChangeValue)
    }

    const deleteInput = (index: number) => {
        if (inputs.length > 1) {
            const resultInputs = inputs.filter((_,i) => i !== index);
            setInputs(resultInputs);
            setFieldValue("advance_payment_schedule", resultInputs)
        }
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, fieldName: keyof IPaymentRp, index: number) => {
        let rawValue = e.target.value;
        let formatValue: string;

        if (rawValue.trim() === "") {
            formatValue = "";
        } else {
            const num = parseFloat(rawValue.replace(/[^0-9.]/g, ""));
            formatValue = isNaN(num) ? "0.000" : num.toFixed(3);
        }

        setInputs((prevInputs) => {
            return prevInputs.map((item, i) => {
                if (i === index) {
                    return { ...item, [fieldName]: formatValue };
                }
                return item;
            });
        });
        setFieldValue("advance_payment_schedule", inputs)
    };

    const handleAddLine = () => {
        const duplicateArray = [...inputs, { amount: "", date: "" }]
        setInputs(duplicateArray)
    }

    useEffect(() => {
        if (currentDirectionLetter) {
            if (currentDirectionLetter.advance_payment_schedule.length > 0) {
                setInputs(currentDirectionLetter.advance_payment_schedule)
            } else {
                setInputs([{ amount: "", date: "" }])
            }
        }
    }, [currentDirectionLetter])

    const PeriodCell = ({ index }: { index: number }) => {
        const [value, setValue] = useState(inputs[index].date !== "" ? dayjs(reverseDate(inputs[index].date)).format("YYYY-MM-DD") : "");

        const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setValue(newValue);
        };

        return (
            <InformationItemDateInput
                isDisabled={isDisabled}
                id={`document_field_date_${index}`}
                name={`document_field_date_${index}`}
                value={value}
                w="240px"
                placeholder="От"
                onBlur={(event) => handleChange(event.target.value, "date", index)}
                onChange={onDateChange}
                isInvalid={inputs[index].amount !== "" && inputs[index].date === ""}
            />
        )
    }

    return (
        <Flex flexDirection="column" gap="10px">
            <InformationBlock>
                <Flex direction="column" gap={4}>
                    {inputs.map((input, index) => {
                        return (
                            <InputGroup alignItems="center" key={index} gap={4}>
                                <Input
                                    isDisabled={isDisabled}
                                    id={`document_field_name_${index}`}
                                    name={`document_field_name_${index}`}
                                    type="number"
                                    value={inputs[index].amount ?? ""}
                                    onChange={(event) => handleChange(event.target.value, "amount", index)}
                                    onBlur={(event) => handleBlur(event, "amount", index)}
                                    placeholder="Сумма зачета"
                                    width="100%"
                                    h="36px"
                                    rounded="md"
                                    borderColor="#E3E7EF"
                                    isInvalid={inputs[index].date !== "" && inputs[index].amount === ""}
                                />
                                <PeriodCell index={index} />
                                <CloseIcon w="14px" sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        opacity: 0.6
                                    }
                                }}
                                    onClick={() => {
                                        !isDisabled && deleteInput(index)
                                    }}
                                />
                            </InputGroup>
                        )
                    })}
                </Flex>
            </InformationBlock>
            <PrimaryButton width="max-content" onClick={handleAddLine} isDisabled={isDisabled}>Добавить строку</PrimaryButton>
        </Flex>
    )
}