import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import {
  IReconciliationStatement,
  IReconciliationStatementResponse,
  IUpdateReconciliationStatementRequest,
} from "../../api/reconcilitiaonStatement/types";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { ICommonResponse } from "../../api/commonResponseType";
import { IPrintFormResponse } from "../../api/directionLetters/types";

interface IReconciliationStatementState extends ICommonInitialState {
  reconciliationStatement: IReconciliationStatement | null;
}

const initialState: IReconciliationStatementState = {
  reconciliationStatement: null,
  isLoading: false,
  error: null,
};

export const fetchReconciliationStatement = createAsyncThunkInstance<
  IReconciliationStatementResponse,
  string
>(
  "reconciliationStatement/fetchReconciliationStatement",
  api.reconciliationStatement.getReconciliationStatement
);

export const updateReconciliatonStatement = createAsyncThunk<
  ICommonResponse,
  { params: IUpdateReconciliationStatementRequest; id: string },
  { rejectValue: string }
>(
  "reconciliationStatement/updateReconciliatonStatement",
  async ({ params, id }, { rejectWithValue }) => {
    try {
      const { data } =
        await api.reconciliationStatement.updateReconciliationStatement(
          params,
          id
        );
      return data;
    } catch (error: unknown) {
      console.error(error);

      throw rejectWithValue("Не удалось получить данные");
    }
  }
);

export const fetchReconciliationPrintForm = createAsyncThunkInstance<
  IPrintFormResponse,
  string
>(
  "reconciliationStatement/fetchReconciliationPrintForm",
  api.reconciliationStatement.getReconciliationPrintForm
);

const reconciliationSlice = createSlice({
  name: "reconciliation",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchReconciliationStatement.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReconciliationStatement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reconciliationStatement = action.payload.data;
      });
  },
});

export const reconciliationReducer = reconciliationSlice.reducer;
