import { Box, Flex, Input, Select } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { RootState, useAppSelector } from "../../store";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { useEffect, useState } from "react";
import { setIn, useFormik } from "formik";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { fetchPrintForm } from "../../store/slices/directionLetters";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { SupplierDropdown } from "./SupplierDrowdown";
import { IAccount } from "../../api/supplier/types";
import { SingleAdvanceType } from "../SingleAdvanceType/SingleAdvanceType";

interface AdditionalInformationBlockProps {
  setFilesModal: (arg: boolean) => void;
  formik: any;
  setFieldValue: any
  setDisableApprove: (arg: boolean) => void
}

export function AdditionalInformationBlock({
  setFilesModal, formik, setFieldValue, setDisableApprove
}: AdditionalInformationBlockProps) {
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);
  const { currentSupplier, selectedAccount } = useAppSelector((state: RootState) => state.supplier);
  const { currentContract } = useAppSelector((state: RootState) => state.contracts);
  const [index, setIndex] = useState<number | null>(null)
  const { downloadFile } = useUniversalDownloadFile();

  const isDisabled = currentDirectionLetter?.status !== "На согласовании (Подрядчик)"

  const [selectedData, setSelectedData] = useState<any>();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState(false);

  useEffect(() => {
    if (currentDirectionLetter && currentSupplier) {
      const elIndex = currentSupplier?.accounts.findIndex(el => el.guid === currentDirectionLetter?.checking_account_guid)
      if (elIndex > -1) {
        setIndex(elIndex)
      }
    }

  }, [currentDirectionLetter, currentSupplier])



  useEffect(() => {
    setFieldValue(
      "supplier_director_full_name",
      currentDirectionLetter?.supplier_director_full_name ??
      currentSupplier?.director_full_name
    );
    setFieldValue(
      "supplier_director_role",
      currentDirectionLetter?.supplier_director_role ??
      currentSupplier?.director_role
    );
    setFieldValue(
      "payment_assignment",
      currentDirectionLetter?.payment_assignment ?? ""
    );

    setFieldValue(
      "invoice_number",
      currentDirectionLetter?.invoice_number ?? ""
    );
    setFieldValue(
      "invoice_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.invoice_date)).format(
          "YYYY-MM-DD"
        )
        : ""
    );

    setFieldValue("order_number", currentDirectionLetter?.order_number ?? "");
    setFieldValue(
      "order_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.order_date)).format(
          "YYYY-MM-DD"
        )
        : ""
    );

    setFieldValue(
      "supplier_contract_number",
      currentDirectionLetter?.supplier_contract_number ?? ""
    );
    setFieldValue(
      "supplier_contract_date",
      currentDirectionLetter
        ? dayjs(
          reverseDate(currentDirectionLetter?.supplier_contract_date)
        ).format("YYYY-MM-DD")
        : ""
    );

    setFieldValue(
      "specification_number",
      currentDirectionLetter?.specification_number ?? ""
    );
    setFieldValue(
      "specification_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.specification_date)).format(
          "YYYY-MM-DD"
        )
        : ""
    );
    setFieldValue(
      "supplier_invoice_number",
      currentDirectionLetter?.supplier_invoice_number ?? ""
    );
    setFieldValue(
      "supplier_invoice_date",
      currentDirectionLetter?.supplier_invoice_date ?? ""
    );
    setFieldValue(
      "supplier_guid",
      currentDirectionLetter?.supplier_guid ?? ""
    );
  }, [setFieldValue, currentDirectionLetter, currentSupplier]);

  useEffect(() => {
    setFieldValue(
      "checking_account_guid",
      selectedAccount?.guid ?? ""
    );
    setFieldValue(
      "checking_account_number",
      selectedAccount?.number ?? ""
    );
    setFieldValue(
      "checking_account_bic",
      selectedAccount?.bic ?? ""
    );

  }, [selectedAccount, setFieldValue])


  return (
    <Flex flexDirection="column" gap="16px">
      <InformationBlock>
        <InformationItem title={`Счет с ${currentDirectionLetter?.supplier_name}`}>
          <InformationItemForm
            firstId="supplier_invoice_number"
            secondId="supplier_invoice_date"
            firstValue={formik.values.supplier_invoice_number}
            secondValue={formik.values.supplier_invoice_date}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title={`Счет ${currentDirectionLetter?.a101_org_name}`}>
          <InformationItemForm
            firstId="invoice_number"
            secondId="invoice_date"
            firstValue={formik.values.invoice_number}
            secondValue={formik.values.invoice_date}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Номер заявки">
          <InformationItemForm
            firstId="order_number"
            secondId="order_date"
            firstValue={formik.values.order_number}
            secondValue={formik.values.order_date}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Договор с поставщиком">
          <InformationItemForm
            firstId="supplier_contract_number"
            secondId="supplier_contract_date"
            firstValue={formik.values.supplier_contract_number}
            secondValue={formik.values.supplier_contract_date}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Спецификация">
          <InformationItemForm
            firstId="specification_number"
            secondId="specification_date"
            firstValue={formik.values.specification_number}
            secondValue={formik.values.specification_date}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
            required={false}
          />
        </InformationItem>
        <InformationItem title={`Р/С  ${currentDirectionLetter?.supplier_name}`} isAccordion>
          <SupplierDropdown
            fetchAfterClick={true}
            isDisabled={isDisabled}
            documents={currentSupplier?.accounts ?? []}
            setFieldValue={setFieldValue}
            index={index}
            setDisableApprove={setDisableApprove}
            defaultNumber={currentDirectionLetter?.checking_account_number ?? ""}
            defaultBic={currentDirectionLetter?.checking_account_bic ?? ""}
            defaultBankName={currentDirectionLetter?.checking_account_back_name?? ""}
            defaultGuid={currentDirectionLetter?.checking_account_guid ?? ""}
            />

        </InformationItem>
        <InformationItem title="Руководитель">
          <Input
            id="supplier_director_full_name"
            name="supplier_director_full_name"
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            value={formik.values.supplier_director_full_name}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Должность руководителя">
          <Input
            id="supplier_director_role"
            name="supplier_director_role"
            defaultValue={currentSupplier?.director_role}
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            value={formik.values.supplier_director_role}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="БИК">
          {selectedAccount ? (
            `${selectedAccount?.bic}`
          ) : (
            <Input
              id="checking_account_bic"
              name="checking_account_bic"
              h="36px"
              rounded="md"
              borderColor="#E3E7EF"
              position="absolute"
              top="50%"
              transform="translate(0, -50%)"
              value={formik.values.checking_account_bic}
              onChange={(e) => {
                formik.handleChange(e);
                setDisableApprove(true)
              }}
            />
          )}

        </InformationItem>
        <InformationItem title="Название отделения банка">
          {selectedAccount ? (
            `${selectedAccount?.bank_name}`
          ) : (
            "" )}
        </InformationItem>
        <InformationItem title="Назначение платежа">
          <Input
            id="payment_assignment"
            name="payment_assignment"
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            isDisabled={isDisabled}
            value={formik.values.payment_assignment}
            onChange={(e) => {
              formik.handleChange(e);
              setDisableApprove(true)
            }}
            isInvalid={formik.values.payment_assignment === ""}
          />
        </InformationItem>
      </InformationBlock>
      <Flex gap="12px">
        <PrimaryButton isDisabled={isDisabled} onClick={() => setFilesModal(true)}>
          Прикрепить письмо
        </PrimaryButton>
        <OutlineButton isDisabled={isDisabled} onClick={() => downloadFile(currentDirectionLetter?.guid, setIsSpinnerRuning, fetchPrintForm, {type: "pdf"})} leftIcon={<DownloadIcon />}>Скачать в PDF</OutlineButton>
      </Flex>
      {currentContract?.advance_minimum_percentage === null && <SingleAdvanceType setFieldValue={setFieldValue} formik={formik} />}
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </Flex>
  );
}
