import { useAppSelector } from "../../../store";
import { Flex, Text } from "@chakra-ui/react";
import { BuildingObjectsTable } from "./BuildingObjectsTable";
import {useEffect, useState} from "react";
import { formatAmount } from "../../../helpers/formatAmount";

export function BuildingObjectsBlock() {
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const calculateTotal = (details:any) => {
      let total = 0;
      details.forEach((detail:any) => {
        total += Number(detail.Theamount);
        if (detail.subRows && detail.subRows.length > 0) {
          total += calculateTotal(detail.subRows);
        }
      });
      return total;
    };
    const totalSum = calculateTotal(currentDetails);
    const fixedTotalSum = totalSum.toFixed(2);
    setTotal(Number(fixedTotalSum));
  }, [currentDetails]);

  return (
    <Flex direction="column">
      <Text h="40px" textAlign="end" fontWeight={500}>
        Итого (без НДС): {formatAmount(total)}
      </Text>
      <BuildingObjectsTable />
    </Flex>
  );
}
