import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";

 interface FullScreenWaitingModalProps {
    openModal: boolean
 }

export function FullScreenWaitingModal(props:FullScreenWaitingModalProps) {
  return (
    <Modal isCentered isOpen={props.openModal} onClose={() => {}}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxWidth="641px" color="white" bg="none" boxShadow="none">
        <Flex gap="24px" w="641px" alignItems="center" flexDirection="column">
          <Spinner size="xl" />
          <Text fontSize="16px" fontWeight="400">
            Ожидание займет некоторое время, пожалуйста подождите...
          </Text>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
