import { AxiosPromise } from "axios";
import { IRequirementsListResponse, ISingleRequirementResponse } from "./types";
import { axiosInstance } from "../instance";
import { RequirementsEndpoints } from "../endpoints";
import { IPrintFormResponse } from "../directionLetters/types";

export const getRequirementsList =
  (): AxiosPromise<IRequirementsListResponse> =>
    axiosInstance.get(RequirementsEndpoints.REQUIREMENTS);


  export const getPrintForm = (id: number): AxiosPromise<ISingleRequirementResponse> =>
    axiosInstance.get(
      RequirementsEndpoints.REQUIREMENT + `/${id}/files`
    );