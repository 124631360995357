import { Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"
import { ChakraModalProps } from "../../../shared/types/modalProps";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { AsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { useAppDispatch } from "../../../store";
import { FullScreenWaitingModal } from "../FullScreenWaitingModal";
import { useState } from "react";

export interface IConfirmDeleteModalModal extends ChakraModalProps {
    setIsRunSpinner?: (isRunSpinner: boolean) => void;
    mainText: string;
    deleteId: string | number;
    updateId?: string | number;
    deleteFunction: AsyncThunk<any, any, {
        rejectValue: AxiosError;
        state?: unknown;
        dispatch?: Dispatch | undefined;
        extra?: unknown;
        serializedErrorType?: unknown;
        pendingMeta?: unknown;
        fulfilledMeta?: unknown;
        rejectedMeta?: unknown;
    }>;
    updateFunction?: AsyncThunk<any, any, {
        rejectValue: AxiosError;
        state?: unknown;
        dispatch?: Dispatch | undefined;
        extra?: unknown;
        serializedErrorType?: unknown;
        pendingMeta?: unknown;
        fulfilledMeta?: unknown;
        rejectedMeta?: unknown;
    }>
    updateFunctionWithoutId?: AsyncThunk<any, undefined, {
        rejectValue: AxiosError;
        state?: unknown;
        dispatch?: Dispatch | undefined;
        extra?: unknown;
        serializedErrorType?: unknown;
        pendingMeta?: unknown;
        fulfilledMeta?: unknown;
        rejectedMeta?: unknown;
    }>
    resetFunction?: any
}

export const ConfirmDeleteModal = ({ onClose, isOpen, mainText, deleteId, updateId, setIsRunSpinner, deleteFunction, updateFunction, resetFunction, updateFunctionWithoutId }: IConfirmDeleteModalModal) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmit = () => {
        setIsLoading(true)
        dispatch(deleteFunction(deleteId))
        .then(() => {
            resetFunction && dispatch(resetFunction());
            updateFunction && dispatch(updateFunction(updateId));
            updateFunctionWithoutId && dispatch(updateFunctionWithoutId())
            onClose();
        })
        .finally(() => setIsLoading(false))
    }
    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay backdropFilter="blur(3px)" />
                <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
                    <ModalHeader p={0}>
                        <Flex flexDirection="column" gap="12px" align="center">
                            <Text
                                fontSize="24px"
                                color="#343b4c"
                                lineHeight="28px"
                                letterSpacing="-1.5%"
                                fontWeight={500}
                                align="center">
                                Подтверждение удаления
                            </Text>
                            <Text fontSize={16} fontWeight={400} color="#5C657E" align="center">{`Вы действительно хотите удалить ${mainText}?`}</Text>
                        </Flex>
                    </ModalHeader>
                    <ModalFooter p={0}>
                        <Flex w="100%" gap="12px" align="center">
                            <OutlineButton w="full" onClick={onClose}>
                                Отмена
                            </OutlineButton>
                            <PrimaryButton w="full" onClick={handleSubmit}>
                                Подтвердить
                            </PrimaryButton>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <FullScreenWaitingModal openModal={isLoading} />
        </>

    )
}