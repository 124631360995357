import { RootState, useAppSelector } from "../../../store";
import { InformationBlock } from "../../InformationBlock/InformationBlock";
import { InformationItem } from "../../InformationBlock/InformationItem";

export const ProfileBankData = () => {
  const bankData = useAppSelector(
    (state: RootState) => state.profile.profileData.profile?.organization
  );

  return (
    <InformationBlock>
      <InformationItem title="ИНН">{bankData?.inn}</InformationItem>
      <InformationItem title="КПП">{bankData?.kpp}</InformationItem>
      <InformationItem title="ОГРН">{bankData?.ogrn}</InformationItem>
      <InformationItem title="ОКПО">{bankData?.okpo}</InformationItem>
      <InformationItem title="Расчётный счёт">
        {bankData?.corr_acc}
      </InformationItem>
      <InformationItem title="Банк">{bankData?.bank}</InformationItem>
      <InformationItem title="БИК">{bankData?.rcbic}</InformationItem>
      <InformationItem title="Корр. счёт">{bankData?.corr_acc}</InformationItem>
    </InformationBlock>
  );
};
