import {Flex, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import {BlockTitle} from "../lib/title/Title";
import { useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { PaymentFileds } from "./PaymentFields";
import { formatAmount } from "../../helpers/formatAmount";
interface SingleEstimateActPrintDataProps {
    setFieldValue?: any
    formik: any
}

export function SingleAdvanceType({setFieldValue, formik}: SingleEstimateActPrintDataProps) {
  const [radioValue, setRadioValue] = useState<string>(formik.advance_payment_type ?? "");
  const { currentDirectionLetter } = useAppSelector((state) => state.directionLetters)
  const isDisabled = currentDirectionLetter?.status !== "На согласовании (Подрядчик)" && !!currentDirectionLetter

  const [amount, setAmount] = useState<number | null>(null)

  useEffect(() => {
    if (radioValue !== "") {
      setFieldValue("advance_payment_type", radioValue)
    }
    if (radioValue === "Пропорциональный") {
      setFieldValue("advance_payment_schedule", [])
    }
  },[radioValue])

  useEffect(() => {
    const sum = formik.values.advance_payment_schedule.reduce((acc:any, obj:any) => acc + Number(obj.amount), 0);
    const result = Number(formik.values.amount) - sum;
    setAmount(result)
  },[formik.values.advance_payment_schedule, formik.values.amount])

  useEffect(() => {
    if (formik.values.advance_payment_type) {
      setRadioValue(formik.values.advance_payment_type)
    }
  }, [formik.values.advance_payment_type])


  return (
    <Flex direction="column" gap={2}>
      <BlockTitle>Вид зачета аванса</BlockTitle>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="space-between">
          <RadioGroup size="lg" colorScheme="teal" defaultValue={formik.advance_payment_type ?? ""} value={radioValue} onChange={setRadioValue} isDisabled={isDisabled}>
            <Stack h="40px" spacing={5} direction="row" align="center">
              <Radio value="Пропорциональный" borderColor="#AAB0C1">
                <Text fontSize="16px">Пропорциональный</Text>
              </Radio>
              <Radio
                alignItems="center"
                alignSelf="center"
                value="По графику"
                borderColor="#AAB0C1"
              >
                <Text fontSize="16px">По графику</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        {radioValue === "По графику" &&  <Text textAlign="end" fontWeight={500}>{`Осталось добавить на сумму: ${formatAmount(amount)}`}</Text>}
      </Flex>
      {radioValue === "По графику" && <PaymentFileds setFieldValue={setFieldValue} formik={formik} />}
    </Flex>
  )
}