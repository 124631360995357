import { AuthTitle } from "../components/AuthTitle/AuthTitle";
import { Modal, ModalProps } from "../components/Modal/Modal";
import styles from "../components/Modal/modal.module.scss"

type changeModalProps = Omit<ModalProps, "children"> & {
    children: React.JSX.Element;
    title: string;
};

export function ChangeModal(props: changeModalProps) {
    const { active, onClose, title, children } = props;
    return (
    <Modal active={active} onClose={onClose}>
      <div
        className={styles.center_modal_container}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <AuthTitle title={title} />
        {children}
      </div>
    </Modal>
  );
}
