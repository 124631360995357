import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    Box,
    Flex,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface MultiselectMenuProps {
    documents: any[];
    setCurrentDocument: any
    index?: number | null;
    isInvalid?: boolean
}

export function DrowpdownMenu(props: MultiselectMenuProps) {
    const { documents, setCurrentDocument, index, isInvalid  = false} =
        props;
    const [selectedDocument, setSelectedDocument] = useState<any | null>(null);


    const { isOpen, onToggle, onClose } = useDisclosure();
    const ref = useRef(null);
    useOutsideClick({ ref, handler: onClose });

    const handleDocumentSelect = (document: any) => {
        setSelectedDocument(document);
        onClose();
    };

    useEffect(() => {
        setCurrentDocument(selectedDocument)
    }, [selectedDocument, setCurrentDocument]);

    useEffect(() => {
        if ((index === undefined || index === null) && documents.length > 0) {
            
        } else if (documents.length > 0 && index !== null && index !== undefined) {
            setSelectedDocument(documents[index]);
        }
    }, [documents, index]);


    return (
        <Accordion
            backgroundColor="#FFFFFF"
            color="#5C657E"
            border={!isInvalid ? "1px solid #EBEEF5" : "2px solid #E53E3E"}
            borderRadius="8px"
            position="relative"
            marginLeft="-1px"
            ref={ref}
        >
            <AccordionItem isDisabled={documents.length === 0} border="none">
                <h2>
                    <AccordionButton p={0} cursor="default" onClick={onToggle} >
                        <Flex
                            margin="10px 12px"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box fontWeight={400} as="span" flex="1" textAlign="left">
                                <Text color="#343B4C">{selectedDocument ? `${selectedDocument.name}` : ""}</Text>
                            </Box>
                            {isOpen ? (
                                <ChevronUpIcon boxSize={6} />
                            ) : (
                                <ChevronDownIcon boxSize={6} />
                            )}
                        </Flex>
                    </AccordionButton>
                </h2>
                {isOpen && (
                    <Box
                        position="absolute"
                        top="46px"
                        left="0"
                        width="100%"
                        bg="white"
                        zIndex="10"
                        overflowY="auto"
                        maxHeight="300px"
                        borderRadius="6px"
                        border="1px solid #E3E7EF"
                        padding="4px"
                        sx={{
                            boxShadow: "0px 8px 24px 0px #1F222A0D",
                        }}
                    >
                        <Flex >
                            {documents.length > 0 && (
                                <Flex w="100%" gap="2px" flexDirection="column">
                                    {documents.map((document) => (
                                        <Flex
                                            key={document.guid}
                                            w="100%"
                                            h="39px"
                                            paddingLeft="16px"
                                            paddingRight="16px"
                                            paddingBottom="2px"
                                            borderBottom="1px solid #E3E7EF"
                                            alignItems="center"
                                            cursor="pointer"
                                            _hover={{
                                                cursor: "pointer",
                                                backgroundColor: "#F0F3FA",
                                            }}
                                            onClick={() => handleDocumentSelect(document)}
                                        >
                                            <Text
                                                w="100%"
                                                color={
                                                    selectedDocument?.guid === document.guid
                                                        ? "#2AB6A5"
                                                        : "#343B4C"
                                                }
                                                fontSize="16px"
                                                noOfLines={1}
                                                lineHeight="16px"
                                            >
                                                {`${document.name}`}
                                            </Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                )}
            </AccordionItem>
        </Accordion>
    );
}