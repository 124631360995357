import { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

interface DebouncedInputProps extends Omit<InputProps, "onChange"> {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}

export function DebouncedInput(props: Readonly<DebouncedInputProps>) {
  const { value: initialValue, onChange, debounce = 500, ...rest } = props;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <InputGroup maxW="320px" size="sm">
      <InputLeftElement>
        <SearchIcon />
      </InputLeftElement>
      <Input
        value={value}
        placeholder="Поиск"
        rounded="md"
        borderColor="#E3E7EF"
        onChange={(e) => setValue(e.target.value)}
        {...rest}
      />
    </InputGroup>
  );
}
