import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { RoleAndPermissionEndpoints } from "../endpoints";
import {
  IPermissionsForRoleRequest,
  IPermissionsResponse,
  IProfileRolesResponse,
  IRolesPermissionsResponse,
  IRolesResponse,
} from "./types";

export const getRolesAndPermissions =
  (): AxiosPromise<IRolesPermissionsResponse> => {
    return axiosInstance.get(RoleAndPermissionEndpoints.ROLES_PERMISSIONS);
  };
export const getRole = (): AxiosPromise<IProfileRolesResponse> => {
  return axiosInstance.get(RoleAndPermissionEndpoints.ROLES);
};
export const getPermissions = (): AxiosPromise<IPermissionsResponse> => {
  return axiosInstance.get(RoleAndPermissionEndpoints.PERMISSIONS);
};
export const getRoleList = (): AxiosPromise<IRolesResponse> => {
  return axiosInstance.get(RoleAndPermissionEndpoints.LIST_ROLES);
};
export const getPermissionList = (): AxiosPromise<IPermissionsResponse> => {
  return axiosInstance.get(RoleAndPermissionEndpoints.LIST_PERMISSIONS);
};
export const getPermissionsForRole = (
  params: IPermissionsForRoleRequest
): AxiosPromise<IPermissionsResponse> => {
  return axiosInstance.post(
    RoleAndPermissionEndpoints.PERMISSIONS_FOR_ROLE,
    params
  );
};
