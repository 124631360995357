import classNames from "classnames";
import styles from "./modal-footer.module.scss";

interface ModalFooterProps {
  children: React.ReactNode;
  shadow?: boolean;
}

export function ModalFooter(props: ModalFooterProps) {
  const footerClass = classNames(styles.footer, {
    [styles.shadow]: props.shadow,
  });

  return <div className={footerClass}>{props.children}</div>;
}
