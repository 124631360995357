import { RootState, useAppSelector } from "../../store";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";

export function SingleSettlementsActInformationBlock() {
  const { reconciliationStatement } = useAppSelector(
    (state: RootState) => state.reconciliation
  );
  return (
    <InformationBlock>
      <InformationItem title="Название">
        {reconciliationStatement?.name}
      </InformationItem>
      <InformationItem title="Дата">{reconciliationStatement?.date}</InformationItem>
      <InformationItem title="Организация">
        {reconciliationStatement?.a101_org_name}
      </InformationItem>
      <InformationItem title="Дата начала">{reconciliationStatement?.start_date}</InformationItem>
      <InformationItem title="Дата окончания">{reconciliationStatement?.finish_date}</InformationItem>
      <InformationItem title="Ответственный">
        {reconciliationStatement?.responsible_full_name}
      </InformationItem>
    </InformationBlock>
  );
}
