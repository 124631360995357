import { useState } from "react";
import { PrimaryButton } from "../lib/button/Button";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { useAppDispatch } from "../../store";
import { fetchLzkReport } from "../../store/slices/reports";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { Box } from "@chakra-ui/react";
import { AccessRights } from "../../shared/mock/sidebarContent";

export function ExecutionLzkComponent() {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleSendReport = () => {
    setIsOpenModal(true)
    dispatch(fetchLzkReport())
      .finally(() => {
        setIsOpenModal(false)
      })
  }

  return (
    <>
      <PrimaryButton onClick={handleSendReport} w="165px">
        Сформировать
      </PrimaryButton>
      <Box>
        <OnboardingByPermission permission={AccessRights.ReportLimitCard_RW} />
      </Box>
      <FullScreenWaitingModal openModal={isOpenModal} />
    </>
  );
}
