import { ITab } from "../../components/lib/tabs/Tabs";
import { AccessRights } from "./sidebarContent";

export const reportPageTabs: ITab[] = [
    {
        id: 1,
        href: "execution-contracts",
        label: "Исполнение договоров",
        permission: AccessRights.ReportContract_RW,
    },
    { 
        id: 2, 
        href: "execution-estimates", 
        label: "Исполнение смет",
        permission: AccessRights.ReportEstimate_RW
    },
    {
        id: 3,
        href: "execution-lzk",
        label: "Исполнение по ЛЗК",
        permission: AccessRights.ReportLimitCard_RW
    },
];