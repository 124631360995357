import { useState } from "react";
import { Link } from "react-router-dom";
import { ChangePasswordModal } from "../../Modal/ModalCenter/ChangePasswordModal";
import { SuccessChangePasswordModal } from "../../Modal/ModalCenter/SuccessChangePasswordModal";
import { ReactComponent as ArrowRight } from "../../../images/svg/arrows/arrow-right.svg";
import profileLogo from "../../../images/png/profile.png";
import styles from "./profile-name.module.scss";

interface ProfileNameProps {
  name: string;
}

export function ProfileName(props: ProfileNameProps) {
  const [changePasswordModalActive, setChangePasswordModalActive] =
    useState<boolean>(false);
  const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

  const openModalHandler = (): void => setChangePasswordModalActive(true);
  const onSuccessChangePassword = (): void => {
    setChangePasswordModalActive(false);
    setSuccessModalActive(true);
  };

  return (
    <>
      <div className={styles.card}>
        <p className={styles.card_name}>{props.name}</p>
        <div className={styles.card_link}>
          <Link to={"#"} onClick={openModalHandler}>
            Сменить пароль
          </Link>
          <div>
            <ArrowRight />
          </div>
        </div>
        <img src={profileLogo} alt="Profile logo" />
      </div>

      <ChangePasswordModal
        active={changePasswordModalActive}
        onClose={() => setChangePasswordModalActive(false)}
        onSuccess={onSuccessChangePassword}
      />
      <SuccessChangePasswordModal
        active={successModalActive}
        onClose={() => setSuccessModalActive(false)}
      />
    </>
  );
}
