import { Flex, Input, Text } from "@chakra-ui/react";
import { InformationItemDateInput } from "./InformationItemDateInput";

interface InformationItemFormProps {
  firstLabel?: string;
  firstValue?: string;
  secondLabel?: string;
  secondValue?: string;
  onChange?: (arg:any) => void;
  firstId?: string;
  secondId?: string;
  isDisabled?:boolean
  required?: boolean
}

export function InformationItemForm(props: Readonly<InformationItemFormProps>) {
  const {
    firstLabel = "№",
    firstValue = "218-23",
    secondLabel = "Дата",
    secondValue = "26.10.2023",
    isDisabled = false,
    onChange,
    firstId,
    secondId,
    required = true
  } = props;

  return (
    <Flex
      w="full"
      gap={6}
      position="absolute"
      top="50%"
      transform="translate(0, -50%)"
    >
      <Flex align="center" gap={2} w="full">
        <Text>{firstLabel}</Text>
        <Input
          isDisabled={isDisabled}
          id={firstId}
          name={firstId}
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          value={firstValue}
          onChange={onChange}
          isInvalid={(required && firstValue === "")}
        />
      </Flex>
      <Flex align="center" gap={2} w="full">
        <Text>{secondLabel}</Text>
        <InformationItemDateInput
              isDisabled={isDisabled}
              id={secondId}
              name={secondId}
              value={secondValue}
              onChange={onChange}
              isInvalid={(required && secondValue === "")}
              
            />
      </Flex>
    </Flex>
  );
}
