import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { ProfileEndpoints } from "../endpoints";
import { IChangeEmail, IChangeFio, IProfileResponse } from "./types";

export const getProfile = (): AxiosPromise<IProfileResponse> =>
  axiosInstance.get(ProfileEndpoints.PROFILE);

export const changeProfileFIO = (body: IChangeFio): AxiosPromise<IProfileResponse> => 
  axiosInstance.post(ProfileEndpoints.CHANGE_FIO, body)

export const changeProfileEmail = (body: IChangeEmail): AxiosPromise<IProfileResponse> => 
  axiosInstance.post(ProfileEndpoints.CHANGE_EMAIL, body)
