import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { Files } from "../endpoints";
import { GetSingleFileRequest } from "./types";

export const getFile = (params: GetSingleFileRequest): AxiosPromise<Blob> => {
  const { id, entityType, entityGuid } = params;
  const queryParams = new URLSearchParams();
  if (entityType) {
    queryParams.append("entity_type", entityType);
  }
  if (entityGuid) {
    queryParams.append("entity_guid", entityGuid);
  }

  return axiosInstance.get(Files.FILE + `/${id}?${queryParams.toString()}`, {
    responseType: "blob",
  });
};


export const getRequestedFile = (id: string): AxiosPromise<Blob> =>
  axiosInstance.get(Files.REQUESTED_FILE + `/${id}`, {
    responseType: "blob",
  });

export const attachFiles = (url: string, files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files[]', file);
    });

    return axiosInstance.post(
        url,
        formData,
        {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        }
    );
};