import { CircularProgress, Flex, Text } from "@chakra-ui/react";
import { SingleOrderLetterInformationBlock } from "./SingleOrderLetterInformationBlock";
import { RootState, useAppSelector } from "../../../store";

export function DocumentPageBlock({ formik, setFieldValue, setDisableApprove }: {
  formik: any;
  setFieldValue: any;
  setDisableApprove: (arg: boolean) => void
}) {
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);

  return (
    <Flex gap={4} direction="column">
      <Flex
        bg="white"
        px="24px"
        py="10px"
        border="1px solid #E3E7EF"
        rounded="lg"
        align="center"
        gap={6}
      >
        <CircularProgress value={currentDirectionLetter?.progress.back_office} color="#2AB6A5" trackColor="#E3E7EF" />
        <Text>{`Бэк-офис - ${currentDirectionLetter?.progress.back_office}%`}</Text>
      </Flex>
      <SingleOrderLetterInformationBlock formik={formik} setFieldValue={setFieldValue} setDisableApprove={setDisableApprove} />
    </Flex>
  );
}
