import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSingleUserScreen, getAllUserScreens, setOnbordingCompeleted } from "../../api/onboarding";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IOnboardingScreen,
  IUserScreensResponse,
  OnboardingCompleteResponse,
} from "../../api/onboarding/types";
// import { IOnboardingPermission } from "../../api/auth/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";

export interface OnboardingState extends ICommonInitialState {
  screens: IOnboardingScreen[];
  currentSectionScreens: IOnboardingScreen[];
  isOnboardingСompleted: boolean;
  // onboardingPermissions: Record<string, IOnboardingPermission> | null;
}

const initialState: OnboardingState = {
  screens: [],
  currentSectionScreens: [],
  isOnboardingСompleted: true,
  // onboardingPermissions: null,
  isLoading: false,
  error: null,
};

export const fetchUserScreens = createAsyncThunkInstance<
  IUserScreensResponse,
  undefined
>("onboarding/fetchUserScreens", getAllUserScreens);

export const fetchSingleSectionScreens = createAsyncThunkInstance<
  IUserScreensResponse,
  number
>("onboarding/fetchSingleUserScreen", getSingleUserScreen);

export const changeOnbordingCompeleted = createAsyncThunkInstance<
  OnboardingCompleteResponse,
  undefined
>("onboarding/setOnboarding", setOnbordingCompeleted);

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setIsOnboardingСompleted(state, action) {
      state.isOnboardingСompleted = action.payload;
    },
    // setOnboardingPermissions(state, action) {
    //   state.onboardingPermissions = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserScreens.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserScreens.fulfilled, (state, action) => {
        state.isLoading = false;
        state.screens = action.payload.onboarding_screens;
      })

      .addCase(fetchSingleSectionScreens.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleSectionScreens.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentSectionScreens = action.payload.onboarding_screens;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const onboardingReducer = onboardingSlice.reducer;
export const { setIsOnboardingСompleted } =
  onboardingSlice.actions;
