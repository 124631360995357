import { useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Center, IconButton } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Table } from "../lib/table/Table";
import { BeatLoader } from "../lib/beatLoader/BeatLoader";
import { RootState, useAppSelector } from "../../store";
import { IFile } from "../../api/files/types";

const columnHelper = createColumnHelper<IFile>();


const DownloadCell = (info: CellContext<IFile, string>) => {

  const handleButtonClick = () => {
    window.open(info.row.original.url)
  };

  return (
    <IconButton
      bg="none"
      color="#2AB6A5"
      aria-label="download"
      icon={<DownloadIcon />}
      spinner={<BeatLoader />}
      _disabled={{ bg: "none" }}
      onClick={handleButtonClick}
    />
  );
};

export function DocumentListTable() {

  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const files = currentContract?.working_documents ? currentContract?.working_documents : [];

  const columns = useMemo<Array<ColumnDef<IFile, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название документа",
      }),
      {
        header: "Тип",
        id: "type",
        cell: "АР",
      },
      {
        header: "Файлы",
        id: "download",
        cell: DownloadCell,
      },
    ],
    []
  );

  return (
    <Table dblClickDisabled={true} data={files} columns={columns} />
  );
}
