import { Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, useDisclosure } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import {CompensationActModal} from "../../components/CompensationActModal/CompensationActModal";
import {
  InformationItem,
  InformationItemInputGroup,
} from "../InformationBlock/InformationItem";
import { InformationItemDateInput } from "../InformationBlock/InformationItemDateInput";
import { AppDispatch, RootState, useAppSelector } from "../../store";
import { Input } from "../lib/input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import { ICompensationActProps, ISavingsCompensationActRequest } from "../../api/savingsCompensationActs/types";
import { useDispatch } from "react-redux";
import { addSingleSavingsCompensationAct } from "../../store/slices/savingsCompensationActs";
import { fetchSingleContract } from "../../store/slices/contracts";
import { numberWithDate } from "../../helpers/numberWithDate";

export const CreateCompensationActComponent: React.FC<ICompensationActProps> = (props: ICompensationActProps) => {
  const {onChangeData, changeButtonInput} = props
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts 
  );
  const { currCompAct } = useAppSelector(
    (state: RootState) => state.savingsCompensationActsReducer
  );
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (currentContract === null) {
      navigate(location.pathname.replace('/create', ''))
    }
  })

  const onSubmit = (values: ISavingsCompensationActRequest) => dispatch(addSingleSavingsCompensationAct(values));

  const formik = useFormik({
    initialValues: {
      contract_id: 0,
      period_from: '',
      period_to: '',
      amount: 0,
      comment: ''
    },

    onSubmit,
  });
  const { setFieldValue } = formik;

  const {period_from, period_to, amount} = formik.values

  useEffect(() => {
    onChangeData(period_from, period_to, amount)
    if (period_from === '' || period_to === '' || (Date.parse(period_from) >= Date.parse(period_to)) 
      || amount === 0 || typeof amount === "string" ) {
        changeButtonInput && changeButtonInput(true);
    } else {
      changeButtonInput && changeButtonInput(false)
    }
  }, [period_from, period_to, amount])

  useEffect(() => {
    if (currCompAct && currCompAct.canEdit) {
      (async () => {
        await dispatch(fetchSingleContract(currCompAct?.contract_id))
        await setFieldValue("period_from", currCompAct.start_date ?? "")
        await setFieldValue("period_to", currCompAct.end_date ?? "")
        await setFieldValue("amount", currCompAct.amount ?? "")
        await setFieldValue("comment", currCompAct.comment ?? "")
      })()

    }
  }, [currCompAct, dispatch, setFieldValue])

  return (
    <>
      <InformationBlock>
        <InformationItem title="Договор">
          <Accordion color="#5C657E" border="1px solid #E3E7EF" borderRadius="8px">
            <AccordionItem isDisabled={currCompAct?.canEdit}>
              <h2>
                <AccordionButton onClick={onOpen}>
                  <Box as="span" flex='1' textAlign='left'>
                    {currentContract?.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
             </h2>
            </AccordionItem>
          </Accordion>
        </InformationItem>
        <InformationItem title="Номер">{currCompAct?.canEdit ? numberWithDate(currCompAct?.number, currCompAct?.date, true) : ""}</InformationItem>
        <InformationItem title="Организация">
        {currentContract?.a101_org_name}
        </InformationItem>
        <InformationItem title="Контрагент">{currentContract?.organization.org_name}</InformationItem>
        <InformationItem title="Проект">{currentContract?.project.name}</InformationItem>
        <InformationItem title="ССОМ">
        </InformationItem>
        <InformationItem title="Период" tooltipKey="saving-compensation-act_create-n-view_date">
          <InformationItemInputGroup>
            <InformationItemDateInput
              placeholder="C"
              name="period_from"
              value={formik.values.period_from}
              onChange={formik.handleChange}
            />
            <InformationItemDateInput
              placeholder="По"
              name="period_to"
              value={formik.values.period_to}
              onChange={formik.handleChange}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Сумма компенсации">
          <Input
            type="number"
            defaultValue={0}
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
          />
        </InformationItem>
        <InformationItem title="Объекты строительства">
          {currentContract?.short_description_construction_object}
        </InformationItem>
        <InformationItem title="Исполнитель">
        {currentContract?.executor}
        </InformationItem>
        <InformationItem title="Ответственный">
        </InformationItem>
      </InformationBlock>
      <CompensationActModal isInsideCreate={true} isOpen={isOpen} onClose={onClose} />
    </>
  );
}
