import { ITab, LinkTabs } from "../../components/lib/tabs/Tabs";
import { RootState, useAppSelector } from "../../store";
import { AccessRights } from "./sidebarContent";

export const DocumentsInProgressPageTabs = () => {
  const { counters } = useAppSelector((state: RootState) => state.mainInfo);

  const documentsInProgressPageTabs: ITab[] = [
    {
      id: 1,
      href: "completed-work-acts",
      label: "Акты выполненных работ",
      notice: counters?.counter?.work_completed_acts ?? 0,
      permission: [AccessRights.WorkCompletedAct_RO, AccessRights.WorkCompletedAct_RW],
    },
    {
      id: 2,
      href: "invoices",
      label: "Счёт-фактуры",
      permission: AccessRights.Invoice_RW,
    },
    { id: 3,
      href: "payment",
      label: "Оплата",
      permission: AccessRights.Payment_RW,
      notice: counters?.counter?.payments ?? 0
    },
  ];

  return (
    <LinkTabs tabs={documentsInProgressPageTabs} />
  )
};
