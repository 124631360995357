import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Box, Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { useNavigate } from "react-router-dom";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { ISingleRemarkConstruction } from "../../api/remarks/types";
import { RootState, useAppSelector } from "../../store";
import dayjs from "dayjs";
import { getCollapsedData } from "../../helpers/getCollapsedData";


const columnHelper = createColumnHelper<ISingleRemarkConstruction>();

export function RemarksTable({
  isCollapsedList,
}: {
  isCollapsedList: boolean;
}) {
  const navigate = useNavigate();

  const { currentContractConstructionRemarks } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [resultData, setResultData] = useState<ISingleRemarkConstruction[] | undefined>([]);
  const buildObjectCell = useCallback(
    ({ row: { original } }: CellContext<ISingleRemarkConstruction, string>) => (
      <>
        <Text noOfLines={1}>{original.construction_object}</Text>
        <Box lineHeight="21px" fontSize={14} color="#8089a2" noOfLines={1}>
          {original.executor}
        </Box>
      </>
    ),
    []
  );

  function navigateToPage(id: number) {
    navigate(`/remarks/construction/${id}`);
  }

  useEffect(() => {
    setResultData(getCollapsedData(currentContractConstructionRemarks, isCollapsedList));
  }, [isCollapsedList, currentContractConstructionRemarks]);

  const columns = useMemo<Array<ColumnDef<ISingleRemarkConstruction, string>>>(
    () => [
      columnHelper.accessor("number", {
        header: "Номер",
      }),
      columnHelper.accessor("date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY")
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue(), "#469DDD40"),
      }),
      {
        id: "buildObject",
        header: "Объект строительства",
        enableHiding: false,
        cell: buildObjectCell,
      },
    ],
    [buildObjectCell]
  );

  return (
    <Table
      data={resultData ?? []}
      dblClickHandler={navigateToPage}
      columns={columns}
    />
  );
}
