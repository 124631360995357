import { useState } from "react";
import EyePassword from "../../../images/svg/eye-password/eye-password.svg";
import { Input, InputProps } from "./Input";
import styles from "./input.module.scss";

export function PasswordInput(props: InputProps) {
  const {
    name,
    value,
    onChange,
    onBlur,
    label,
    placeholder = "Введите пароль",
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);
  const handleVisibleClick = () => setVisible(!visible);

  return (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.password}>
        <Input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type={visible ? "text" : "password"}
          {...rest}
        />
        <div className={styles.image_wrapper} onClick={handleVisibleClick}>
          <img src={EyePassword} alt="eye" />
        </div>
      </div>
    </div>
  );
}
