import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CustomLink } from "../../customLink/CustomLink";
import styles from "./drop-file-input.module.scss";

interface DropFileInputProps {
  text: string;
  onChange: (files: File[]) => void;
  id?: string;
  label?: string;
  name?: string;
  value?: File[];
  isLimitedFormat?: boolean;
  linkText?: string;
  onLinkClick?: (e: SyntheticEvent) => void;
  isDisabled?: boolean;
  handleDeleteFile?: (files: File) => void;
  deleteFile?: File;
  clearDeleteFiles?: () => void;
  isSend?: boolean;
  availableXls?: boolean
}

export function DropFileInputInSingleComponents(
  props: Readonly<DropFileInputProps>
) {
  const {
    text,
    onChange,
    id = "",
    label = "",
    name,
    isLimitedFormat = true,
    isDisabled = false,
    deleteFile,
    clearDeleteFiles,
    isSend,
    availableXls = false
  } = props;
  const [fileList, setFileList] = useState<File[]>([]);

  const onFileChange = useCallback(
    (files: File[]) => {
      onChange(files);
    },
    [onChange]
  );

  const onFileDrop = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const newFiles = target.files as FileList;

    if (newFiles) {
      const updatedList = [...fileList];

      for (let i = 0; i < newFiles.length; i++) {
        updatedList.push(newFiles[i]);
      }

      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  useEffect(() => {
    if (deleteFile) {
      const updatedList = [...fileList];
      updatedList.splice(fileList.indexOf(deleteFile), 1);
      setFileList(updatedList);
      onFileChange(updatedList);
      if (clearDeleteFiles) {
        clearDeleteFiles();
      }
    }
  }, [deleteFile, onFileChange, fileList, clearDeleteFiles]);

  useEffect(() => {
    isSend && setFileList([]);
  }, [isSend]);

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.file_input}>
        <div className={styles.file_input_label}>
          <div>
            {text} или нажмите <CustomLink content={"загрузить"} />
          </div>
          {isLimitedFormat &&  !availableXls && <span>Формат: .pdf, .zip, до 70 Мб</span>}
          {isLimitedFormat &&  availableXls && <span>Формат: .pdf, .zip, .xlsx до 70 Мб</span>}
        </div>
        <input
          id={id}
          type="file"
          value=""
          onChange={onFileDrop}
          name={name}
          multiple
          accept={availableXls ? ".pdf,.zip,.xlsx" : ".pdf,.zip"}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}
