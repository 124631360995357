import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { IAccount, ISupplier, ISupplierResponse } from "../../api/supplier/types";

interface ISupplierInitialState extends ICommonInitialState {
  currentSupplier: ISupplier | null;
  selectedAccount : IAccount | null;
}

const initialState: ISupplierInitialState = {
  currentSupplier: null,
  selectedAccount: null,
  isLoading: false,
  error: null,
};

export const fetchSupplier = createAsyncThunkInstance<
  ISupplierResponse,
  string
>("supplier/fetchSupplier", api.supplier.getSupplier);

export const fetchSupplierByGuid = createAsyncThunkInstance<
  ISupplierResponse,
  string
>("supplier/fetchSupplierByGuid", api.supplier.getSupplierByGuid);

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    selectAccount(state, action) {
      state.selectedAccount = action.payload;
    },
    resetCurrentSupplier(state) {
      state.currentSupplier = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentSupplier = action.payload.data;
      })
      .addCase(fetchSupplierByGuid.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSupplierByGuid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentSupplier = action.payload.data;
      });
  },
});

export const supplierReducer = supplierSlice.reducer;
export const { selectAccount, resetCurrentSupplier } = supplierSlice.actions;
