import {
    Box,
    Flex,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { ReactComponent as Filter } from "../../images/svg/documents/filter.svg";
import { useEffect, useState } from "react";
import { Title } from "../lib/title/Title";
import { PrimaryButton } from "../lib/button/Button";
import CustomDatePicker from "../lib/input/CustomDatePicker";
import { Column, Table } from "@tanstack/react-table";

interface DateRange {
    startDate: Date | null;
    endDate: Date | null;
}

export const FilterDateRange = (column: Column<any, unknown>, table: Table<any>, columnName: string) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: null,
        endDate: null,
    });
    const [isFiltered, setIsFiltered] = useState<boolean>(false);

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            column.setFilterValue([dateRange.startDate, dateRange.endDate]);
            setIsFiltered(true);
        } else {
            column.setFilterValue(undefined);
            setIsFiltered(false);
        }
    }, [dateRange, column])

    return (
        <>
            <Flex justify="space-between" w="full" align="center">
                <Text>{columnName}</Text>
                <Box position="relative">
                    <Filter onClick={() => setIsOpen(true)} width={16} height={16} cursor="pointer" />
                    {isFiltered && (
                        <Box
                            w={2}
                            h={2}
                            borderRadius="50%"
                            border="2px solid white"
                            bg="#E8363D"
                            position="absolute"
                            top={-1}
                            right={-1}
                        />
                    )}
                </Box>
            </Flex>
            <ChooseDateModal setDateRange={setDateRange} isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};


interface ChooseDateModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void
    setDateRange: (newDateRange: DateRange) => void
}


const ChooseDateModal = ({ isOpen, setIsOpen, setDateRange
}: ChooseDateModalProps) => {

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleApplyDateRange = () => {
        const today = new Date();
        const newDateRange = startDate?.getDate() !== today.getDate() && endDate?.getDate() !== today.getDate() ?
            {
                startDate: startDate,
                endDate: endDate,
            }

            :
            {
                startDate: null,
                endDate: null,
            };
        setDateRange(newDateRange)
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={() => { setIsOpen(false) }}>
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent maxW="351px" gap="16px" p="24px">
                <Title textAlign="center" color="#343B4C" fontSize="20px">Фильтр по дате платежа</Title>
                <Flex justifyContent="space-between" color="#8089A2" alignItems="center">
                    <Flex cursor="pointer" alignItems="center">
                        <CustomDatePicker onChange={setStartDate} />
                    </Flex>
                    —
                    <Flex cursor="pointer" alignItems="center">
                        <CustomDatePicker onChange={setEndDate} />
                    </Flex>
                </Flex>
                <PrimaryButton
                    onClick={() => {
                        handleApplyDateRange();
                        setIsOpen(false)
                    }}>Применить</PrimaryButton>
            </ModalContent>
        </Modal>
    )
}