import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import {
  IFile,
  IFiles,
  IFilesDeleteProps,
  IFilesTableProps,
} from "../../api/files/types";
import { Table } from "../lib/table/Table";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as files } from "../../images/svg/file/file-preview.svg";
import { CloseIcon } from "@chakra-ui/icons";
import { fetchSingleFile } from "../../store/slices/files";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { Loader } from "../Loader/Loader";
import dayjs from "dayjs";

const columnHelper = createColumnHelper<IFiles>();

const StatusCell = ({ row: { original } }: CellContext<IFiles, string>) => {
  let status = "";
  if (original.created_at === undefined) {
    status = "Загружен, не отправлен";
  } else if (original.is_lkp) {
    status = "Отправлен, принят";
  } else {
    status = "Получен из 1С";
  }
  const isDownload = original.created_at === undefined;
  return (
    <Flex color={isDownload ? "#8089A2" : "#000"} align="center" gap="8px">
      <Text>{status}</Text>
      {isDownload && <CloseIcon />}
    </Flex>
  );
};

const TitleCell = ({ row: { original } }: CellContext<IFiles, string>) => {
  const isDownload = original.created_at === undefined;
  return (
    <Flex cursor="pointer" align="center" gap="8px">
      <Icon as={files} cursor="pointer" />
      <Text noOfLines={1} color={isDownload ? "#8089A2" : "#000"}>{original.name}</Text>
    </Flex>
  );
};

const CreatedAtCell = ({ row: { original } }: CellContext<IFiles, string>) => {
  const formattedDate = dayjs(original.created_at).format("DD.MM.YYYY")
  return (
    <Text>{formattedDate}</Text>
  );
};

export const FilesTable: React.FC<IFilesTableProps> = (
  props: IFilesTableProps
) => {
  const { filesList, handleDelete, receivedFiles } = props;
  const [downloadList, setDownloadList] = useState<File[]>([]);
  const [acceptFiles, setAcceptFiles] = useState<IFile[]>([]);
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);
  const resultList = [...acceptFiles, ...downloadList];
  const {currentAdditionalRequest} = useAppSelector((state: RootState) => state.additionalRequests);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setDownloadList(filesList);
  }, [filesList]);

  useEffect(() => {
    if (receivedFiles) {
      setAcceptFiles(receivedFiles);
    }
  }, [receivedFiles, acceptFiles]);


  const handleClickFile = (file: any) => {
    if (file.original.created_at) {
      handleUploadFile(file);
    } else {
      handleDeleteFile(file);
    }
  };

  const handleDeleteFile = (file: IFilesDeleteProps) => {
    if (file.original.lastModified) {
      handleDelete(file.original);
    }
  };

  const handleUploadFile = (file: any) => {
    if (file.original.guid) {
      if (currentAdditionalRequest) {
        setIsSpinnerRunning(true);
        dispatch(fetchSingleFile({id:file.original.guid, entityType: "additional-request", entityGuid: currentAdditionalRequest.guid}))
        .then((res) => {
          if (res.payload instanceof Blob && file.original.name) {
            const url = window.URL.createObjectURL(
              new Blob([res.payload], { type: "application/octet-stream" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.original.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .finally(() => {
          setIsSpinnerRunning(false);
        });
      } else {
        setIsSpinnerRunning(true);
        dispatch(fetchSingleFile({id:file.original.guid}))
        .then((res) => {
          if (res.payload instanceof Blob && file.original.name) {
            const url = window.URL.createObjectURL(
              new Blob([res.payload], { type: "application/octet-stream" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.original.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .finally(() => {
          setIsSpinnerRunning(false);
        });
      }
    }
  };

  const columns = useMemo<Array<ColumnDef<IFiles, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Имя файла",
        cell: TitleCell,
      }),
      columnHelper.accessor("created_at", {
        header: "Дата создания/загрузки",
        cell: CreatedAtCell
      }),
      columnHelper.accessor("status", {
        header: "Статус",
        cell: StatusCell,
      }),
    ],
    []
  );
  return (
    <>
      <Table
        rowClick={handleClickFile}
        dblClickDisabled={true}
        data={resultList}
        columns={columns}
      />
      <Loader isLoading={isSpinnerRunning} />
    </>
  );
};
