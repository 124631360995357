import React from "react";
import {
  Divider,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
} from "@chakra-ui/react";
import {useNavigate } from "react-router-dom";

interface ChakraMenuListProps extends MenuListProps {
  list: {title: string, onClick?: () => void, isDisabled?: boolean}[];
}

export function ChakraMenuList(props: Readonly<ChakraMenuListProps>) {
  return (
    <MenuList border="1px solid #E3E7EF" p={1} zIndex={2}>
      {props.list.map((item, index) => (
        <React.Fragment key={item.title}>
          <ChakraMenuItem isDisabled={item.isDisabled} onClick={item.onClick}>{item.title}</ChakraMenuItem>
          {index !== props.list.length - 1 && (
            <Divider my="2px" borderColor="#E3E7EF" />
          )}
        </React.Fragment>
      ))}
    </MenuList>
  );
}

export function ChakraMenuItem(props: MenuItemProps) {
  const { children, ...rest } = props;
  const navigate = useNavigate();
  
  const handleMaterialsClick = (value: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (value.currentTarget.innerText === "Акт об экономии") {
      navigate("/purchase/compensation-act/create");
    };
  };

  return (
    <MenuItem
      fontWeight={400}
      py={2.5}
      px={3}
      h="fit-content"
      display="inline-block"
      lineHeight="20px"
      onClick={handleMaterialsClick}
      {...rest}
    >
      {children}
    </MenuItem>
  );
}
