import {CellContext} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
import {Link, Text} from "@chakra-ui/react";
import {checkPermission} from "../../helpers/checkPermission";
import {RootState, useAppSelector} from "../../store";
import { AccessRights } from "../../shared/mock/sidebarContent";

export function ContractCell<T>(info: CellContext<T, string>, id: string | number, maxWidth?: string) {
  const navigate = useNavigate();
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  return checkPermission([AccessRights.Contract_RO, AccessRights.Contract_RW], permissions) ? (
    <Link maxW={maxWidth} color="#2AB6A5" noOfLines={2} onClick={() => navigate(`/my-documents/contracts/${id}`)}>
      {info.renderValue()}
    </Link>
  ) : (
    <Text maxW={maxWidth} noOfLines={2}>
      {info.renderValue()}
    </Text>
  );
}
