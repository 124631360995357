import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Input,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface MultiselectMenuProps {
  documents: any[];
  inputPlaceholder: string;
  returnResult: (arg: any) => void;
  selectAllButtonText: string;
}

export function ReportsDropdownList(props: MultiselectMenuProps) {
  const { documents, inputPlaceholder, returnResult, selectAllButtonText } =
    props;
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [resultDocuments, setResultDocuments] = useState<any | any[]>();
  const [searchQuery, setSearchQuery] = useState("");
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref = useRef(null);
  useOutsideClick({ ref, handler: onClose });

  const filteredDocuments = documents.filter((document: any) =>
    document.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDocumentSelect = (document: any) => {
    setSelectedDocument(document);
    onClose();
  };

  useEffect(() => {
    if (selectedDocument === null) {
      setResultDocuments(documents);
    } else {
      setResultDocuments(selectedDocument);
    }
  }, [selectedDocument, documents]);

  useEffect(() => {
    returnResult(selectedDocument);
  }, [selectedDocument, returnResult]);

  return (
    <Accordion
      w="432px"
      backgroundColor="#FFFFFF"
      color="#5C657E"
      border="1px solid #EBEEF5"
      borderRadius="8px"
      position="relative"
      marginLeft="-1px"
      ref={ref}
    >
      <AccordionItem>
        <h2>
          <AccordionButton p={0} cursor="default" onClick={onToggle}>
            <Flex
              margin="10px 12px"
              w="408px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box fontWeight={400} as="span" flex="1" textAlign="left">
                {selectedDocument ? (
                  <Text>{selectedDocument.name}</Text>
                ) : (
                  selectAllButtonText
                )}
              </Box>
              {isOpen ? (
                <ChevronUpIcon boxSize={6} />
              ) : (
                <ChevronDownIcon boxSize={6} />
              )}
            </Flex>
          </AccordionButton>
        </h2>
        {isOpen && (
          <Box
            position="absolute"
            top="46px"
            left="0"
            width="100%"
            bg="white"
            zIndex="10"
            overflowY="auto"
            maxHeight="300px"
            borderRadius="6px"
          >
            <Box
              w="418px"
              position="sticky"
              top="0"
              bg="white"
              borderRight="1px solid #E3E7EF"
              padding="8px 16px 8px 16px"
            >
              <Input
                borderColor="#E3E7EF"
                w="100%"
                placeholder={inputPlaceholder}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <Flex>
              {filteredDocuments.length > 0 && (
                <Box w="100%">
                  <Flex
                    w="418px"
                    h="40px"
                    paddingLeft="16px"
                    alignItems="center"
                    cursor="pointer"
                    borderRight="1px solid #E3E7EF"
                    _hover={{
                      cursor: "pointer",
                      backgroundColor: "#F0F3FA",
                    }}
                    onClick={() => handleDocumentSelect(null)}
                  >
                    <Text w="100%">{selectAllButtonText}</Text>
                  </Flex>
                  {filteredDocuments.map((document) => (
                    <Flex
                      key={document.id}
                      w="418px"
                      h="40px"
                      paddingLeft="16px"
                      paddingRight="16px"
                      alignItems="center"
                      cursor="pointer"
                      borderRight="1px solid #E3E7EF"
                      _hover={{
                        cursor: "pointer",
                        backgroundColor: "#F0F3FA",
                      }}
                      onClick={() => handleDocumentSelect(document)}
                    >
                      <Text
                        w="100%"
                        color={
                          selectedDocument?.id === document.id
                            ? "#2AB6A5"
                            : "inherit"
                        }
                        noOfLines={1}
                      >
                        {document.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              )}
            </Flex>
          </Box>
        )}
      </AccordionItem>
    </Accordion>
  );
}
