import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { EstimatesEndpoints } from "../endpoints";
import { IEstimatesResponse, ISingleEstimateResponse, IVerifyWorkDetailsResponse, IVerifyWorksRequest } from "./types";
import { ICreateWorkCompletedActDetail, IWorkCompletedActDetail } from "../workCompletedActs/types";

export const getEstimates = (id: number): AxiosPromise<IEstimatesResponse> =>
  axiosInstance.get(EstimatesEndpoints.ESTIMATES + `/${id}` + EstimatesEndpoints.ESTIMATES_AND_SPRECIFICATIONS);

export const getSingleEstimate = (
  id: number
): AxiosPromise<ISingleEstimateResponse> =>
  axiosInstance.get(EstimatesEndpoints.ESTIMATE_DETAILS + `/${id}`);

export const getSingleEstimateFromAct = (
  id: string
): AxiosPromise<ISingleEstimateResponse> =>
  axiosInstance.post(EstimatesEndpoints.SINGLE_ESTIMATE + `/from-act/${id}`);

export const exportEstimate = (
  id: number,
  params: ICreateWorkCompletedActDetail[] | IWorkCompletedActDetail[] | []
): AxiosPromise<Blob> =>
  axiosInstance.post(
    EstimatesEndpoints.SINGLE_ESTIMATE + `/${id}/export`,
    params,
    { responseType: "blob" }
  );

export const importEstimate = (id: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosInstance.post(
    EstimatesEndpoints.SINGLE_ESTIMATE + `/${id}/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

  export const verifyWorks = (
    params: IVerifyWorksRequest
  ): AxiosPromise<IVerifyWorkDetailsResponse> => {
    return axiosInstance.post(
      EstimatesEndpoints.SINGLE_ESTIMATE + '/verify-works',
      params
    );
  };