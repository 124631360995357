import { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchProfile } from "../../store/slices/profile";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ProfileCEOContainer } from "../../components/ProfileComponents/ProfileCEOContainer";
import { ProfileEmployeeContainer } from "../../components/ProfileComponents/ProfileEmployeeContainer ";

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(
    (state: RootState) => state.profile.profileData.profile
  );

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  return (
    <MainLayout>
      {profile &&
        (profile.is_ceo ? (
          <ProfileCEOContainer />
        ) : (
          <ProfileEmployeeContainer />
        ))}
    </MainLayout>
  );
};
