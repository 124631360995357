import { useEffect, useState } from 'react';
import { ReactComponent as Calendar } from "../../../images/svg/calendar/calendar.svg";
import styles from "./date-picker.module.scss";

interface CustomDatePickerProps {
    onChange: (date: Date) => void
}

const CustomDatePicker = ({ onChange }: CustomDatePickerProps) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [isChange, setIsChange] = useState(false)

    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const month = parseInt(event.target.value);
        setSelectedDate(new Date(selectedDate.getFullYear(), month, selectedDate.getDate()));
    };

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = parseInt(event.target.value);
        setSelectedDate(new Date(year, selectedDate.getMonth(), selectedDate.getDate()));
    };

    const handleDayClick = (element: { day: number; month: number }) => {

        const year = selectedDate.getFullYear();

        const newDate = new Date(year, element.month, element.day);

        setSelectedDate(newDate);

        setShowCalendar(false);

        setIsChange(true)
    };

    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendar = () => {
        const currentYear = selectedDate.getFullYear();
        const currentMonth = selectedDate.getMonth();

        const daysInMonth = getDaysInMonth(currentYear, currentMonth);

        let firstDay = new Date(currentYear, currentMonth, 1).getDay() - 1;
        if (firstDay < 0) {
            firstDay += 7;
        }

        const previousMonthDays = getDaysInMonth(
            currentMonth === 0 ? currentYear - 1 : currentYear,
            currentMonth === 0 ? 11 : currentMonth - 1
        );

        const calendar = [];
        let day = 1;
        let currentWeek = [];

        let previousMonthDay = previousMonthDays - firstDay + 1;
        for (let i = 0; i < firstDay; i++) {
            currentWeek.push({ day: previousMonthDay, month: currentMonth - 1, isCurrentMonth: false });
            previousMonthDay++;
        }

        while (day <= daysInMonth) {
            currentWeek.push({ day: day, month: currentMonth, isCurrentMonth: true });
            day++;

            if (currentWeek.length === 7) {
                calendar.push(currentWeek);
                currentWeek = [];
            }
        }

        if (currentWeek.length > 0 && day > daysInMonth) {
            let nextMonthDay = 1;
            while (currentWeek.length < 7) {
                currentWeek.push({ day: nextMonthDay, month: currentMonth + 1, isCurrentMonth: false });
                nextMonthDay++;
            }
            calendar.push(currentWeek);
        }

        return calendar;
    };

    const formattedDate = `${selectedDate.getDate() < 10 ? `0${selectedDate.getDate()}` : selectedDate.getDate()
        }.${selectedDate.getMonth() + 1 < 10 ? `0${selectedDate.getMonth() + 1}` : selectedDate.getMonth() + 1
        }.${selectedDate.getFullYear()}`;

    useEffect(() => {
        onChange(selectedDate)
    }, [selectedDate, onChange])

    return (
        <div className={styles.datePicker}>
            <div className={styles.datePicker__display} onClick={() => setShowCalendar(!showCalendar)}>
                <Calendar />
                {!isChange ? "Дата начала" : formattedDate}
            </div>
            {showCalendar && (
                <div className={styles.datePicker__calendar}>
                    <div className={styles.datePicker__calendarHeader}>
                        <select className={styles.datePicker__calendarHeader_select} value={selectedDate.getMonth()} onChange={handleMonthChange}>
                            {months.map((month, index) => (
                                <option key={index} value={index}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        <select className={styles.datePicker__calendarHeader_select} value={selectedDate.getFullYear()} onChange={handleYearChange}>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.datePicker__calendarDays}>
                        <div className={styles.datePicker__calendarWeek}>
                            <div className={styles.datePicker__calendarDay_name}>Пн</div>
                            <div className={styles.datePicker__calendarDay_name}>Вт</div>
                            <div className={styles.datePicker__calendarDay_name}>Ср</div>
                            <div className={styles.datePicker__calendarDay_name}>Чт</div>
                            <div className={styles.datePicker__calendarDay_name}>Пт</div>
                            <div className={styles.datePicker__calendarDay_name}>Сб</div>
                            <div className={styles.datePicker__calendarDay_name}>Вс</div>
                        </div>
                        {generateCalendar().map((week, index) => (
                            <div className={styles.datePicker__calendarWeek} key={index}>
                                {week.map((element) => (
                                    <div
                                        key={element.day}
                                        className={
                                            `${styles.datePicker__calendarDay} ${element.isCurrentMonth ? styles.datePicker__calendarDay_currentMonth : ""} ${element.day === selectedDate.getDate() && element.month === selectedDate.getMonth()
                                                ? styles.datePicker__calendarDay_selected
                                                : ""
                                            }`
                                        }
                                        onClick={() => handleDayClick(element)}

                                    >
                                        {element.day ? element.day : ""}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomDatePicker;