import { Flex, Text } from "@chakra-ui/react"
import { MaterialsTable } from "../MaterialsTable"
import { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../../../store";
import { formatAmount } from "../../../helpers/formatAmount";

interface MaterialTableBlockProps {
    setFieldValue: any
}

export const MaterialTableBlock = ({setFieldValue}: MaterialTableBlockProps) => {
    const { currentSupplierMaterials } = useAppSelector(
        (state: RootState) => state.limitFenceCards
    );
    const [total, setTotal] = useState<number>(0)

    useEffect(() => {
        const calculateTotal = (details: any) => {
            let total = 0;
            details.forEach((detail: any) => {
                const quantity = Number(detail.amount) || 0;
                const price = Number(detail.tender_price) || 0;
                total += quantity * price;
            });
            return total;
        };
        const totalSum = calculateTotal(currentSupplierMaterials);
        const fixedTotalSum = totalSum.toFixed(2);
        setFieldValue("amount",fixedTotalSum)
        setTotal(Number(fixedTotalSum));
    }, [currentSupplierMaterials]);
    return (
        <Flex gap="8px" flexDirection="column">
            <Text fontWeight={500} alignSelf="end">Сумма: {formatAmount(total)}</Text>
            <MaterialsTable isRp={true} isCreate={true} />
        </Flex>
    )
}