import {ChakraModalProps} from "../../shared/types/modalProps";
import {useNavigate} from "react-router-dom";
import {Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import {OutlineButton, PrimaryButton} from "../lib/button/Button";

export function CloseFormModal({onClose, isOpen}: ChakraModalProps) {
  const navigate = useNavigate();

  const handleCloseForm = () => {
    navigate(-1);
    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Закрыть форму?
            </Text>
            <Text fontSize={16} lineHeight="24px" fontWeight={400} color="#5C657E" align="center">Будут потеряны все
              введённые на странице данные. Вы уверены?</Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={handleCloseForm}>
              Да
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
