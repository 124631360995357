import { Button, ButtonProps, MenuButton } from "@chakra-ui/react";

export function PrimaryMenuButton(props: Readonly<ButtonProps>) {
  return (
    <MenuButton
      as={Button}
      px={6}
      py={3}
      colorScheme="red"
      fontWeight={500}
      _disabled={{ color: "#8089A2", bg: "#E3E7EF" }}
      {...props}
    >
      {props.children}
    </MenuButton>
  );
}

export function OutlineMenuButton(props: Readonly<ButtonProps>) {
  return (
    <MenuButton
      as={Button}
      px={6}
      py={3}
      variant="outline"
      color="#E8363D"
      borderColor="#FAD7D8"
      fontWeight={500}
      _active={{ bg: "#FAD7D8", borderColor: "#F1868B" }}
      _disabled={{ color: "#AAB0C1", border: "1px solid #E3E7EF" }}
      {...props}
    >
      {props.children}
    </MenuButton>
  );
}
