import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { ReportsEndpoints } from "../endpoints";
import {
  ICreateReportContractRequest,
  ICreateReportEstimateRequest,
  IReportDocumentListResponse,
} from "./types";

export const getReportContractsList =
  (): AxiosPromise<IReportDocumentListResponse> =>
    axiosInstance.get(ReportsEndpoints.REPORT_CONTRACT + "/contract-list");

export const createContractReport = (
  params: ICreateReportContractRequest
): AxiosPromise<undefined> => {
  return axiosInstance.post(
    ReportsEndpoints.REPORT_CONTRACT + "/request",
    params
  );
};

export const getReportEstimatesList =
  (): AxiosPromise<IReportDocumentListResponse> =>
    axiosInstance.get(ReportsEndpoints.REPORT_ESTIMATE + "/estimate-list");

export const createEstimateReport = (
  params: ICreateReportEstimateRequest
): AxiosPromise<undefined> => {
  return axiosInstance.post(
    ReportsEndpoints.REPORT_ESTIMATE + "/request",
    params
  );
};

export const createLzkReport = (): AxiosPromise<undefined> =>
  axiosInstance.post(ReportsEndpoints.REPORT_LIMIT_CARD + "/request");
