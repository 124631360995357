import { useState, useRef } from "react";
import classNames from "classnames";
import { ReactComponent as Plus } from "../../images/svg/accordion/plus.svg";
import { ReactComponent as Minus } from "../../images/svg/accordion/minus.svg";
import styles from "./accordion.module.scss";
import {IQuestionTextBlock} from "../../api/faqs/types";

interface AccordionProps {
  content: IQuestionTextBlock[];
}

export function Accordion({ content }: AccordionProps) {
  const [active, setActive] = useState<boolean>(false);
  const contentBlock = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<string>("0px");

  const toggleAccordion = () => {
    setActive(!active);
    setHeight(active ? "0px" : `${contentBlock?.current?.scrollHeight}px`);
  };

  const accordionClass = classNames(styles.accordion, {
    [styles.active]: active,
  });

  return (
    <div className={styles.wrapper}>
      <div className={accordionClass} onClick={toggleAccordion}>
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: content[0].data.text}} />
        <div className={styles.icon}>{active ? <Minus /> : <Plus />}</div>
      </div>
      <div
        ref={contentBlock}
        style={{ maxHeight: `${height}` }}
        className={styles.content}
      >
        {content.slice(1).map((item, index) => (
          <div key={index} className={styles.text} dangerouslySetInnerHTML={{ __html: item.data.text }} />
        ))}
      </div>
    </div>
  );
}
