import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { AccessActsEndpoints, Files } from "../endpoints";
import { IAccessActsResponse, ISingleAccessActResponse, IUpdateAccessActRequest } from "./types";
import { ICommonResponse } from "../commonResponseType";

export const getAccessActs = (): AxiosPromise<IAccessActsResponse> =>
  axiosInstance.get(AccessActsEndpoints.ACCESS_ACTS);

export const getSingleAccessAct = (
  id: number
): AxiosPromise<ISingleAccessActResponse> =>
  axiosInstance.get(AccessActsEndpoints.SINGLE_ACCESS_ACT + `/${id}`);

export const editAccessAct = (
  params: IUpdateAccessActRequest,
  id: number
): AxiosPromise<ICommonResponse> => {
  return axiosInstance.post(
    AccessActsEndpoints.SINGLE_ACCESS_ACT + `/${id}`,
    params
  );
};