import { Box, Flex } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ArchiveTable } from "../../components/ArchiveTable/ArchiveTable";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

export function ArchivePage() {
  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Title>Архив договоров</Title>
        <ArchiveTable />
        <Box>
          <OnboardingByPermission permission={AccessRights.Contract_RO} />
        </Box>
      </Flex>
    </MainLayout>
  );
}
