import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { accessActsReducer } from "./slices/accessActs";
import { authReducer } from "./slices/auth";
import { contractsReducer } from "./slices/contracts";
import { directionLettersReducer } from "./slices/directionLetters";
import { estimatesReducer } from "./slices/estimates";
import { invoicesReducer } from "./slices/invoices";
import { limitFenceCardsSliceReducer } from "./slices/limitFenceCards";
import { onboardingReducer } from "./slices/onboarding";
import { paymentsReducer } from "./slices/payments";
import { profileReducer } from "./slices/profile";
import { savingsCompensationActsReducer } from "./slices/savingsCompensationActs";
import { workCompletedActsReducer } from "./slices/workCompletedActs";
import { filesReducer } from "./slices/files";
import { additionalRequestsReducer } from "./slices/additionalRequests";
import { reportReducer } from "./slices/reports";
import { remarksReducer } from "./slices/remarks";
import { requirementsReducer } from "./slices/requirements";
import { reconciliationReducer } from "./slices/reconciliationStatement";
import { signingReducer } from "./slices/signing";
import { settlementsReducer } from "./slices/settlements";
import { mainInfoReducer } from "./slices/mainInfo";
import { tooltipsReducer } from "./slices/tooltips";
import { supplierReducer } from "./slices/supplier";
import { vatsReducer } from "./slices/vats";

export const store = configureStore({
  reducer: {
    accessActs: accessActsReducer,
    auth: authReducer,
    contracts: contractsReducer,
    directionLetters: directionLettersReducer,
    estimates: estimatesReducer,
    invoices: invoicesReducer,
    limitFenceCards: limitFenceCardsSliceReducer,
    onboarding: onboardingReducer,
    payments: paymentsReducer,
    profile: profileReducer,
    savingsCompensationActsReducer: savingsCompensationActsReducer,
    workCompletedActs: workCompletedActsReducer,
    files: filesReducer,
    additionalRequests: additionalRequestsReducer,
    report: reportReducer,
    remarks: remarksReducer,
    requirements: requirementsReducer,
    reconciliation: reconciliationReducer,
    signing: signingReducer,
    settlements: settlementsReducer,
    mainInfo: mainInfoReducer,
    tooltips: tooltipsReducer,
    supplier: supplierReducer,
    vats: vatsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['files/fetchSingleFile/fulfilled'],
        ignoredPaths: ['files.currentFile'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function isError(action: AnyAction) {
  return action.type.endsWith("rejected");
}
