import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    Box,
    Flex,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../store";

interface MultiselectMenuProps {
    value: string | undefined;
    onValueChange: (arg: string) => void
}

export function VatsCellComponent({value, onValueChange}: MultiselectMenuProps) {
    const { vats } = useAppSelector((state) => state.vats);;

    const [currentVat, setCurrentVat] = useState<string>(value ?? "")

    const { isOpen, onToggle, onClose } = useDisclosure();
    const ref = useRef(null);
    useOutsideClick({ ref, handler: onClose });

    const handleVatSelect = (vat: string) => {
        setCurrentVat(vat);
        onValueChange(vat);
        onClose();
    }

    useEffect(() => {
        value && setCurrentVat(value)
    },[value])


    return (
        <Accordion
            backgroundColor="#FFFFFF"
            color="#5C657E"
            borderRadius="8px"
            position="relative"
            marginLeft="-1px"
            ref={ref}
        >
            <AccordionItem border="none" position="relative">
                <h2>
                    <AccordionButton border="1px solid #EBEEF5" p={0} cursor="default" onClick={onToggle} >
                        <Flex
                            margin="10px 12px"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box fontWeight={400} as="span" flex="1" textAlign="left">
                                <Text color="#343B4C">{currentVat ? `${currentVat}` : "Без НДС"}</Text>
                            </Box>
                            {isOpen ? (
                                <ChevronUpIcon boxSize={6} />
                            ) : (
                                <ChevronDownIcon boxSize={6} />
                            )}
                        </Flex>
                    </AccordionButton>
                </h2>
                {isOpen && (
                    <Box
                        width="100%"
                        bg="white"
                        zIndex="1000000"
                        overflowY="auto"
                        maxHeight="300px"
                        borderRadius="6px"
                        border="1px solid #E3E7EF"
                        padding="4px"
                        marginTop="2px"
                        sx={{
                            boxShadow: "0px 8px 24px 0px #1F222A0D",
                        }}
                    >
                        <Flex>
                            {vats.length > 0 && (
                                <Flex w="100%" gap="2px" flexDirection="column">
                                    <Flex
                                        w="100%"
                                        h="39px"
                                        paddingLeft="16px"
                                        paddingRight="16px"
                                        paddingBottom="2px"
                                        borderBottom="1px solid #E3E7EF"
                                        alignItems="center"
                                        cursor="pointer"
                                        _hover={{
                                            cursor: "pointer",
                                            backgroundColor: "#F0F3FA",
                                        }}
                                        onClick={() => handleVatSelect("")}
                                    >
                                        <Text
                                            w="100%"
                                            color={
                                                currentVat === ""
                                                    ? "#2AB6A5"
                                                    : "#343B4C"
                                            }
                                            fontSize="16px"
                                            noOfLines={1}
                                            lineHeight="16px"
                                        >
                                            Без НДС
                                        </Text>
                                    </Flex>
                                    {vats.map((document) => (
                                        <Flex
                                            w="100%"
                                            h="39px"
                                            paddingLeft="16px"
                                            paddingRight="16px"
                                            paddingBottom="2px"
                                            borderBottom="1px solid #E3E7EF"
                                            alignItems="center"
                                            cursor="pointer"
                                            _hover={{
                                                cursor: "pointer",
                                                backgroundColor: "#F0F3FA",
                                            }}
                                            onClick={() => handleVatSelect(document)}
                                        >
                                            <Text
                                                w="100%"
                                                color={
                                                    currentVat === document
                                                        ? "#2AB6A5"
                                                        : "#343B4C"
                                                }
                                                fontSize="16px"
                                                noOfLines={1}
                                                lineHeight="16px"
                                            >
                                                {`${document}`}
                                            </Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                )}
            </AccordionItem>
        </Accordion>
    );
}