import { ReactComponent as Home } from "../../images/svg/sidebar/home.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ReactComponent as Purchase } from "../../images/svg/purchase/purchase.svg";
import { ReactComponent as Plus } from "../../images/svg/sidebar/plus.svg";
import { ReactComponent as Archive } from "../../images/svg/sidebar/archive.svg";
import { ReactComponent as Remark } from "../../images/svg/sidebar/remark.svg";
import { ReactComponent as Sign } from "../../images/svg/sidebar/sign.svg";
import { ReactComponent as Report } from "../../images/svg/sidebar/report.svg";
import { ReactComponent as Book } from "../../images/svg/sidebar/book.svg";
import { ReactComponent as PaymentSettlement } from "../../images/svg/sidebar/settlement.svg";
import { ISidebarItem } from "../types/sidebarTypes";

export enum AccessRights {
  Contract_RO = 'contract_ro',
  Contract_RW = 'contract_rw',
  WorkCompletedAct_RO = 'work_completed_act_ro',
  WorkCompletedAct_RW = 'work_completed_act_rw',
  AccessAct_RW = 'access_act_rw',
  Payment_RW = 'payment_rw',
  LetterGuarantee_RW = 'letter_guarantee_rw',
  Invoice_RW = 'invoice_rw',
  Purchase_RW = 'purchase_rw',
  Requirement_RW = 'requirement_rw',
  ConstructionControlNote_RW = 'construction_control_note_rw',
  TransferNote_RW = 'transfer_note_rw',
  Signing_RW = 'signing_rw',
  MutualSettlement_RW = 'mutual_settlement_rw',
  ReportContract_RW = 'report_contract_rw',
  ReportEstimate_RW = 'report_estimate_rw',
  ReportLimitCard_RW = 'report_limit_card_rw',
  ReconciliationStatement_RW = 'reconciliation_statement_rw'
}

export const sidebarContent: ISidebarItem[] = [
  {
    id: 111,
    href: "/main",
    title: "Главная",
    logo: Home,
    notice: 0,
    children: [],
  },
  {
    id: 1,
    href: "/my-documents",
    title: "Мои документы",
    logo: Document,
    notice: 0,
    children: [],
    permissions: [AccessRights.Contract_RO, AccessRights.Contract_RW,AccessRights.AccessAct_RW]
  },
  {
    id: 9,
    title: "Документы в работе",
    href: "/documents-in-progress",
    logo: Document,
    notice: 0,
    children: [],
    permissions: [AccessRights.WorkCompletedAct_RO, AccessRights.WorkCompletedAct_RW,
      AccessRights.Invoice_RW, AccessRights.Payment_RW
    ]
  },
  {
    id: 11,
    title: "Закупки",
    href: "/purchase",
    logo: Purchase,
    notice: 0,
    children: [],
    permissions: AccessRights.Purchase_RW
  },
  {
    id: 2,
    href: "/add-request",
    title: "Доп. запросы и Визы",
    logo: Plus,
    notice: 0,
    /* overdueNotice : 2, */
    children: [],
  },
  {
    id: 10,
    href: "/requirements-account",
    title: "Учёт требований",
    logo: Document,
    notice: 0,
    children: [],
    permissions: AccessRights.Requirement_RW
  },
  {
    id: 4,
    href: "/remarks",
    title: "Замечания",
    logo: Remark,
    notice: 0,
    permissions: [AccessRights.ConstructionControlNote_RW, AccessRights.TransferNote_RW],
    children: [
      {
        id: 41,
        title: "Повторные",
        href: "/remarks/repeated",
      },
      {
        id: 42,
        title: "При передаче",
        href: "/remarks/transmission",
      },
      {
        id: 43,
        title: "Из Стройконтроля",
        href: "/remarks/construction-control",
      },
      {
        id: 44,
        title: "Субподрядчики",
        href: "/remarks/subcontractors",
      },
    ],
  },
  {
    id: 5,
    href: "/sign",
    title: "Подписание",
    logo: Sign,
    notice: 0,
    permissions: AccessRights.Signing_RW,
    children: [],
  },
  {
    id: 12,
    href: "/payment-settlements",
    title: "Взаиморасчёты",
    logo: PaymentSettlement,
    notice: 0,
    permissions: AccessRights.MutualSettlement_RW,
    children: [],
  },
  {
    id: 6,
    href: "/report",
    title: "Отчёты",
    logo: Report,
    notice: 0,
    permissions: [AccessRights.ReportContract_RW, AccessRights.ReportEstimate_RW, AccessRights.ReportLimitCard_RW],
    children: [
      {
        id: 61,
        title: "Исполнение договоров",
        href: "/report/contracts-execution",
      },
      {
        id: 62,
        title: "Исполнение смет",
        href: "/report/estimates-execution",
      },
      {
        id: 63,
        title: "Исполнение по ЛЗК",
        href: "/report/PSC-execution",
      },
    ],
  },
  {
    id: 8,
    href: "/archive",
    title: "Архив договоров",
    logo: Archive,
    notice: 0,
    children: [],
    permissions: [AccessRights.Contract_RO, AccessRights.Contract_RW]
  },
  /*{
    id: 7,
    href: "/instructions",
    title: "Инструкции",
    logo: Book,
    notice: 0,
    children: [
      {
        id: 71,
        title: "Инструкции",
        href: "/instructions",
      },
    ],
    isDisabled: true,
  }, */
];
