import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Title } from "../lib/title/Title";
import { DocumentListTable } from "../DocumentListTable/DocumentListTable";
import { workingDocumentationInfo } from "../../shared/mock/workingDocumentationInfo";
import { RootState, useAppSelector } from "../../store";

interface WorkingDocumentationModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export function WorkingDocumentationModal(
  props: WorkingDocumentationModalProps
) {
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  return (
    <Drawer onClose={props.onClose} isOpen={props.isOpen}>
      <DrawerOverlay backdropFilter="blur(3px)" />
      <DrawerContent borderLeftRadius="16px" maxW="640px">
        <DrawerHeader p={8} color="#1F222A" display="flex" gap={4}  justifyContent="space-between">
          <Flex direction="column" gap={2}>
            <Title lineHeight="20px">Рабочая документация</Title>
            <Text
              fontSize="16px"
              fontWeight={400}
              lineHeight="20px"
              color="#5C657E"
            >
              {currentContract?.name}
            </Text>
          </Flex>
          <DrawerCloseButton position="static" />
        </DrawerHeader>

        <DrawerBody px={8}>
          <Flex direction="column" gap={6}>
            <SimpleGrid
              columns={2}
              p="4px 24px"
              bg="#F6F8FC"
              borderRadius="8px"
              spacingX={4}
            >
              <Flex
                direction="column"
                justify="center"
                h="66px"
                borderBottom={"1px solid #E3E7EF"}
              >
                <Text color="#5C657E" fontSize="14px" lineHeight="18px">
                  Коммерческое название объекта
                </Text>
                <Text color="#343B4C" fontSize="16px" lineHeight="20px">
                  {currentContract?.object_commercial_name}
                </Text>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                h="66px"
                borderBottom={"1px solid #E3E7EF"}
              >
                <Text color="#5C657E" fontSize="14px" lineHeight="18px">
                  Участок
                </Text>
                <Text color="#343B4C" fontSize="16px" lineHeight="20px">
                  {currentContract?.plot}
                </Text>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                h="66px"
              >
                <Text color="#5C657E" fontSize="14px" lineHeight="18px">
                  Название объекта
                </Text>
                <Text color="#343B4C" fontSize="16px" lineHeight="20px">
                  {currentContract?.object_name}
                </Text>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                h="66px"
              >
                <Text color="#5C657E" fontSize="14px" lineHeight="18px">
                  ID объекта
                </Text>
                <Text color="#343B4C" fontSize="16px" lineHeight="20px">
                  {currentContract?.object_id}
                </Text>
              </Flex>
            </SimpleGrid>

            <Flex gap={4} direction="column">
              <Text
                fontSize="18px"
                fontWeight={500}
                lineHeight="18px"
                color="#1F222A"
              >
                Список документов
              </Text>
              <DocumentListTable />
              <Link color="#2AB6A5">Развернуть список</Link>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

