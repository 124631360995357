import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { StaffEndpoints } from "../endpoints";
import {
  ICreateStaffResponse,
  IEditStaffResponse,
  IGetStaffResponse,
} from "./types";
import { ICommonResponse } from "../commonResponseType";
import { downloadElement } from "../../helpers/downloadElement";

export const createStaff = (
  params: FormData
): AxiosPromise<ICreateStaffResponse> => {
  return axiosInstance.post(StaffEndpoints.STAFF, params);
};

export const getStaff = (id: number): AxiosPromise<IGetStaffResponse> => {
  return axiosInstance.get(StaffEndpoints.STAFF + `/${id}`);
};

export const editStaff = (
  params: FormData,
  id: number
): AxiosPromise<IEditStaffResponse> => {
  return axiosInstance.post(StaffEndpoints.STAFF + `/${id}`, params, {
    params: { _method: "PUT" },
  });
};

export const deleteStaff = (id: number): AxiosPromise<ICommonResponse> => {
  return axiosInstance.delete(StaffEndpoints.STAFF + `/${id}`);
};

export const downloadPowerAttorneyTemplate = () =>
  downloadElement(StaffEndpoints.DOWNLOAD, "power-attorney-template");
