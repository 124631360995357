import { Button, ButtonProps } from "@chakra-ui/react";

export function PrimaryButton(props: Readonly<ButtonProps>) {
  return (
    <Button
      px={6}
      py={3}
      fontWeight={500}
      colorScheme="red"
      _disabled={{ color: "#8089A2", bg: "#E3E7EF", pointerEvents: "none" }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

export function OutlineButton(props: Readonly<ButtonProps>) {
  return (
    <Button
      px={6}
      py={3}
      variant="outline"
      fontWeight={500}
      color="#E8363D"
      borderColor="#FAD7D8"
      _disabled={{ color: "#AAB0C1", border: "1px solid #E3E7EF", pointerEvents: "none", "& svg path": { fill: "#AAB0C1" } }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
