import { useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  Row,
  createColumnHelper,
} from "@tanstack/react-table";
import { Flex } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditFile } from "../../images/svg/documents/edit-file.svg";
import { formatAmount } from "../../helpers/formatAmount";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { ILetterGuarantees } from "../../api/contracts/types";
import { resetLetterGuarantees, setCurrentLetterGuarantees } from "../../store/slices/contracts";

const columnHelper = createColumnHelper<ILetterGuarantees>();

export function IndemnityLetterTable() {
  const { currentContractLettersGuarantees } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const dispatch = useAppDispatch()

  const handleEstimatesTableRowClick = (row: Row<ILetterGuarantees>) => {
    row.toggleSelected();
    if(row.original.status=="Утвержден" && !row.getIsSelected()){
      dispatch(setCurrentLetterGuarantees(row.original.guid))
    } else {
      dispatch(resetLetterGuarantees())
    }

  }

  const columns = useMemo<Array<ColumnDef<ILetterGuarantees, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue())
      }),

      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue()),
      }),
    ],
    []
  );

  return (
    <Table 
      rowClick={handleEstimatesTableRowClick}
      dblClickDisabled={true}
      data={currentContractLettersGuarantees}
      columns={columns}
    />
  );
}
