import {AxiosPromise} from "axios";
import {axiosInstance} from "../instance";
import {AdditionalRequestsEndpoints, Files} from "../endpoints";
import {
  IAdditionalRequestResponse,
  IAnswerAdditionalRequestRequest,
  ICreateAdditionalRequestRequest,
  ISingleAdditionalRequestResponse,
} from "./types";
import {ICommonResponse} from "../commonResponseType";
import {IFilesAttachResponse} from "../files/types";

export const getAdditionalRequests = (): AxiosPromise<IAdditionalRequestResponse> =>
  axiosInstance.get(AdditionalRequestsEndpoints.ADDITIONAL_REQUESTS);

export const getSingleAdditionalRequest = (
  id: string
): AxiosPromise<ISingleAdditionalRequestResponse> =>
  axiosInstance.get(
    AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}`
  );

export const approveAdditionalRequest = (id: string): AxiosPromise<ICommonResponse> =>
  axiosInstance.post(AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}` + AdditionalRequestsEndpoints.APPROVE);
export const answerAdditionalRequest = (id: string, params: IAnswerAdditionalRequestRequest): AxiosPromise<ICommonResponse> =>
  axiosInstance.post(AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}` + AdditionalRequestsEndpoints.ANSWER, params);

export const createAdditionalRequest = (id: string, params: ICreateAdditionalRequestRequest): AxiosPromise<ISingleAdditionalRequestResponse> =>
  axiosInstance.post(AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}` + AdditionalRequestsEndpoints.CREATE, params);

export const getAdditionalRequestFiles = (id: string): AxiosPromise<IFilesAttachResponse> =>
  axiosInstance.get(AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}` + Files.FILES);
