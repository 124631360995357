import { useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Table } from "../lib/table/Table";
import { Text } from "@chakra-ui/react";
import {FilterHeader} from "../Filter/FilterHeader";
import {Link, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {useAppSelector} from "../../store";
import {IAccessAct} from "../../api/accessActs/types";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";

interface IReconciliationActTable {
  makeTableWrap: () => void;
  wrap: boolean;
}

const columnHelper = createColumnHelper<IAccessAct>();

const TitleCell = (info: CellContext<IAccessAct, string>) => (
  <Text color="#2AB6A5" noOfLines={2}>
    <Link to={info.row.original.contract_link}>{info.row.original.contract_name}</Link>
  </Text>
);

const ProjectCell = (info: CellContext<IAccessAct, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);


export function ReconciliationActTable({makeTableWrap, wrap}: IReconciliationActTable) {
  const { currentContractAccessActs } = useAppSelector((state) => state.contracts);
  const navigate = useNavigate()

  function navigateToPage(id: number){
    navigate(`/my-documents/access-acts/${id}`);
  }

  const columns = useMemo<Array<ColumnDef<IAccessAct, string>>>(
    () => [
      columnHelper.accessor("act_creation_date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("act_number", {
        header: "Номер",
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("a101_org_name", {
        header: "Организация",
      }),
      columnHelper.accessor("construction_manager", {
        header: "Руководитель строительства",
      }),
      columnHelper.accessor("contractor_exit_date", {
        header: "Дата выхода подрядчика",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
    ],
    []
  );

  return (
    <Table
      data={currentContractAccessActs}
      columns={columns}
      dblClickHandler={navigateToPage}
      maxHeight={wrap ? "auto" : "360px"}
      makeTableWrap={makeTableWrap}
    />
  );
}
