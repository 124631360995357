import { InformationBlock } from "../InformationBlock/InformationBlock";
import {
  InformationItem, InformationItemInputGroup,
} from "../InformationBlock/InformationItem";
import {AppDispatch, RootState, useAppSelector} from "../../store";
import {StatusCell} from "../StatusCell/StatusCell";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {ICreateLimitFenceCardRequest} from "../../api/limitFenceCards/types";
import {addSingleFenceCard, changeCurrentLimitFenceCard} from "../../store/slices/limitFenceCards";
import {useDispatch} from "react-redux";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, Box, Link, useDisclosure} from "@chakra-ui/react";
import dayjs from "dayjs";
import {CompensationActModal} from "../CompensationActModal/CompensationActModal";
import { numberWithDate } from "../../helpers/numberWithDate";
import { CheckIcon } from "@chakra-ui/icons";
import { CheckboxInput } from "../lib/input/CheckboxInput";

interface ISingleStockCardInformationBlock {
  onChangeData: (period_from: string, period_to: string, create_rp: boolean) => void;
  setDisableApprove: (value: boolean) => void;
}

export function SingleStockCardInformationBlock({onChangeData, setDisableApprove}: ISingleStockCardInformationBlock) {
  const dispatch = useDispatch<AppDispatch>();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ canChangeData, setCanChangeData ] = useState(true);

  const onSubmit = (values: ICreateLimitFenceCardRequest) => {};

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      contract_id: currentLimitFenceCard?.contract_id.toString() ?? currentContract?.id.toString() ?? "",
      period_from: "",
      period_to: "",
      comment: "",
      create_rp: false
    },
    onSubmit,
  });

  const {period_from, period_to, create_rp} = formik.values;

  const handleChangeDates = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDisableApprove(true);
    formik.handleChange(e)
  }

  useEffect(() => {
    if (currentLimitFenceCard) {
      (currentLimitFenceCard?.status !== "На согласовании (Подрядчик)" && currentLimitFenceCard?.status !== "Черновик") && setCanChangeData(false);
    }
  }, [currentLimitFenceCard, currentLimitFenceCard?.status]);
 

  useEffect(() => {
    setFieldValue("period_from", currentLimitFenceCard ? dayjs(currentLimitFenceCard?.period_from).format('YYYY-MM-DD') : "");
    setFieldValue("period_to", currentLimitFenceCard ? dayjs(currentLimitFenceCard?.period_to).format('YYYY-MM-DD') : "");
    setFieldValue("create_rp", currentLimitFenceCard ? currentLimitFenceCard.create_rp : "");
  }, [setFieldValue, currentLimitFenceCard]);

  useEffect(() => {
    onChangeData(period_from, period_to, create_rp);
    if(currentLimitFenceCard) {
      const updatedLimitFenceCard = {...currentLimitFenceCard, period_from: period_from, period_to: period_to, create_rp: create_rp};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }
  }, [period_from, period_to, onChangeData, create_rp]);

  const handleRpCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisableApprove(true);
    setFieldValue("create_rp", e.target.checked);
  };

  return (
    <>
      <InformationBlock>
        <InformationItem title="Договор" isAccordion={canChangeData}>
          <Link color="#2AB6A5" href={`/my-documents/contracts/${currentLimitFenceCard ? currentLimitFenceCard.contract_id : currentContract?.id}`}>
            {currentLimitFenceCard?.contract_name ?? currentContract?.name}
          </Link>
        </InformationItem>
        <InformationItem title="Создать РП">
          {
            canChangeData ? (
              <CheckboxInput
                label=""
                name="create_rp"
                value={formik.values.create_rp}
                onChange={handleRpCheckboxChange}
              />
            ) : (
              <>
                {currentLimitFenceCard?.create_rp ? (
                  <CheckIcon color="#8089A2" />
                ) : (
                  <></>
                )}
              </>

            )
          }
        </InformationItem>

        <InformationItem title="Статус">
        <StatusCell value={currentLimitFenceCard?.status ?? ""} />
      </InformationItem>
      <InformationItem title="Номер">{numberWithDate(currentLimitFenceCard?.number, currentLimitFenceCard?.limit_card_created_at)}</InformationItem>
      <InformationItem title="Организация">
        {currentLimitFenceCard?.a101_org_name ?? currentContract?.a101_org_name}
        </InformationItem>
        <InformationItem title="Контрагент">{currentLimitFenceCard?.organization_name ?? currentContract?.organization.org_name}</InformationItem>
        <InformationItem title="Проект">{currentLimitFenceCard?.project_name ?? currentContract?.project?.name}</InformationItem>
        <InformationItem title="ССОМ">
          {currentLimitFenceCard?.ssom}
        </InformationItem>
        <InformationItem title="Период" tooltipKey="limit-card_create-n-view_period">
          {canChangeData ?
            (
              <InformationItemInputGroup>
                <InformationItemDateInput
                  id="period_from"
                  name="period_from"
                  value={formik.values.period_from}
                  onChange={handleChangeDates}
                  placeholder="С"
                  isDisabled={!canChangeData}
                  isInvalid={formik.values.period_from === ''}
                />
                <InformationItemDateInput
                  id="period_to"
                  name="period_to"
                  value={formik.values.period_to}
                  onChange={handleChangeDates}
                  placeholder="По"
                  isDisabled={!canChangeData}
                  isInvalid={formik.values.period_to === ''}
                />
              </InformationItemInputGroup>
            ) : (
              <>
                {dayjs(currentLimitFenceCard?.period_from).format('DD.MM.YYYY')} — {dayjs(currentLimitFenceCard?.period_to).format('DD.MM.YYYY')}
              </>
            )
          }
        </InformationItem>
        <InformationItem title="Объекты строительства">
          {currentLimitFenceCard?.construction_objects ?? currentContract?.short_description_construction_object}
        </InformationItem>
        <InformationItem title="Исполнитель">
          {currentLimitFenceCard?.performer_full_name ?? currentContract?.executor}
        </InformationItem>
        <InformationItem title="Ответственный">
          {currentLimitFenceCard?.responsible_full_name}
        </InformationItem>
        <InformationItem title="Заявка на закупку">

          {currentLimitFenceCard?.supplier_guid ? (
            <CheckIcon color="#8089A2" />
          ) : (
            <></>
          )}
        </InformationItem>
        {currentLimitFenceCard?.supplier_guid ? (
          <>
            <InformationItem title="ИНН поставщика">
              {currentLimitFenceCard?.supplier_inn}
            </InformationItem>
            <InformationItem title="Название поставщика">
              {currentLimitFenceCard?.supplier_name}
            </InformationItem>
            <InformationItem title="Название поставщика">
              {currentLimitFenceCard?.supplier_kpp}
            </InformationItem>
            <InformationItem title="Счет поставщика">
              {`${currentLimitFenceCard.supplier_account} от ${currentLimitFenceCard.supplier_account_date_from ? dayjs(currentLimitFenceCard?.supplier_account_date_from).format('DD.MM.YYYY') : ""}`}
            </InformationItem>
          </>

        ) : (<></>)}
      </InformationBlock>
      <CompensationActModal isInsideCreate={true} isOpen={isOpen} onClose={onClose} />
    </>
  );
}
