import { InputHTMLAttributes } from "react";
import { ReactComponent as Search } from "../../../images/svg/search/search.svg";
import classNames from "classnames";
import styles from "./input.module.scss";

export enum InputThemes {
  Primary = "Primary",
  High = "High",
  Disabled = "disabled",
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: InputThemes;
  error?: boolean;
  search?: boolean;
  small?: boolean;
}

export function Input(props: InputProps) {
  const {
    value = "",
    name = "",
    onChange,
    onBlur,
    label = "",
    type = "text",
    className = InputThemes,
    id,
    error = false,
    search = false,
    small = false,
    ...rest
  } = props;

  const inputClass = classNames(styles.input_container, {
    [styles.modal_input_container]: className === InputThemes.High,
    [styles.disabled]: className === InputThemes.Disabled,
    [styles.error]: error,
    [styles.small]: small,
  });

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.container}>
        <div className={inputClass}>
          {search && (
            <div className={styles.search_icon}>
              <Search />
            </div>
          )}
          <input
            id={id}
            type={type}
            name={name}
            defaultValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={className === InputThemes.Disabled}
            autoComplete="new-password"
            {...rest}
          />
        </div>
      </div>
    </div>
  );
}
