import { Text } from "@chakra-ui/react";

interface StatusCellProps {
  value: string;
  color?: string;
}

export function StatusCell(props: Readonly<StatusCellProps>) {
  return (
    props.value && (
      <Text
        fontSize={14}
        py={2}
        px={3}
        bg={props.color ? props.color : "#FEE7AC"}
        borderRadius={6}
        lineHeight="14px"
        position="absolute"
        top="50%"
        transform="translate(0, -50%)"
      >
        {props.value}
      </Text>
    )
  );
}
