import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "../../lib/button/Button";
import { Modal, ModalProps } from "../Modal";
import { AuthTitle } from "../../AuthTitle/AuthTitle";
import { PasswordInput } from "../../lib/input/PasswordInput";
import { CustomLink } from "../../lib/customLink/CustomLink";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import { IPasswordChangeRequest } from "../../../api/auth/types";
import { changeUserPassword } from "../../../store/slices/auth";
import styles from ".././modal.module.scss";

type ChangePasswordModalProps = Omit<ModalProps, "children"> & {
  onSuccess: () => void;
};

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.[a-zA-Z]).{8,}$/;

export function ChangePasswordModal(props: ChangePasswordModalProps) {
  const { active, onClose, onSuccess } = props;
  const dispatch = useAppDispatch();
  const { changePasswordError } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const onSubmit = async (values: IPasswordChangeRequest) => {
    dispatch(changeUserPassword(values))
      .unwrap()
      .then(() => onSuccess())
      .catch((err) => console.error(err));
  };

  const formik = useFormik({
    initialValues: {
      password_old: "",
      password: "",
      c_password: "",
    },
    validationSchema: Yup.object({
      password_old: Yup.string().required("Заполните все поля"),
      password: Yup.string()
        .matches(
          PASSWORD_REGEX,
          "Пароль должен включать заглавную букву, цифру и быть не короче 8-ми символов"
        )
        .required("Заполните все поля"),
      c_password: Yup.string()
        .required("Заполните все поля")
        .oneOf([Yup.ref("password")], "Пароли не совпадают"),
    }),

    onSubmit,
  });

  const renderErrorMessage = () => {
    if (formik.touched.password_old && formik.errors.password_old) {
      return <ErrorMessage message={formik.errors.password_old} />;
    }
    if (formik.touched.password && formik.errors.password) {
      return <ErrorMessage message={formik.errors.password} />;
    }
    if (formik.touched.c_password && formik.errors.c_password) {
      return <ErrorMessage message={formik.errors.c_password} />;
    }
    if (changePasswordError) {
      return <ErrorMessage message={changePasswordError} />;
    }

    return <></>;
  };

  return (
    <Modal active={active} onClose={onClose}>
      <form
        className={styles.center_modal_container}
        onSubmit={formik.handleSubmit}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <AuthTitle title="Сменить пароль" subtitle="Придумайте новый пароль" />
        <div className={styles.input_block}>
          {renderErrorMessage()}

          <PasswordInput
            placeholder="Введите текущий пароль"
            name="password_old"
            value={formik.values.password_old}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordInput
            placeholder="Введите новый пароль"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordInput
            placeholder="Повторите новый пароль"
            name="c_password"
            value={formik.values.c_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <CustomLink content="Забыли текущий пароль?" href="/restore-password" />
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </form>
    </Modal>
  );
}
