import { useMemo } from "react";
import { useAppDispatch } from "../../store";
import { createColumnHelper, ColumnDef, Row } from "@tanstack/react-table";
import {
  fetchSingleEstimate,
  resetCurrentEstimate,
} from "../../store/slices/estimates";
import { Table } from "../lib/table/Table";
import { IEstimate } from "../../api/estimates/types";
import { formatAmount } from "../../helpers/formatAmount";

const columnHelper = createColumnHelper<IEstimate>();

interface EstimateTableProps {
  data: IEstimate[];
  setIsLoading: (isLoading: boolean) => void;
}

export function EstimateTable(props: Readonly<EstimateTableProps>) {
  const dispatch = useAppDispatch();

  const handleEstimatesTableRowClick = (row: Row<IEstimate>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrentEstimate());
    } else {
      props.setIsLoading(true);
      dispatch(fetchSingleEstimate(row.original.id))
        .then(() => {
          props.setIsLoading(false)
        })
        .catch(e => {
          console.error('Error: ', e);
          props.setIsLoading(false);
        });
    }
  };

  const columns = useMemo<Array<ColumnDef<IEstimate, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
      }),
      columnHelper.accessor("Document_Amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return (
    <Table
      data={props.data}
      columns={columns}
      dblClickDisabled={true}
      rowClick={handleEstimatesTableRowClick}
      maxHeight="370px"
    />
  );
}
