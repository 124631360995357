import {
    Flex,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
  } from "@chakra-ui/react";
  
   interface FullScreenWaitingModalProps {
      openModal: boolean
   }
  
  export function SuccessCopyModal(props:FullScreenWaitingModalProps) {
    return (
      <Modal isCentered isOpen={props.openModal} onClose={() => {}}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxWidth="641px" color="white" bg="none" boxShadow="none">
          <Flex w="641px" alignItems="center" flexDirection="column">
            <Text fontSize="14px" fontWeight="400">
              Ссылка скопирована
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    );
  }
  