import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Table } from "../lib/table/Table";
import { Flex, Text } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { IApplication } from "../../api/remarks/types";
import { RootState, useAppSelector } from "../../store";

const columnHelper = createColumnHelper<IApplication>();

const TitleCell = ({
  row: { original },
}: CellContext<IApplication, string>) => {
  return (
    <Flex justify="space-between" cursor="pointer" align="center" gap="8px">
      <Text>{original.name}</Text>
      <DownloadIcon color="#2AB6A5" />
    </Flex>
  );
};

export function FilesTable() {
  const { currentRemarkTransmission } = useAppSelector(
    (state: RootState) => state.remarks
  );

  const columns = useMemo<
    Array<ColumnDef<IApplication, string>>
  >(
    () => [
      columnHelper.accessor("name", {
        header: "Приложения",
        cell: TitleCell,
      }),
    ],
    []
  );
  return (
    <Table
      dblClickDisabled={true}
      data={currentRemarkTransmission?.applications ?? []}
      columns={columns}
    />
  );
}
