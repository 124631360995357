import { Flex, Text } from "@chakra-ui/react";
import { BlockTitle } from "../../lib/title/Title";
import { FilesTable } from "../../FilesTable/FilesTable";
import { PrimaryButton } from "../../lib/button/Button";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from "react";
import { DropFileInputInSingleComponents } from "../../lib/input/drop-file-input/DropFileInputInSingleComponents";
import { IFile, IFilesAttachResponse } from "../../../api/files/types";
import {
  attachFilesToSingleAccessAct,
  fetchSingleAccessAct,
} from "../../../store/slices/accessActs";
import { Loader } from "../../Loader/Loader";
import { checkUploadFiles } from "../../../helpers/checkFiles";

export function FilesBlock() {
  const value: File[] = [];
  const { currentAccessAct } = useAppSelector(
    (state: RootState) => state.accessActs
  );
  const dispatch = useAppDispatch();
  const [deleteFile, setDeleteFile] = useState<File>();
  const [files, setFiles] = useState<IFile[]>();
  const [downloadFileList, setDownloadFileList] = useState<File[]>(value);
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false)

  function clearDeleteFiles() {
    setDeleteFile(undefined);
  }

  const handleFileInputChange = (files: File[]) => {
    setDownloadFileList(files);
  };

  function handleDelete(file: File) {
    if (file.lastModified) {
      setDeleteFile(file);
    }
  }

  const sendFiles = () => {
    if (currentAccessAct) {
      setIsSpinnerRunning(true);
      dispatch(
        attachFilesToSingleAccessAct({
          id: currentAccessAct.id,
          files: downloadFileList,
        })
      )
        .then(() => {
          dispatch(fetchSingleAccessAct(currentAccessAct.id));
        })
        .finally(() => {
          setIsSpinnerRunning(false);
          setDownloadFileList([]);
          setIsSend(true)
        });
    }
    setIsSend(false)
  };

  useEffect(() => {
    if (checkUploadFiles(downloadFileList)) {
      setIsValidFile(false);
      setIsDisabledButton(true);
    } else if (downloadFileList.length === 0) {
      setIsValidFile(true);
      setIsDisabledButton(true);
    } else {
      setIsValidFile(true);
      setIsDisabledButton(false);
    }
  }, [downloadFileList]);

  useEffect(() => {
    setFiles(currentAccessAct?.files);
  }, [currentAccessAct?.files]);

  const canSendFiles = currentAccessAct?.status == "Рабочий"

  return (
    <>
      <Flex direction="column" gap={4}>
        <BlockTitle>Файлы</BlockTitle>
        {canSendFiles &&
        <DropFileInputInSingleComponents
          clearDeleteFiles={clearDeleteFiles}
          deleteFile={deleteFile}
          isDisabled={!canSendFiles}
          text="Перетащите сюда файл(ы)"
          name="attachments"
          onChange={handleFileInputChange}
          isSend={isSend}
        />
      }
        <FilesTable
          receivedFiles={files}
          filesList={downloadFileList}
          handleDelete={handleDelete}
        />
        {!isValidFile && (
          <Text color="red">Файлы не могут быть отправлены</Text>
        )}
        {canSendFiles &&
        
        <PrimaryButton
          onClick={sendFiles}
          isDisabled={isDisabledButton || currentAccessAct?.status != "Рабочий"}
          width="124px"
        >
          Отправить
        </PrimaryButton>
        }
      </Flex>
      <Loader isLoading={isSpinnerRunning} />
    </>
  );
}
