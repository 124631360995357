import { Center, Text } from "@chakra-ui/react";
import { BlockTitle } from "../lib/title/Title";

export function NoDataBlock() {
  return (
    <Center
      h="324px"
      gap={3}
      bg="white"
      border="1px solid #E3E7EF"
      borderRadius={8}
      flexDirection="column"
    >
      <BlockTitle>Пусто</BlockTitle>
      <Text fontSize={16} fontWeight={400} color="#5C657E">
        На данный момент информации нет
      </Text>
    </Center>
  );
}
