import { Table, TableContainer, Tbody } from "@chakra-ui/react";

interface InformationBlockProps {
  children?: JSX.Element | JSX.Element[];
}

export function InformationBlock(
  props: Readonly<InformationBlockProps>
): JSX.Element {
  return (
    <TableContainer
      h="full"
      bg="white"
      p="10px 24px"
      borderRadius="8px"
      border="1px solid #E3E7EF"
      whiteSpace="break-spaces"
    >
      <Table>
        <Tbody>{props.children}</Tbody>
      </Table>
    </TableContainer>
  );
}
