import { useCallback, useMemo, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import {
  Center,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { fetchUser } from "../../../store/slices/profile";
import { ReactComponent as DottActive } from "../../../images/svg/dott/dott-active.svg";
import { ReactComponent as DottUnactive } from "../../../images/svg/dott/dott-unactive.svg";
import { ReactComponent as Dotts } from "../../../images/svg/dott/dotts.svg";
import { AddEditModal } from "../../Modal/ModalRight/AddEditModal";
import { ConfirmDeleteModal } from "../../Modal/ModalCenter/ConfirmDeleteModal";
import { IProfileUser } from "../../../api/profile/types";
import styles from "./profile-table.module.scss";

const columnHelper = createColumnHelper<IProfileUser>();

const DataCell = (info: CellContext<IProfileUser, string>) => {
  return (
    <div className={styles.role_container}>
      {info
        .getValue()
        .split(",")
        .map((value: string) => (
          <span key={value} className={styles.role}>
            {value}
          </span>
        ))}
    </div>
  );
};

const StatusCell = (status: CellContext<IProfileUser, string>) => {
  return (
    <div className={styles.status_container}>
      {status.getValue() === "Активный" ? <DottActive /> : <DottUnactive />}
    </div>
  );
};

export function ProfileTable() {
  const users = useAppSelector(
    (state: RootState) => state.profile.profileData.profile?.users
  );
  const [editModalActive, setEditModalActive] = useState<boolean>(false);
  const [removeModalActive, setRemoveModalActive] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IProfileUser | null>(null);
  const dispatch = useAppDispatch();

  const handleOpenEditModal = useCallback(
    (user: IProfileUser): void => {
      dispatch(fetchUser(user.user_id));
      setCurrentUser(user);
      setEditModalActive(true);
    },
    [dispatch]
  );

  const handleOpenDeleteModal = (user: IProfileUser): void => {
    setCurrentUser(user);
    setRemoveModalActive(true);
  };

  const handleCloseEditModal = () => {
    setCurrentUser(null);
    setEditModalActive(false);
  };

  const handleCloseDeleteModal = () => {
    setCurrentUser(null);
    setRemoveModalActive(false);
  };

  const ActionsCell = useCallback(
    ({
      row: { original },
    }: CellContext<IProfileUser, unknown>): JSX.Element => (
      <Menu>
        <MenuButton w={10} h={8} as={IconButton} bg="none">
          <Center>
            <Dotts />
          </Center>
        </MenuButton>
        <MenuList
          w="200px"
          border="1px solid #E3E7EF"
          p={1}
          boxShadow="0px 8px 24px 0px #1f222a0d"
        >
          <MenuItem
            fontWeight={400}
            justifyContent="flex-start"
            gap={3}
            onClick={() => handleOpenEditModal(original)}
          >
            <EditIcon />
            <span>Редактировать</span>
          </MenuItem>
          <Divider my="2px" borderColor="#E3E7EF" />
          <MenuItem
            fontWeight={400}
            justifyContent="flex-start"
            gap={3}
            onClick={() => handleOpenDeleteModal(original)}
            color="#E8363D"
          >
            <DeleteIcon />
            <span>Удалить</span>
          </MenuItem>
        </MenuList>
      </Menu>
    ),
    [handleOpenEditModal]
  );

  const columns = useMemo<Array<ColumnDef<IProfileUser, string>>>(
    () => [
      columnHelper.accessor("surname", {
        header: "ФИО",
        cell: ({ row: { original } }) =>
          `${original.surname} ${original.name} ${original.patronymic}`,
      }),
      columnHelper.accessor((row) => row.email, {
        id: "email",
        header: "Email",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("phone_number", {
        header: "Телефон",
      }),
      columnHelper.accessor("position", {
        header: "Роль",
        cell: DataCell,
      }),
      columnHelper.accessor("last_activity_at", {
        header: "Активность",
        cell: (info) => {
          const activityDate = new Date(info.getValue())
            .toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
            .split(",")
            .join(" в");

          return activityDate;
        },
      }),

      columnHelper.accessor("status_activity", {
        header: "Статус",
        cell: StatusCell,
      }),

      {
        id: "actions",
        enableHiding: false,
        cell: ActionsCell,
      },
    ],
    [ActionsCell]
  );

  const table = useReactTable({
    data: users ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className={styles.table_container}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <span className={styles.inner_head_cell}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddEditModal
        active={editModalActive}
        onClose={handleCloseEditModal}
        title="Редактировать сотрудника"
        user={currentUser}
      />
      <ConfirmDeleteModal
        active={removeModalActive}
        onClose={handleCloseDeleteModal}
        id={currentUser?.user_id}
      />
    </>
  );
}
