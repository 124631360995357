import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../api";
import {createAsyncThunkInstance} from "../createAsyncThunkInstance";
import {isError} from "..";
import {ErrorType, ICommonInitialState} from "../../shared/types/storeTypes";
import {AxiosError} from "axios";
import {IFilesAttachResponse} from "../../api/files/types";
import {attachFiles} from "../../api/files";
import {AdditionalRequestsEndpoints, Files} from "../../api/endpoints";
import {
  IAdditionalRequest,
  IAdditionalRequestChatList,
  IAdditionalRequestResponse, IAnswerAdditionalRequestRequest, ICreateAdditionalRequestRequest,
  ISingleAdditionalRequestResponse
} from "../../api/additionalRequests/types";
import {answerAdditionalRequest, createAdditionalRequest} from "../../api/additionalRequests";
import {ICommonResponse} from "../../api/commonResponseType";

interface IInitialLimitFenceCardsState extends ICommonInitialState {
  additionalRequests: IAdditionalRequest[];
  currentChatList: IAdditionalRequestChatList[];
  currentAdditionalRequest: IAdditionalRequest | null;
}

const initialState: IInitialLimitFenceCardsState = {
  additionalRequests: [],
  currentChatList: [],
  currentAdditionalRequest: null,
  isLoading: false,
  error: null,
};

export const fetchAdditionalRequests = createAsyncThunkInstance<
  IAdditionalRequestResponse,
  undefined
>(
  "additionalRequests/fetchAdditionalRequests",
  api.additionalRequests.getAdditionalRequests
);

export const fetchSingleAdditionalRequest = createAsyncThunkInstance<
  ISingleAdditionalRequestResponse,
  string
>(
  "additionalRequests/fetchSingleAdditionalRequest",
  api.additionalRequests.getSingleAdditionalRequest
);

export const addAdditionalRequest = createAsyncThunk<
  ISingleAdditionalRequestResponse,
  { id: string, params: ICreateAdditionalRequestRequest },
  { rejectValue: AxiosError }
>(
  "additionalRequests/addAdditionalRequest",
  async ({id, params}, {rejectWithValue}) => {
    try {
      const {data} = await createAdditionalRequest(id, params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const approveSingleAdditionalRequest = createAsyncThunkInstance<
  ICommonResponse,
  string
>(
  "additionalRequests/approveSingleAdditionalRequest",
  api.additionalRequests.approveAdditionalRequest
);

export const answerSingleAdditionalRequest = createAsyncThunk<
  ICommonResponse,
  { id: string, params: IAnswerAdditionalRequestRequest },
  { rejectValue: AxiosError }
>(
  "additionalRequests/answerSingleAdditionalRequest",
  async ({id, params}, {rejectWithValue}) => {
    try {
      const {data} = await answerAdditionalRequest(id, params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const fetchAdditionalRequestFiles = createAsyncThunkInstance<
  IFilesAttachResponse,
  string
>(
  "additionalRequests/fetchAdditionalRequestFiles",
  api.additionalRequests.getAdditionalRequestFiles
);

export const attachFilesToSingleAdditionalRequest = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "additionalRequests/attachFilesToSingleAdditionalRequest",
  async ({id, files}, {rejectWithValue}) => {
    try {
      const {data} = await attachFiles(AdditionalRequestsEndpoints.ADDITIONAL_REQUEST + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);


const additionalRequestsSlice = createSlice({
  name: "additionalRequests",
  initialState,
  reducers: {
    resetCurrentRequest(state) {
      state.currentAdditionalRequest = null;
    },
    resetCurrentChatList(state) {
      state.currentChatList = [];
    },
    changeCurrentRequest(state, action) {
      state.currentAdditionalRequest = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchAdditionalRequests.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAdditionalRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.additionalRequests = action.payload.data;
      })

      .addCase(fetchSingleAdditionalRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAdditionalRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentChatList = action.payload.data;
      })
      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const additionalRequestsReducer = additionalRequestsSlice.reducer;
export const {resetCurrentRequest, changeCurrentRequest, resetCurrentChatList} = additionalRequestsSlice.actions;
