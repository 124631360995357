import { Flex, Text } from "@chakra-ui/react"

interface InformationBlockProps {
    title: string;
    count: number | undefined;
    onClick?: () => void;
}

export const InformationBlock = ({ title, count, onClick }: InformationBlockProps) => {
    return (
        <Flex
            sx={{
                "&:hover": {
                    cursor: "pointer"
                }
            }}
            onClick={onClick}
            gap="8px"
            flexDirection="column"
        >
            <Text lineHeight="16px" fontSize="12px">{title}</Text>
            <Text fontWeight={400} lineHeight="44px" fontSize="34px">{count}</Text>
        </Flex>
    )
} 