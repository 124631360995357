import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import {
  ICreateReportContractRequest,
    ICreateReportEstimateRequest,
    IReportDocument,
    IReportDocumentListResponse,
} from "../../api/reports/types";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { AxiosError } from "axios";
import { createContractReport, createEstimateReport } from "../../api/reports";

interface IReportsInitialState extends ICommonInitialState {
  contractsList: IReportDocument[];
  estimatesList: IReportDocument[];
}

const initialState: IReportsInitialState = {
  contractsList: [],
  estimatesList: [],
  isLoading: false,
  error: null,
};

export const fetchReportContractsList = createAsyncThunkInstance<
IReportDocumentListResponse,
  undefined
>("report/fetchReportContractsList", api.report.getReportContractsList);

export const fetchContractReport = createAsyncThunk<
  undefined,
  ICreateReportContractRequest,
  { rejectValue: AxiosError }
>(
  "report/fetchContractReport",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createContractReport(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const fetchReportEstimateList = createAsyncThunkInstance<
IReportDocumentListResponse,
  undefined
>("report/fetchReportEstimateList", api.report.getReportEstimatesList);

export const fetchEstimateReport = createAsyncThunk<
  undefined,
  ICreateReportEstimateRequest,
  { rejectValue: AxiosError }
>(
  "report/fetchContractReport",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createEstimateReport(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const fetchLzkReport = createAsyncThunkInstance<undefined, undefined>(
  "report/fetchLzkReport",
  api.report.createLzkReport
);

const reportSlice = createSlice({
  name: "savingsCompensationActs",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchReportContractsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReportContractsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractsList = action.payload.data;
      })
      .addCase(fetchReportEstimateList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReportEstimateList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.estimatesList = action.payload.data;
      })
  },
});

export const reportReducer = reportSlice.reducer;
