import { Box } from "@chakra-ui/react";

interface SubtitleProps {
  text: string;
}

export function Subtitle(props: Readonly<SubtitleProps>) {
  return (
    <Box
      w="full"
      fontSize="16px"
      fontWeight={400}
      textAlign="center"
      mb="8px"
      color="#5c657e"
    >
      <p>{props.text}</p>
    </Box>
  );
}
