import { SyntheticEvent } from "react";
import styles from "./input.module.scss";

export const enum StatusActivityValues {
  True = "true",
  False = "false",
}

interface RadioInputProps {
  label?: string;
  value?: string;
  onChange?: (e: SyntheticEvent) => void;
  name?: string;
}

export function RadioInput(props: RadioInputProps) {
  const { label, value, onChange, name = "" } = props;
  return (
    <div className={styles.radio_wrapper}>
      <label className={styles.label}>{label}</label>

      <div className={styles.radio_container}>
        <label className={styles.radio_label}>
          <input
            type="radio"
            value="true"
            name={name}
            checked={value === StatusActivityValues.True}
            onChange={onChange}
          />
          Активный
          <span />
        </label>
        <label className={styles.radio_label}>
          <input
            type="radio"
            value="false"
            name={name}
            checked={value === StatusActivityValues.False}
            onChange={onChange}
          />
          Неактивный
          <span />
        </label>
      </div>
    </div>
  );
}
