import React, { useEffect, useRef, useState } from "react";
import { Flex, Icon as ChakraIcon, Text, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ReactComponent as Edit } from "../../../images/svg/profile/edit.svg";
import styles from "./profileInfoCard.module.scss"
import { ChevronRightIcon } from "@chakra-ui/icons";
import { AllPositionModal } from "./AllPositionModal";

interface ProfileInfoCardProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
  isEditable?: boolean;
  сhangeNameModalActive?: () => void;
  сhangeEmailModalActive?: () => void;
}

export const ProfileInfoCard = (props: ProfileInfoCardProps) => {
  const { Icon, title, content, isEditable = false, сhangeNameModalActive, сhangeEmailModalActive } = props;
  const [ isCollapse, setIsCollapse] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const contentRef = useRef<HTMLParagraphElement>(null);
  const handleClickIcon = () => {
    if (title === "Гендиректор") {
      сhangeNameModalActive && сhangeNameModalActive();
    } else if (title === "E-mail") {
      сhangeEmailModalActive && сhangeEmailModalActive();
    }
  }


  useEffect(() => {
    if(title == "Должность" && contentRef.current){
      const parentNode = contentRef.current.parentNode?.parentNode as HTMLElement;
      const otherHeight = 32 + 30 // padding + title height
      setIsCollapse(contentRef.current?.clientHeight > (parentNode.clientHeight - otherHeight))
    }

  }, [contentRef])

  return (
    <>
      <Flex
        direction="column"
        gap={3}
        h="full"
        p="16px 24px"
        border="1px solid #e3e7ef"
        borderRadius="8px"
        bg="white"
        overflow="hidden"
      >
        <Flex align="center" gap={2}>
          <ChakraIcon as={Icon} w={5} h={5} />
          <Text fontWeight={500} lineHeight="20px" color="#8089A2">
            {title}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text className={isCollapse ? styles.collapseContent : ""} ref={contentRef}>{content}</Text>
          {isEditable && <ChakraIcon onClick={() => handleClickIcon()} as={Edit} w={5} h={5} cursor="pointer" />}
        </Flex>
        {isCollapse &&       
        <Box color="#2AB6A5">
          <Link onClick={() => setIsModalOpen(true)} to="#">
                  Посмотреть все <ChevronRightIcon />
          </Link>
        </Box>}
      </Flex>
      {isCollapse && <AllPositionModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} content={content}/>}
    </>
  );
};
