import { RootState, useAppSelector } from "../../store";
import classNames from "classnames";
import { Header } from "../Header/Header";
import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./main-layout.module.scss";

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { isSidebarClosed } = useAppSelector(
    (state: RootState) => state.profile.profileData
  );
  const layoutClass = classNames(styles.layout, {
    [styles.wide]: isSidebarClosed,
  });

  return (
    <>
      <Header />
      <Sidebar />
      <main className={layoutClass}>{props.children}</main>
    </>
  );
};
