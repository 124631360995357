import { Outlet } from "react-router-dom";
import { Flex, Spacer } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { RequireAuth } from "../../hoc/RequireAuth";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { myDocumentsPageTabs } from "../../shared/mock/myDocumentsPageData";

export const MyDocumentsPage = () => {
  return (
    <RequireAuth>
      <MainLayout>
        <Flex overflowY="scroll" maxH="100%" p={6} direction="column" gap={4} minH="full">
          <Title>Мои документы</Title>
          <LinkTabs tabs={myDocumentsPageTabs} />
          <Outlet />
          <Spacer />
        </Flex>
      </MainLayout>
    </RequireAuth>
  );
};
