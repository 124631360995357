import { useEffect, useMemo, useState } from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {
  createColumnHelper,
  CellContext,
  ColumnDef, Row,
} from "@tanstack/react-table";
import { Box, Center, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { AddIcon, AttachmentIcon, MinusIcon } from "@chakra-ui/icons";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ISingleContractWorkCompletedAct } from "../../api/contracts/types";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { fetchSingleWorkCompletedActs, resetCurrenWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { resetCurrentEstimate } from "../../store/slices/estimates";
import { ActsWithSigningTable } from "./ActsWithSingingTable";
import { getSigningFile } from "../../api/workCompletedActs";
import { AxiosResponse } from "axios";
import { Loader } from "../Loader/Loader";

const columnHelper = createColumnHelper<ISingleContractWorkCompletedAct>();

const SubRowTitleCell = (props: { value: string }) => (
  <Flex pl={5} gap={2}>
    <Center>
      <Document fill="#2AB6A5" />
    </Center>
    <Link color="#2AB6A5" href="#">
      {props.value}
    </Link>
  </Flex>
);

const TitleCell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => {
  const { row } = info;

  return row.depth === 0 ? (
    <Flex gap={3} justify="space-between" userSelect="none">
      <Flex alignItems="center">
        {row.getCanExpand() && (
          <Icon
            cursor="pointer"
            mr={2}
            fontSize="12px"
            as={info.row.getIsExpanded() ? MinusIcon : AddIcon}
            onClick={(e) => {
              e.stopPropagation()
              return info.row.toggleExpanded()
            }}
          />
        )}
        <Text flex={1} noOfLines={2}>
          {info.getValue()}
        </Text>
      </Flex>
      <Center gap={2} cursor="pointer" color="#2AB6A5">
        {row.original.has_files && <Icon as={AttachmentIcon} fill="#2AB6A5" />}
      </Center>
    </Flex>
  ) : (
    <SubRowTitleCell value={info.getValue()} />
  );
};

const StatusCell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => {
  
  return (
  <Text
    fontSize={14}
    py={2}
    px={3}
    bg="#469DDD40"
    borderRadius={6}
    display="inline-block"
    lineHeight={4}
  >
    {info.renderValue() === "Черновик ЛКП" ? "Черновик" : info.renderValue()}
  </Text>
)};

const SumSell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => <Box whiteSpace="nowrap">{ info.getValue() && formatAmount(info.getValue())}</Box>;

export function ActsTable() {
  const { currentContractWorkCompletedActs } = useAppSelector(
    (state) => state.contracts
  );
  const navigate = useNavigate();
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleActRowClick = (row: Row<ISingleContractWorkCompletedAct>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrenWorkCompletedAct());
      dispatch(resetCurrentEstimate())
    } else {
      !row.parentId && dispatch(fetchSingleWorkCompletedActs(row.original.id))
    }

    if (row && row.parentId) {
      if (row.original.id) {
        setIsSpinnerRunning(true);
        getSigningFile(row.original.id)
          .then((res: AxiosResponse<Blob>) => {
            if (res.data?.size) {
              const contentDisposition = res.headers["content-disposition"];
              let filename = "unknown";

              if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename=([^;]+)/);
                filename = filenameMatch[1];
              }

              const mimeType = res.data.type;
              const extension = mimeType.split("/")[1];

              const url = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/octet-stream" })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${filename}.pdf`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          })
          .finally(() => {
            setIsSpinnerRunning(false);
          });
      }
    }

  };

  const handleDblClick = (id: number, row?: Row<ISingleContractWorkCompletedAct>) => {
    if (row?.original.status === "Черновик ЛКП") {
      navigate(`/my-documents/contracts/work-completed-act/${row?.original.id}/draft`)
    } else {
      navigate(`/my-documents/contracts/work-completed-act/${row?.original.id}`)
    }
  }

  const sortedActs = [...currentContractWorkCompletedActs]
  .sort((a, b) => {
    const getDate = (name: string) => {
      const matchDate = name.match(/(\d{2}\.\d{2}\.\d{4} \d{1,2}:\d{2}:\d{2})/);
      if (matchDate) {
        const reversedDate = matchDate[0].replace(/\b\d\b/g, '0$&').replace(/^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4');
        return dayjs(reversedDate, "YYYY-MM-DD HH:mm:ss", true);
      }
      return null;
    };

    const dateA = getDate(a.name);
    const dateB = getDate(b.name);

    return dateB && dateA ? dateB.diff(dateA) : 0;
  });


  const columns = useMemo<
    Array<ColumnDef<ISingleContractWorkCompletedAct, string>>
  >(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
        id: "title",
        cell: TitleCell,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        header: "Статус",
        cell: StatusCell,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: SumSell,
      }),
    ],
    []
  );

  return (
    <>
      <ActsWithSigningTable data={sortedActs} columns={columns} rowClick={handleActRowClick} dblClickHandler={handleDblClick} />
      <Loader isLoading={isSpinnerRunning} />
    </>

  );
}
