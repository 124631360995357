import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    Box,
    Flex,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { setCurrentVat } from "../../store/slices/vats";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IDetail } from "../../api/estimates/types";
import { setCurrentDetails } from "../../store/slices/estimates";
import { ChakraModalProps } from "../../shared/types/modalProps";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { setWorkCompletedActDetails } from "../../store/slices/workCompletedActs";
import { IWorkCompletedActDetail } from "../../api/workCompletedActs/types";

interface MultiselectMenuProps {
    documents: string[];
    index?: number | null;
    isUpdateDocument?: boolean
}

export function VatsDropdownMenu(props: MultiselectMenuProps) {
    const { documents, isUpdateDocument = false } =
        props;
    const dispatch = useAppDispatch();
    const { currentContract } = useAppSelector((state) => state.contracts);
    const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);
    const [selectedVat, setSelectedVat] = useState<string>(currentWorkCompletedAct?.vat ?? "");
    const { currentDetails } = useAppSelector((state) => state.estimates);
    const { detailsWorkCompletedAct } = useAppSelector((state: RootState) => state.workCompletedActs);
    const [modalValue, setModalValue] = useState<string>("")

    const { isOpen, onToggle, onClose } = useDisclosure();
    const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
    const ref = useRef(null);
    useOutsideClick({ ref, handler: onClose });

    const handleDocumentSelect = (document: any) => {
        if (isUpdateDocument) {
            let needConfirm: boolean = false;
            detailsWorkCompletedAct.forEach((el) => {
                if (el.vat !== selectedVat && el.vat) {
                    needConfirm = true;
                }
            })

            if (needConfirm) {
                onOpenConfirm()
                setModalValue(document)
            } else {
                setSelectedVat(document);
                onClose();
            }
        } else {
            let needConfirm: boolean = false;
            currentDetails.forEach((el) => {
                if (el.vat !== selectedVat && el.vat) {
                    needConfirm = true;
                }
            })

            if (needConfirm) {
                onOpenConfirm()
                setModalValue(document)
            } else {
                setSelectedVat(document);
                onClose();
            }
        }
    };

    useEffect(() => {
        dispatch(setCurrentVat(selectedVat))

        if (isUpdateDocument) {
            const updatedMaterials: IWorkCompletedActDetail[] = detailsWorkCompletedAct.map((material) => {
                return {
                    ...material,
                    vat: selectedVat,
                };
            });
            dispatch(setWorkCompletedActDetails(updatedMaterials))
        } else {
            const updatedMaterials: IDetail[] = currentDetails.map((material) => {
                return {
                    ...material,
                    vat: selectedVat,
                };
            });
            dispatch(setCurrentDetails(updatedMaterials));
        }
    }, [selectedVat, dispatch]);

    useEffect(() => {
        if ((currentContract?.rate_vat === 20) || (currentContract?.rate_vat === 10)) {
            setSelectedVat(String(currentContract?.rate_vat.toFixed(2)))
        }
    }, [currentContract?.rate_vat])

    return (
        <Accordion
            backgroundColor="#FFFFFF"
            color="#5C657E"
            border="1px solid #EBEEF5"
            borderRadius="8px"
            position="relative"
            marginLeft="-1px"
            ref={ref}
        >
            <AccordionItem isDisabled={(currentContract?.rate_vat === 20) || (currentContract?.rate_vat === 10)} border={"1px solid #EBEEF5"}>
                <h2>
                    <AccordionButton p={0} cursor="default" onClick={onToggle} >
                        <Flex
                            margin="10px 12px"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box fontWeight={400} as="span" flex="1" textAlign="left">
                                <Text color="#343B4C">{selectedVat ? `${selectedVat}` : "Без НДС"}</Text>
                            </Box>
                            {isOpen ? (
                                <ChevronUpIcon boxSize={6} />
                            ) : (
                                <ChevronDownIcon boxSize={6} />
                            )}
                        </Flex>
                    </AccordionButton>
                </h2>
                {isOpen && (
                    <Box
                        position="absolute"
                        top="46px"
                        left="0"
                        width="100%"
                        bg="white"
                        zIndex="10"
                        overflowY="auto"
                        maxHeight="300px"
                        borderRadius="6px"
                        border="1px solid #E3E7EF"
                        padding="4px"
                        sx={{
                            boxShadow: "0px 8px 24px 0px #1F222A0D",
                        }}
                    >
                        <Flex >
                            {documents.length > 0 && (
                                <Flex w="100%" gap="2px" flexDirection="column">
                                    <Flex
                                        w="100%"
                                        h="39px"
                                        paddingLeft="16px"
                                        paddingRight="16px"
                                        paddingBottom="2px"
                                        borderBottom="1px solid #E3E7EF"
                                        alignItems="center"
                                        cursor="pointer"
                                        _hover={{
                                            cursor: "pointer",
                                            backgroundColor: "#F0F3FA",
                                        }}
                                        onClick={() => handleDocumentSelect("")}
                                    >
                                        <Text
                                            w="100%"
                                            color={
                                                selectedVat === ""
                                                    ? "#2AB6A5"
                                                    : "#343B4C"
                                            }
                                            fontSize="16px"
                                            noOfLines={1}
                                            lineHeight="16px"
                                        >
                                            Без НДС
                                        </Text>
                                    </Flex>
                                    {documents.map((document) => (
                                        <Flex
                                            w="100%"
                                            h="39px"
                                            paddingLeft="16px"
                                            paddingRight="16px"
                                            paddingBottom="2px"
                                            borderBottom="1px solid #E3E7EF"
                                            alignItems="center"
                                            cursor="pointer"
                                            _hover={{
                                                cursor: "pointer",
                                                backgroundColor: "#F0F3FA",
                                            }}
                                            onClick={() => handleDocumentSelect(document)}
                                        >
                                            <Text
                                                w="100%"
                                                color={
                                                    selectedVat === document
                                                        ? "#2AB6A5"
                                                        : "#343B4C"
                                                }
                                                fontSize="16px"
                                                noOfLines={1}
                                                lineHeight="16px"
                                            >
                                                {`${document}`}
                                            </Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                )}
            </AccordionItem>
            <ConfirmChangeModal value={modalValue} setSelectedVat={setSelectedVat} isOpen={isOpenConfirm} onClose={onCloseConfirm} />
        </Accordion>
    );
}

interface ConfirmChangeModalProps extends ChakraModalProps {
    setSelectedVat: (arg: string) => void;
    value: string
}

export const ConfirmChangeModal = ({ onClose, isOpen, setSelectedVat, value }: ConfirmChangeModalProps) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmit = () => {
        setSelectedVat(value);
        onClose();
    }
    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay backdropFilter="blur(3px)" />
                <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
                    <ModalHeader p={0}>
                        <Flex flexDirection="column" gap="12px" align="center">
                            <Text
                                fontSize="24px"
                                color="#343b4c"
                                lineHeight="28px"
                                letterSpacing="-1.5%"
                                fontWeight={500}
                                align="center">
                                Предупреждение
                            </Text>
                            <Text fontSize={16} fontWeight={400} color="#5C657E" align="center">Выбранная ставка применяется по всем строкам таблицы</Text>
                        </Flex>
                    </ModalHeader>
                    <ModalFooter p={0}>
                        <Flex w="100%" gap="12px" align="center">
                            <OutlineButton w="full" onClick={onClose}>
                                Отмена
                            </OutlineButton>
                            <PrimaryButton w="full" onClick={handleSubmit}>
                                Подтвердить
                            </PrimaryButton>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <FullScreenWaitingModal openModal={isLoading} />
        </>

    )
}