import { Accordion, AccordionButton, AccordionItem, Box } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import {
  InformationItem,
} from "../InformationBlock/InformationItem";
import { RootState, useAppSelector } from "../../store";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { formatAmount } from "../../helpers/formatAmount";
import { convertDate } from "../../helpers/convertDate";
import { numberWithDate } from "../../helpers/numberWithDate";

export function SingleCompensationActInformationBlock() {

  const { currCompAct } = useAppSelector(
    (state: RootState) => state.savingsCompensationActsReducer
  );
  return (
    <InformationBlock>
      <InformationItem title="Договор">
      <Accordion color="#AAB0C1" border="1px solid #EBEEF5" borderRadius="8px">
        <AccordionItem>
          <h2>
            <AccordionButton cursor="default">
              <Box as="span" flex='1' textAlign='left'>
                {currCompAct?.contract_name}
              </Box>
              <ChevronDownIcon />
            </AccordionButton>
          </h2>
        </AccordionItem>
        </Accordion>
      </InformationItem>
      <InformationItem title="Номер">{numberWithDate(currCompAct?.number, currCompAct?.date, true)}</InformationItem>
      <InformationItem title="Организация">
        {currCompAct?.a101_org_name}
      </InformationItem>
      <InformationItem title="Контрагент">{currCompAct?.organization_name}</InformationItem>
      <InformationItem title="Проект">{currCompAct?.project_name}</InformationItem>
      <InformationItem title="ССОМ">
        {currCompAct?.ssom}
      </InformationItem>
      <InformationItem title="Период" tooltipKey="saving-compensation-act_create-n-view_date">
        {
          currCompAct?.start_date && currCompAct?.end_date
            ? `${convertDate(currCompAct?.start_date)} — ${convertDate(currCompAct?.end_date)}`
            : ''
        }
      </InformationItem>
      <InformationItem title="Сумма компенсации">{formatAmount(currCompAct?.amount)}</InformationItem>
      <InformationItem title="Объекты строительства">
        {currCompAct?.description_construction_object}
      </InformationItem>
      <InformationItem title="Исполнитель">
        {currCompAct?.performer_full_name}
      </InformationItem>
      <InformationItem title="Ответственный">
        {currCompAct?.responsible_full_name}
      </InformationItem>
    </InformationBlock>
  );
}
