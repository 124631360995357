import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuOptionGroup,
  Checkbox,
  MenuList,
  Link,
  Divider,
  Button,
  MenuItem,
  Icon,
  Text,
} from "@chakra-ui/react";
import { ReactComponent as Filter } from "../../images/svg/documents/filter.svg";
import { Column } from "@tanstack/react-table";
import { MenuItemOptionProps } from "@chakra-ui/menu/dist/menu-item-option";
import { FunctionComponent, SVGProps, useState } from "react";

export const FilterHeaderMarker = (
  headerComponent: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }> | string,
  column: Column<any, any>,
  textTrue: string,
  textFalse: string
) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const handleChangeFiltered = (value: boolean) => {
    setIsFiltered(value);
  };

  return (
    <Flex gap="9px" justify="space-between" w="full" align="center">
      {typeof headerComponent === "string" ? (
        <Text>{headerComponent}</Text>
      ) : (
        <Icon w="19px" h="19px" as={headerComponent} />
      )}
      <Menu closeOnSelect={false}>
        <MenuButton>
          <Box position="relative">
            <Filter width={16} height={16} cursor="pointer" />
            {isFiltered && (
              <Box
                w={2}
                h={2}
                borderRadius="50%"
                border="2px solid white"
                bg="#E8363D"
                position="absolute"
                top={-1}
                right={-1}
              />
            )}
          </Box>
        </MenuButton>
        <MenuList
          minWidth="240px"
          border="1px solid #E3E7EF"
          boxShadow="0 0 1px 0 #1F222A05,0 2px 6px 0 #1F222A05, 0 8px 24px 0 #1F222A0D"
          p="4px"
          position="relative"
        >
          <MenuOptionGroup
            title="Фильтры"
            color="#343B4C"
            type="checkbox"
            fontWeight="500"
            fontSize={16}
            lineHeight="20px"
            textAlign="left"
            m="8px"
          >
            <Link
              as={Button}
              color="#2AB6A5"
              fontWeight="400"
              fontSize={14}
              lineHeight="18px"
              position="absolute"
              top="14px"
              right="12px"
              bg="transparent"
              p={0}
              height="auto"
              _hover={{ bg: "transparent", textDecoration: "underline" }}
              _focus={{ bg: "transparent", textDecoration: "underline" }}
              onClick={() => {
                column.setFilterValue("");
                setIsFiltered(false);
              }}
            >
              Сбросить
            </Link>
            <CustomMenuItem
              handleChangeFiltered={handleChangeFiltered}
              column={column}
              text={textTrue}
              isValue={true}
              isChecked={column.getFilterValue() === true}
            />
            <CustomMenuItem
              handleChangeFiltered={handleChangeFiltered}
              column={column}
              text={textFalse}
              isValue={false}
              isChecked={column.getFilterValue() === false}
            />
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

function CustomMenuItem(
  props: MenuItemOptionProps & {
    column: Column<any, string>;
    text: string;
    isValue: boolean;
    handleChangeFiltered: (value: boolean) => void;
  }
) {
  const { isValue, isChecked, column, text, handleChangeFiltered } = props;

  const handleClick = () => {
    if (isChecked) {
      column.setFilterValue("");
      handleChangeFiltered(false);
    } else {
      column.setFilterValue(isValue);
      handleChangeFiltered(true);
    }
  };

  return (
    <>
      <Divider color="#E3E7EF" />
      <MenuItem
        color="#343B4C"
        fontWeight="400"
        fontSize={14}
        lineHeight="18px"
        m="2px 0"
        p="8px"
        textAlign="left"
        onChange={handleClick}
      >
        <Checkbox
          colorScheme="teal"
          borderColor="#AAB0C1"
          isChecked={isChecked}
          borderRadius="full"
        >
          {text}
        </Checkbox>
      </MenuItem>
    </>
  );
}
