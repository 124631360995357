import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import classNames from "classnames";
import { downloadOnboardingPDF, downloadSingleScreenOnboardingPDF } from "../../api/onboarding";
import Header from "@editorjs/header";
/* @ts-ignore */
import List from "@editorjs/list";
/* @ts-ignore */
import Image from "@editorjs/image";
/* @ts-ignore */
import Embed from "@editorjs/embed";
import ImagesListTool from "../../helpers/imagesListTool";
import { CustomLink } from "../lib/customLink/CustomLink";
import { ReactComponent as ArrowRight } from "../../images/svg/arrows/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../images/svg/arrows/arrow-left.svg";
import { ReactComponent as LastItem } from "../../images/svg/check/check-tutorial.svg";
import FileLink from "../../images/svg/file/file.svg";
import styles from "./carousel.module.scss";
import { AccessRights } from "../../shared/mock/sidebarContent";

interface CarouselProps {
  data: OutputData[];
  handleClickLast: () => void;
  permission?: AccessRights | null;
}

export function Carousel(props: CarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isFirstSlide = currentIndex === 0;
  const ejInstance = useRef<EditorJS | null>(null);
  const isLastSlide = currentIndex === props.data.length - 1;

  const previousButtonClass = classNames(styles.button_item, {
    [styles.first_slide]: isFirstSlide,
  });
  const nextButtonClass = classNames(styles.button_item, {
    [styles.last_slide]: isLastSlide,
  });

  const handlePrevoiusButtonClick = () => {
    !isFirstSlide && setCurrentIndex((prev) => prev - 1);
  };
  const handleNextButtonClick = () => {
    !isLastSlide && setCurrentIndex((prev) => prev + 1);
  };

  const actualCount = `${currentIndex + 1} из ${props.data.length}`;

  const initEditor = useCallback(() => {
    const editor = new EditorJS({
      minHeight: 0,
      readOnly: true,
      inlineToolbar: ["link", "marker", "bold", "italic"],
      holder: "editorjs",
      onReady: () => {
        ejInstance.current = editor;
      },
      tools: {
        header: Header,
        list: List,
        image: Image,
        embed: Embed,
        images_list: ImagesListTool,
      },
      data: props.data[currentIndex],
    });
  }, [currentIndex, props.data]);

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
  }, [initEditor]);

  const onDownloadLinkClick = () => {
    if (props.permission && props.permission !== null) {
      downloadSingleScreenOnboardingPDF(props.permission)
    } else {
      downloadOnboardingPDF()
    }
  }

  return (
    <>
      <Box id="editorjs" h="full" w="full" overflow="overlay" />
      <div className={styles.footer}>
        <CustomLink
          content="Скачать в PDF"
          icon={FileLink}
          onClick={onDownloadLinkClick}
        />

        {!!props.data.length && (
          <>
            <div className={styles.count}>{actualCount}</div>
            <div className={styles.footer_buttons}>
              <div
                className={previousButtonClass}
                onClick={handlePrevoiusButtonClick}
              >
                <ArrowLeft />
              </div>
              <div className={nextButtonClass} onClick={handleNextButtonClick}>
                {!isLastSlide ? <ArrowRight /> : <LastItem onClick={props.handleClickLast} />}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
