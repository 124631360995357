import styles from "./input.module.scss";

interface CheckboxInputProps {
  label: string;
  value?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

export function CheckboxInput(props: CheckboxInputProps) {
  const { label, value, onChange}  = props;

  return (
    <div className={styles.checkbox_wrapper}>
      <input
        type="checkbox"
        className={styles.custom_checkbox}
        id="remember"
        checked={value}
        onChange={onChange}
      />
      <label htmlFor="remember">{label}</label>
    </div>
  );
}
