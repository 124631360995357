import { Box, Flex, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";
import { ColumnDef, ExpandedState, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getSortedRowModel, Row, RowSelectionState, useReactTable } from "@tanstack/react-table";
import { TableHTMLAttributes, useEffect, useRef, useState } from "react";
import { SortIcon } from "../SortIcon/SortIcon";
import { MemoizedFilter } from "../Filter/Filter";
import classNames from "classnames";

import styles from "../lib/table/table.module.scss"
import { useNavigate } from "react-router-dom";
import { NoMatches } from "../NoMatches/NoMatches";
import { SearchIcon } from "@chakra-ui/icons";

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
    data: Array<any>;
    columns: ColumnDef<any, string>[];
    rowClick?: (row: Row<any>) => void;
    onRowSelect?: (row: Row<any>) => void;
    dblClickDisabled?: boolean;
    dblClickHandler?: (id: number, row?: Row<any>) => void;
    loadingSpinner: boolean
}

export const SigningTableComponent = (props: Readonly<TableProps>) => {
    const {
        data,
        columns,
        rowClick,
        dblClickDisabled,
        dblClickHandler,
        loadingSpinner,
        ...rest
    } = props;
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [rowSelection, setRowSelection] = useState<any>();
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [tableWidth, setTableWidth] = useState<number>();
    const [columnsLength, setColumnsLength] = useState<number>(0);


    const table = useReactTable({
        data,
        columns,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableSubRowSelection: false,
        defaultColumn: {
            enableColumnFilter: false,
            enableSorting: false,
        },
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.items,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    });

    function handleRowDoubleClick<T>(id: number, row?: Row<T>): void {
        dblClickHandler ? dblClickHandler(id, row) : (!dblClickDisabled && navigate(`${id}`));
    }

    function handleRowClick<T>(row: Row<T>): void {
        if (rowClick) {
            rowClick(row);
            if (!row.parentId) {
                setRowSelection(!row.getIsSelected() ? row : null)
            }
        }
    }

    const checkTableSize = () => {
        if (tableRef.current) {
            setTableWidth(tableRef.current.offsetWidth - 64)
        }
    };

    useEffect(() => {
        checkTableSize();

        window.addEventListener('resize', checkTableSize);

        return () => {
            window.removeEventListener('resize', checkTableSize);
            setColumnsLength(0)
        };
    }, []);

    useEffect(() => {
        table.getHeaderGroups().map((el) =>
            setColumnsLength((prevLength) => prevLength + el.headers.length)
        )
    }, [table])
    const [filterValue, setFilterValue] = useState<string | number>('');
    const handleFilterChange = (value: any) => {
        setFilterValue(value);
        const newExpanded: ExpandedState = {};

        if (value.trim() !== "") {
            const allRows = table.getRowModel().rows;
            allRows.forEach((row: Row<any>) => {
                if (row.original.items) {
                    const childMatch = row.original.items.some((item: any) =>
                        item.diadoc_name?.toLowerCase().includes(value.toLowerCase())
                    );
                    if (childMatch) {
                        newExpanded[row.id] = true;
                    } else if (row.original.diadoc_name?.toLowerCase().includes(value.toLowerCase())) {
                        newExpanded[row.id] = true;
                    }
                }
            });
        } else {
            setExpanded({});
        }

        setExpanded(newExpanded);
        table.setGlobalFilter(value);
    };

    const renderTableBody = () => {
        if (loadingSpinner) {
            return (
                <tr className={styles.tr__withoutHover}>
                    <td colSpan={table.getHeaderGroups()[0].headers.length}>
                        <Flex
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            height="510px"
                        >
                            <Spinner size="xl" />
                        </Flex>
                    </td>
                </tr>
            );
        }
        return table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
                <tr
                    key={row.id}
                    className={classNames({
                        [styles.checked]:
                            (rowSelection && row.id === rowSelection.id) ? true : false,
                        [styles.isChild]:
                            (row.original.isChild) ? true : false
                    })}
                    onClick={() => handleRowClick(row)}
                    onDoubleClick={() => {
                        if (!row.parentId) {
                            handleRowDoubleClick(row.original.id, row);
                        }
                    }}
                >
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className={cell.column.columnDef.meta?.cellClassName && styles[cell.column.columnDef.meta?.cellClassName]}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))
        ) : (
            <tr className={styles.tr__withoutHover}>
                <td colSpan={columnsLength}>
                    <div style={{
                        width: tableWidth,
                    }}>
                        <NoMatches />
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <Box overflowX="scroll" overflowY="scroll" ref={tableRef}>
            <div className={styles.main_table_container} style={{
                maxWidth: "max-content",
            }} {...rest}>
                <div className={styles.main_table_container_container} style={{
                    minHeight: "300px"
                }}>
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            <Flex
                                                gap="10px"
                                                justify="space-between"
                                                className={styles.inner_head_cell}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                {header.column.getCanSort() && (
                                                    <div onClick={header.column.getToggleSortingHandler()}>
                                                        <SortIcon sort={header.column.getIsSorted()} />
                                                    </div>
                                                )}
                                                {header.column.getCanFilter() && (
                                                    <MemoizedFilter column={header.column} table={table} />
                                                )}
                                                {
                                                    header.column.id === "diadoc_name" && (
                                                        <InputGroup maxW="320px" size="sm">
                                                            <InputLeftElement>
                                                                <SearchIcon />
                                                            </InputLeftElement>
                                                            <Input
                                                                value={filterValue}
                                                                onChange={(e) => handleFilterChange(e.target.value)}
                                                                rounded="md"
                                                                borderColor="#E3E7EF"
                                                                placeholder="Поиск"
                                                            />
                                                        </InputGroup>

                                                    )
                                                }
                                            </Flex>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody>
                            {renderTableBody()}
                        </tbody>
                    </table>
                </div>
            </div>
        </Box>
    )
}