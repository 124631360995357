import {Box, Flex} from "@chakra-ui/react";
import {ChevronRightIcon} from "@chakra-ui/icons";
import { BlockTitle } from "../lib/title/Title";
import { ReconciliationActTable } from "./ReconciliationActTable";
import { Link } from "react-router-dom";
import { useState } from "react";

export function ReconciliationActBlock() {
  const [canWrap, setCanWrap] = useState<boolean>(false);
  const [wrap, setWrap] = useState<boolean>(false);

  const makeTableWrap = (): void => {
    setCanWrap(true);
  }

  const toggleTableWrap = (): void => {
    setWrap(!wrap);
  }

  return (
    <Flex gap={4}>
      <Flex direction="column" gap={4} flex={1} width="100%">
        <BlockTitle>Акты допуска</BlockTitle>
        <ReconciliationActTable makeTableWrap={makeTableWrap} wrap={wrap} />
        <Flex justify="space-between" color="#2AB6A5">
          {canWrap && (
            <Link to="#" onClick={toggleTableWrap}>{`${wrap ? "Свернуть" : "Развернуть"} список`}</Link>
          )}
          <Box marginLeft="auto">
            <Link to="/my-documents/access-acts">
              Перейти в раздел <ChevronRightIcon />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
