import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { Flex, Link, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { StatusCell } from "../StatusCell/StatusCell";
import { useNavigate } from "react-router-dom";
import { numberWithDate } from "../../helpers/numberWithDate";
import { ChangeModal } from "../../hoc/ChangeModal";
import { useState } from "react";
import { Input } from "../lib/input/Input";
import { useFormik } from "formik";
import { PrimaryButton } from "../lib/button/Button";
import { Modal } from "../Modal/Modal";
import { editAccessAct } from "../../store/slices/accessActs";
import * as Yup from "yup";

export function SingleAccessActInformationBlock() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentAccessAct } = useAppSelector(
    (state: RootState) => state.accessActs
  );
  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [statusModal, setStatusModal] = useState<boolean>(false);

  const onSubmit = () => {
    currentAccessAct &&
      dispatch(editAccessAct({params: formik.values, id: currentAccessAct?.id}))
        .then((res) => {
          if (res.type !== "accessActs/editAccessAct/rejected") {
            setStatusModal(true)
          }

        })
        .finally(() => {
          setChangeModal(false);
          formik.values.contractor_email = "";
        })
  };
  
  const formik = useFormik({
    initialValues: {
      contractor_email: "",
    },

    onSubmit,
  });

  return (
    <>
      <InformationBlock>
        <InformationItem title="Номер">
          {numberWithDate(
            currentAccessAct?.act_number,
            currentAccessAct?.act_creation_date
          )}
        </InformationItem>
        <InformationItem title="Статус">
          <StatusCell value={currentAccessAct?.status ?? ""} />
        </InformationItem>
        <InformationItem title="Организация">
          {currentAccessAct?.a101_org_name}
        </InformationItem>

        <InformationItem title="Договор">
          <Link
            onClick={() => {
              navigate(
                `/my-documents/contracts/${currentAccessAct?.contract_id}`
              );
            }}
            color="#2AB6A5"
          >
            {currentAccessAct?.contract?.name ?? ""}
          </Link>
        </InformationItem>
        <InformationItem title="Срок действия">
          {dayjs(currentAccessAct?.validity_term).format("DD.MM.YYYY")}
        </InformationItem>
        <InformationItem title="Проект">
          {currentAccessAct?.project?.name ?? ""}
        </InformationItem>
        <InformationItem title="Контрагент">
          {currentAccessAct?.counterparty ?? ""}
        </InformationItem>
        <InformationItem title="Руководитель строительства">
          {currentAccessAct?.construction_manager ?? ""}
        </InformationItem>
        <InformationItem title="Руководитель проекта">
          {currentAccessAct?.project_manager ?? ""}
        </InformationItem>
        <InformationItem title="Ответственный за стройконтроль">
          {currentAccessAct?.construction_control_responsible ?? ""}
        </InformationItem>
        <InformationItem
          title="Электронный адрес подрядчика"
          isEdited={currentAccessAct?.status === "Рабочий"}
          handleEditClick={() => setChangeModal(true)}
        >
          {currentAccessAct?.contractor_email ?? ""}
        </InformationItem>
        <InformationItem title="Дата выхода подряда">
          {dayjs(currentAccessAct?.contractor_exit_date).format("DD.MM.YYYY")}
        </InformationItem>
      </InformationBlock>
      <ChangeModal
        active={changeModal}
        onClose={() => {
          setChangeModal(false);
        }}
        title="Сменить электронный адрес подрядчика"
        children={
          <>
            <Input placeholder="Введите e-mail" name="contractor_email" value={formik.values.contractor_email} onChange={formik.handleChange} />
            <PrimaryButton isDisabled={formik.values.contractor_email === ""} onClick={onSubmit}>Сохранить</PrimaryButton>
          </>

        }
      />
      <Modal active={statusModal} onClose={() => setStatusModal(false)}>
        <Flex bgColor="#E3E7EF" padding="24px" flexDirection="column" textAlign="center" gap="12px" borderRadius="8px">
          <Text color="Готово" fontSize="20px" fontWeight={500}>Готово</Text>
          <Text color="#5C657E" fontSize="16px">E-mail успешно изменён</Text>
          <PrimaryButton onClick={() => setStatusModal(false)}>Закрыть</PrimaryButton>
        </Flex>
      </Modal>
    </>
  );
}
