import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IDetail,
  IEstimate,
  IEstimatesResponse,
  IImportEstimateDetail,
  IImportEstimateResponse,
  ISingleEstimateResponse,
} from "../../api/estimates/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { TableState } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { importEstimate } from "../../api/estimates";

interface IEstimatesInitialState extends ICommonInitialState {
  estimates: IEstimate[];
  currentEstimate: IEstimate | null;
  currentDetails: IDetail[];
  tableState: TableState | null;
  currentImportEstimate: IImportEstimateDetail[];
  amountEstimate: number;
}

const initialState: IEstimatesInitialState = {
  estimates: [],
  currentEstimate: null,
  currentDetails: [],
  currentImportEstimate: [],
  tableState: null,
  amountEstimate: 0,
  isLoading: false,
  error: null,
};

export const fetchEstimates = createAsyncThunkInstance<
  IEstimatesResponse,
  number
>("estimates/fetchEstimates", api.estimates.getEstimates);

export const fetchSingleEstimate = createAsyncThunkInstance<
  ISingleEstimateResponse,
  number
>("estimates/fetchSingleEstimate", api.estimates.getSingleEstimate);

export const fetchSingleEstimateFromAct = createAsyncThunkInstance<
  ISingleEstimateResponse,
  string
>("estimates/fetchSingleEstimateFromAct", api.estimates.getSingleEstimateFromAct);

export const handleImportEstimate = createAsyncThunk<
  IImportEstimateResponse,
  { id: number; file: File },
  { rejectValue: AxiosError }
>(
  "estimates/handleImportEstimate",
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const { data } = await importEstimate(id, file);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const estimatesSlice = createSlice({
  name: "estimates",
  initialState,
  reducers: {
    resetCurrentEstimate(state) {
      state.currentEstimate = null;
    },
    resetCurrentImportEstimate(state) {
      state.currentImportEstimate = [];
    },
    setCurrentDetails(state, action) {
      state.currentDetails = action.payload;
    },
    changeEstimate(state, action ) {
      state.currentEstimate = action.payload;
    },
    setTableState(state, action){
      state.tableState = action.payload;
    },
    setAmountEstimate(state, action){}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstimates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEstimates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.estimates = action.payload.data;
      })
      .addCase(fetchSingleEstimate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleEstimate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentEstimate = action.payload.data;
      })
      .addCase(fetchSingleEstimateFromAct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleEstimateFromAct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentEstimate = action.payload.data;
      })
      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.error = action.payload.response?.data.message;
      });
  },
});

export const estimatesReducer = estimatesSlice.reducer;
export const { resetCurrentEstimate, setCurrentDetails, changeEstimate, setTableState, resetCurrentImportEstimate } =
  estimatesSlice.actions;
