import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../store";

const ComponentWithProps = (OriginalComponent: FunctionComponent) => {
    function NewComponent(props: any) {
        const [filesModal, setFilesModal] = useState<boolean>(false);
        const { id } = useParams();
        const navigate = useNavigate()
        const goBack = (url?: string) => {
            url ? navigate(url) : navigate(-1)
        };
        return <OriginalComponent {...props} filesModal={filesModal} setFilesModal={setFilesModal} id={id} goBack={goBack} dispatch={useAppDispatch()} navigate={navigate} />
    }
    return NewComponent;

}

export default ComponentWithProps;