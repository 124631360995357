import { useEffect, useMemo, useState } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { FilterHeader } from "../Filter/FilterHeader";
import { filterFunction } from "../../helpers/filterFunction";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { ReactComponent as Link } from "../../images/svg/link/link.svg";
import { ISigning } from "../../api/signing/types";
import { fetchSigningList } from "../../store/slices/signing";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { ContractCell } from "../ContractCell/ContractCell";
import { SuccessCopyModal } from "../Modal/SuccessCopyModal";
import { formatAmount } from "../../helpers/formatAmount";
import { SigningTableComponent } from "./SigningTableComponent";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

const completedArray = (array: ISigning[]) => {
    const updatedArray = array.map(obj => {
        if (!(obj.items.length > 1)) {
            return {
                ...obj,
                diadoc_name: obj.items[0].diadoc_name,
                diadoc_sign_url: obj.items[0].diadoc_sign_url,
                items: []
            };
        } else {
            return {
                ...obj,
                type: null,
                items: obj.items.map((item, index) => ({
                    ...item,
                    days_to_sign: obj.days_to_sign,
                    document: obj.document,
                    type: obj.type,
                    contract_id: obj.contract_id,
                    contract_name: obj.contract_name,
                    project_name: obj.project_name,
                    amount: obj.amount,
                    isChild: true
                })),
            };
        }
    });
    return updatedArray;
};

const columnHelper = createColumnHelper<ISigning>();

const DownloadCell = (info: CellContext<ISigning, string>) => {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    return (
        <Flex gap="16px">
            {info.row.original.diadoc_sign_url && (
                <Icon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Перейти в ДИАДОК"
                    as={EditPen}
                    cursor="pointer"
                    fill="#2AB6A5"
                    sx={{
                        "&:hover": {
                            opacity: 0.6
                        }
                    }}
                    onClick={() => {
                        window.open(`${info.row.original.diadoc_sign_url}`)
                    }}
                />
            )}
            {info.row.original.diadoc_sign_url && (
                <Icon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Скопировать сслыку"
                    as={Link}
                    cursor="pointer"
                    fill="#2AB6A5"
                    sx={{
                        "&:hover": {
                            opacity: 0.6
                        }
                    }}
                    onClick={() => {
                        navigator.clipboard.writeText(`${info.row.original.diadoc_sign_url}`)
                        setIsCopied(true)
                        setTimeout(() => {
                            setIsCopied(false)
                        }, 750)
                    }}
                />
            )}

            <SuccessCopyModal openModal={isCopied} />
        </Flex>
    )
};

const DeadlineHeader = () => (
    <Text textAlign="left">Дней на <br /> подписании</Text>
);

const ExpandCell = (info: CellContext<ISigning, string>) => (
    <Flex>
        {info.row.getCanExpand() && (
            <Icon
                cursor="pointer"
                mr={2}
                fontSize="12px"
                as={info.row.getIsExpanded() ? MinusIcon : AddIcon}
                onClick={(e) => {
                    e.stopPropagation()
                    return info.row.toggleExpanded()
                }}
            />
        )}
    </Flex>

);


const DiadocHeader = () => (
    <Text textAlign="left">Документ <br /> в Диадок</Text>
);

const TypeCell = (info: CellContext<ISigning, string>) =>
(
    <>
        {info.renderValue() !== null ? (
            <Text
                fontSize="14px"
                lineHeight="14px"
                py={2}
                px={3}
                color="#343B4C"
                bg="#FEE7AC"
                borderRadius="6px"
                display="inline-flex"
            >
                {info.renderValue()}
            </Text>
        ) : (
            <></>
        )}
    </>

)


export function SigningTable() {
    const dispatch = useAppDispatch()
    const [resultArray, setResultArray] = useState<ISigning[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { signingList } = useAppSelector(
        (state: RootState) => state.signing
    );

    useEffect(() => {
        dispatch(fetchSigningList())
        .finally(() => setIsLoading(false))
    }, [dispatch])

    useEffect(() => {
        setResultArray(completedArray(signingList))
    }, [signingList])

    const columns = useMemo(
        () => [
            columnHelper.accessor("expand", {
                header: "",
                cell: ExpandCell

            }),
            columnHelper.accessor("days_to_sign", {
                header: DeadlineHeader,
                enableSorting: true

            }),
            columnHelper.accessor("document", {
                header: "Документ",
                enableColumnFilter: true
            }),
            columnHelper.accessor("type", {
                header: (info) => FilterHeader(info.column, info.table, "Тип"),
                cell: TypeCell,
                filterFn: ({ original: { type } }, id, filterValues) =>
                    filterFunction({ value: type, filterValues }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("contract_name", {
                header: "Договор",
                cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
            }),
            columnHelper.accessor("project_name", {
                header: "Проект",
            }),
            columnHelper.accessor("amount", {
                header: "Сумма",
                cell: (info) => formatAmount(info.renderValue())
            }),
            columnHelper.accessor("diadoc_name", {
                header: DiadocHeader,
            }),
            {
                header: "",
                id: "download",
                cell: DownloadCell,
            },
        ],
        []
    );

    return (
        <SigningTableComponent loadingSpinner={isLoading} data={resultArray} columns={columns} dblClickDisabled={true} />
    );
}