import { useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  Row,
  createColumnHelper,
} from "@tanstack/react-table";
import { Flex, Icon } from "@chakra-ui/react";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { formatAmount } from "../../helpers/formatAmount";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchSinglePayment, resetCurrentPayment } from "../../store/slices/payments";
import { IPayment } from "../../api/payments/types";

const columnHelper = createColumnHelper<IPayment>();

export function PaymentTable() {

  const dispatch = useAppDispatch()

  const handlePaymentsTableRowClick = (row: Row<IPayment>) => {
    row.toggleSelected();
    if(row.original.status=="Рабочая" && !row.getIsSelected()){
      dispatch(fetchSinglePayment(row.original.guid))
    } else {
      dispatch(resetCurrentPayment())
    }
  }

  const { currentContractPayments } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const columns = useMemo<Array<ColumnDef<IPayment, string>>>(
    () => [
      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue()),
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue())
      }),

      columnHelper.accessor("project_name", {
        header: "Проект",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("executor", {
        header: (info) => FilterHeader(info.column, info.table, "Исполнитель"),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        }
      }),
    ],
    []
  );

  return <Table rowClick={handlePaymentsTableRowClick} dblClickDisabled={true} data={currentContractPayments} columns={columns} minHeight="250px"/>;
}
