import {Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import {OutlineButton, PrimaryButton} from "../lib/button/Button";
import {ChakraModalProps} from "../../shared/types/modalProps";

interface CloseAlertModalProps extends ChakraModalProps {
  closeDrawer?: () => void;
  setConfirmationLoss?: (arg:boolean) => void
}

export function CloseAlertModal({ onClose, isOpen, closeDrawer, setConfirmationLoss }: CloseAlertModalProps) {
  const handleCloseForm = () => {
    setConfirmationLoss && setConfirmationLoss(true)
    closeDrawer && closeDrawer();
    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              lineHeight="28px"
              color="#343b4c"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Данные не сохранены
            </Text>
            <Text fontSize={16} lineHeight="20px" fontWeight={400} color="#5C657E" align="center">Вы действительно хотите закрыть окно?<br />Все не сохранённые данные будут удалены.</Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={handleCloseForm}>
              Закрыть
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
