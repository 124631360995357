import React, {useMemo} from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  flexRender, getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import {Flex, Icon, Text} from "@chakra-ui/react";
import { ReactComponent as files } from "../../images/svg/file/file-preview.svg";
import { DownloadIcon } from "@chakra-ui/icons";
import styles from "./application_table.module.scss";
import { RootState, useAppSelector } from "../../store";
import { IApplication } from "../../api/remarks/types";
import { NoMatches } from "../NoMatches/NoMatches";

const columnHelper = createColumnHelper<IApplication>();

const TitleCell = ({ row: { original } }: CellContext<IApplication, string>) => {
    return (
        <Flex maxW="380px" align="center" gap="8px">
            <Icon as={files} />
            <Text p={0} noOfLines={1} color="#343B4C">{original.name}</Text>
        </Flex>
    );
};

const DownloadCell = ({ row: { original } }: CellContext<IApplication, string>) => {
  return (
    <DownloadIcon onClick={() => window.open(`${original.url}`, '_blank')} cursor="pointer" color="#2AB6A5" />
  );
};

export const AppTable: React.FC = () => {
  const { currentRemarkConstruction } = useAppSelector(
    (state: RootState) => state.remarks
  );
  const columns = useMemo<Array<ColumnDef<IApplication, string>>>(
    () => [
      columnHelper.accessor("name", {
        id: 'name-column',
        cell: TitleCell,
      }),
      columnHelper.display( {
        id: 'download-column',
        cell: DownloadCell,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: currentRemarkConstruction?.applications ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });
  
    return (
      <div className={styles.application_table}>
        <table width="100%">
          <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <NoMatches />
              </td>
            </tr>

          )}
          
          </tbody>
        </table>
      </div>
  );
};
