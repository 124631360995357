import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { IVatsResponse } from "../../api/vats/types";

interface IVatsInitialState extends ICommonInitialState {
  vats: string[];
  currentVat: string;
}

const initialState: IVatsInitialState = {
  vats: [],
  currentVat: "",
  isLoading: false,
  error: null,
};

export const fetchVatsList = createAsyncThunkInstance<IVatsResponse, undefined>(
  "vats/fetchVatsList",
  api.vats.getVats
);

const vatsSlice = createSlice({
  name: "vats",
  initialState,
  reducers: {
    setCurrentVat(state, action) {
      state.currentVat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVatsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchVatsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vats = action.payload.data;
      });
  },
});

export const vatsReducer = vatsSlice.reducer;

export const { setCurrentVat } = vatsSlice.actions;
