import { useMemo, useState } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Link } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import {FilterHeader} from "../Filter/FilterHeader";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { FilterDateRange } from "../Filter/FilterDateRange";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { filterFunction } from "../../helpers/filterFunction";
import { RootState, useAppSelector } from "../../store";
import { fetchSettlementPrintForm, fetchSettlementsList } from "../../store/slices/settlements";
import { ISettlement } from "../../api/settlements/types";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { getFullTime } from "../../helpers/getFullTime";
import { formatAmount } from "../../helpers/formatAmount";

const columnHelper = createColumnHelper<ISettlement>();

export function PaymentSettlementsTable() {
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false)
  const { downloadFile } = useUniversalDownloadFile();
 
  const DownloadCell = (info: CellContext<ISettlement, string>) => (
    <Link onClick={() => downloadFile(info.row.original.guid, setIsSpinnerRuning, fetchSettlementPrintForm)} color="#1677FF">Скачать</Link>
  );


  const { settlementsList } = useAppSelector(
    (state: RootState) => state.settlements
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("payment_date", {
        header: (info) => FilterDateRange(info.column, info.table, "Дата платежа"),
        cell: (info) => getFullTime(info.renderValue()),
        enableColumnFilter: true,
        filterFn: (row, id, filterValues) => { 
          const date = new Date(dayjs(reverseDate(row.original.payment_date)).format("YYYY-MM-DD"));
          const startDate = filterValues[0] ? filterValues[0] : '';
          const endDate = filterValues[1] ? filterValues[1] : ''; 
          return date >= startDate && date <= endDate;
        },
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("payment_number", {
        header: "Номер платежа",
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, info.cell.row.original.contract_id),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("type", {
        header: (info) => FilterHeader(info.column, info.table,  "Тип"),
        cell: (info) =>  StatusCellTable(info.renderValue(), "#AAE2DB"),
        enableColumnFilter: true,
        filterFn: ({ original: { type } }, id, filterValues) =>
          filterFunction({ value: type, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("payment_assignment", {
        header: "Назначение платежа",
      }),
      {
        header: "Скачать",
        id: "download",
        cell: DownloadCell,
      },
    ],
    []
  );

  return (
    <>
      <Table data={settlementsList} fetchFunction={fetchSettlementsList} columns={columns} dblClickDisabled={true} />
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </>

  );
}