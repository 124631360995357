import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  Flex,
  Menu,
  MenuList,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalOverlay, Text,
  useDisclosure, ModalHeader,
} from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { purchasePageTabs } from "../../shared/mock/purchasePageData";
import { OutlineButton, PrimaryButton } from "../../components/lib/button/Button";
import { useEffect, useState } from "react";
import { CompensationActModal } from "../../components/CompensationActModal/CompensationActModal";
import { PrimaryMenuButton } from "../../components/lib/menu/ChakraMenuButton";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ChakraMenuItem } from "../../components/lib/menu/ChakraMenuList";
import { ChakraModalProps } from "../../shared/types/modalProps";
import styles from "../../components/Modal/ModalRight/ModalHeader/modal-header.module.scss";
import { ReactComponent as Cross } from "../../images/svg/cross/cross.svg";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchDeleteLimitFenceCard, fetchLimitFenceCards, fetchLzkSuppliers, resetCurrentLimitFenceCard } from "../../store/slices/limitFenceCards";
import { resetCurrentContract } from "../../store/slices/contracts";
import { Input } from "../../components/lib/input/Input";
import { fetchSupplier, fetchSupplierByGuid } from "../../store/slices/supplier";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { SupplierDropdown } from "./SupplierDrowdown";
import { ILzkSuppliers } from "../../api/limitFenceCards/types";
import ErrorLogo from "../../images/svg/error/error.svg";
import { ConfirmDeleteModal } from "../../components/Modal/ModalCenter/ConfirmDelete";

export function PurchasePage() {
  const [isCompensationAct, setIsCompensationAct] = useState(false);
  const [isStockCards, setIsStockCards] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSupplierCompanyModalOpen, onClose: onSupplierCompanyModalClose, onOpen: onSupplierCompanyModalOpen } = useDisclosure();
  const { isOpen: isErrorModalOpen, onClose: onErrorModalClose, onOpen: onErrorModalOpen } = useDisclosure();
  const { currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const dispatch = useAppDispatch();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();

  const onCreateStockCard = () => {
    dispatch(resetCurrentLimitFenceCard());
    dispatch(resetCurrentContract());
    navigate(`${location.pathname}/create`);
  }

  useEffect(() => {
    setIsCompensationAct(location.pathname.includes('compensation-act'))
    setIsStockCards(location.pathname.includes('stock-cards'))
  }, [location])

  const handleCreateDirrectionLetter = () => {
    currentLimitFenceCard && dispatch(fetchLzkSuppliers(currentLimitFenceCard.id))
      .then((res: any) => {
        if (res.payload.data.length > 0) {
          onSupplierCompanyModalOpen()
        } else {
          onErrorModalOpen()
        }
      })
  }

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={4} p="6">
        <Flex justifyContent="space-between">
          <Title>Закупки</Title>
          {isStockCards &&
            <Flex gap="12px" align="center">
              <Menu>
                <PrimaryMenuButton rightIcon={<ChevronDownIcon />}>
                  Создать
                </PrimaryMenuButton>
                <MenuList border="1px solid #E3E7EF" zIndex={2} p={1}>
                  <ChakraMenuItem
                    onClick={handleCreateDirrectionLetter}
                    isDisabled={!currentLimitFenceCard || currentLimitFenceCard.status !== "Утверждена" || currentLimitFenceCard.create_rp}
                  >
                    Создать распорядительное письмо
                  </ChakraMenuItem>
                  <Divider />
                  <ChakraMenuItem
                    onClick={onCreateStockCard}
                  >
                    Создать новую ЛЗК
                  </ChakraMenuItem>
                </MenuList>
              </Menu>
              <OutlineButton isDisabled={!currentLimitFenceCard} onClick={() => navigate(`${location.pathname}/create`)}>Дублировать ЛЗК</OutlineButton>
              <PrimaryButton isDisabled={currentLimitFenceCard?.status !== "На согласовании (Подрядчик)"} onClick={onDeleteModalOpen}>Удалить</PrimaryButton>
            </Flex>
          }
          {isCompensationAct &&
            <PrimaryButton onClick={onOpen} >Создать</PrimaryButton>
          }

        </Flex>
        <LinkTabs tabs={purchasePageTabs} />
        <Outlet />
      </Flex>
      <CompensationActModal isAok={true} isInsideCreate={false} isOpen={isOpen} onClose={onClose} />
      <SupplierCompanyModal isOpen={isSupplierCompanyModalOpen} onClose={onSupplierCompanyModalClose} createInLzk={true} />
      <ErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} />
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        mainText="лимитно-заборную карту"
        deleteId={currentLimitFenceCard?.id ?? ""}
        deleteFunction={fetchDeleteLimitFenceCard}
        updateFunctionWithoutId={fetchLimitFenceCards}
        resetFunction={resetCurrentLimitFenceCard}
      />
    </MainLayout>
  );
}

export function SupplierCompanyModal({ onClose, isOpen, createInLzk }: ChakraModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [inn, setInn] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false);
  const [currentSupplier, setCurrentSupplier] = useState<ILzkSuppliers | null>(null)
  const { currentLzkSuppliers } = useAppSelector((state: RootState) => state.limitFenceCards);

  const handleSend = () => {
    if (!createInLzk) {
      setIsSpinnerRuning(true)
      dispatch(fetchSupplier(inn))
        .then((res) => {
          if (res.payload?.response?.status === 500) {
            setError(true)
          } else {
            setError(false)
            navigate("/purchase/order-letters/create")
          }
        })
        .finally(() => setIsSpinnerRuning(false))
    } else {
      setIsSpinnerRuning(true)
      currentSupplier && dispatch(fetchSupplierByGuid(currentSupplier?.guid))
        .then((res) => {
          if (res.payload?.response?.status === 500) {
            setError(true)
            navigate("/purchase/order-letters/create")
          } else {
            navigate("/purchase/order-letters/create")
            setError(false)
          }
        })
        .finally(() => setIsSpinnerRuning(false))
    }
  }

  useEffect(() => {
    return () => {
      setError(false)
    }
  }, [])


  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="520px" gap="24px" p="24px">
        <ModalHeader p={0}>
          <Flex justifyContent="space-between" align="center">
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}>
              Выберите компанию поставщика
            </Text>
            <span className={styles.cross_wrapper} onClick={onClose}>
              <Cross />
            </span>
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          {!createInLzk ? (
            <>
              <Input placeholder="Введите ИНН" name="inn" onChange={(e) => setInn(e.target.value)} />
              {error && <Text fontSize="14" color="#E8363D" marginTop="8px">Компания не найдена</Text>}
            </>
          ) : (
            <>
              <SupplierDropdown documents={currentLzkSuppliers} setCurrentSupplier={setCurrentSupplier} />
              {error && <Text fontSize="14" color="#E8363D" marginTop="8px">Поставщик не найден</Text>}
            </>

          )}

        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="12px" align="center" justifyContent="flex-end">
            <OutlineButton onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton isDisabled={!inn && !currentSupplier} onClick={handleSend}>
              Выбрать
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </Modal>
  );
}

const ErrorModal = ({ onClose, isOpen }: ChakraModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={ErrorLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
              Для выбранной ЛЗК нельзя создать распорядительное письмо. Обратитесь к координатору
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={onClose}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
