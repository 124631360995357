import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  createColumnHelper,
  ColumnDef,
  CellContext,
} from "@tanstack/react-table";
import { Box, Select, Text } from "@chakra-ui/react";
import { Table } from "../../lib/table/Table";
import { IDetail } from "../../../api/estimates/types";
import { formatAmount } from "../../../helpers/formatAmount";
import { formatWorkActNumbers } from "../../../helpers/formatWorkActNumbers";
import styles from "../../lib/input/input.module.scss";
import { setCurrentDetails } from "../../../store/slices/estimates";
import { estimateDetailsWithVat } from "../../../helpers/estimateDetailsWithVat";
import { VatsCellComponent } from "./VatCellComponents";

const columnHelper = createColumnHelper<IDetail>();

const WorkTypeCell = (info: CellContext<IDetail, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

const VatCell = (info: CellContext<IDetail, string>) => {
  const [value, setValue] = useState<string | undefined>(info.row.original.vat ?? "")
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const dispatch = useAppDispatch();

  const handleChange = (vat: string) => {
    const rowId = info.row.original.id;
    if (rowId !== undefined && currentDetails) {
      const updatedMaterials: IDetail[] = currentDetails.map((material) => {
        if (material.id === rowId) {
          return {
            ...material,
            vat: vat,
          };
        }
        return material;
      });

      dispatch(setCurrentDetails(updatedMaterials));
    }
  }


  useEffect(() => {
    setValue(info.row.original.vat ?? "")
  }, [info.row.original.vat])
  return (
    <VatsCellComponent value={value} onValueChange={handleChange} />
  );
}

export function BuildingObjectsTable() {
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const { currentContract } = useAppSelector((state) => state.contracts);
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);
  const withVat = !((currentContract?.rate_vat === 20) || (currentContract?.rate_vat === 10))
  const filteredDetails =  currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0)
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(setCurrentDetails(estimateDetailsWithVat(currentDetails.filter(detail => Number(detail.Theamount) !== 0), currentWorkCompletedAct?.details)))
  }, [])

  const columns = useMemo<Array<ColumnDef<IDetail, string>>>(
    () => [
      columnHelper.accessor("ConstructionObjectName", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("TypeofWorkName", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantityinthedocument", {
        header: "Количество",
        cell: (info) => formatWorkActNumbers(info.getValue()),
      }),
      columnHelper.accessor("Price", {
        header: "Цена, без НДС",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("Theamount", {
        header: "Сумма, без НДС",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  const columnsWithVat = useMemo<Array<ColumnDef<IDetail, string>>>(
    () => [
      columnHelper.accessor("ConstructionObjectName", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("TypeofWorkName", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantityinthedocument", {
        header: "Количество",
        cell: (info) => formatWorkActNumbers(info.getValue()),
      }),
      columnHelper.accessor("Price", {
        header: "Цена, без НДС",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("Theamount", {
        header: "Сумма, без НДС",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("vat", {
        header: "НДС",
        cell: VatCell,
      }),
    ],
    []
  );

  return <Table data={filteredDetails ?? []} columns={withVat ? columnsWithVat : columns} dblClickDisabled={true} />;
}
