import { useMemo } from "react";
import { CellContext, createColumnHelper, Row } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import {FilterHeader} from "../Filter/FilterHeader";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";
import { fetchRequirementsList, resetCurrentReq, setCurrentReq } from "../../store/slices/requirements";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IRequirement } from "../../api/requirements/types";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";

const columnHelper = createColumnHelper<IRequirement>();

const OrganizationCell = (info: CellContext<IRequirement, string>) => (
  <Text noOfLines={2}>{`${info.row.original.a101_org_name}, ${info.row.original.project_name}`}</Text>
);



export function RequirementsAccountTable() {

  const dispatch = useAppDispatch();
  const handleRowClick = (row: Row<IRequirement>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrentReq());
    } else {
      dispatch(setCurrentReq(row.original));

    }
  };
  const { requirementsList } = useAppSelector((state: RootState) => state.requirements);
  const columns = useMemo(
    () => [
      columnHelper.accessor("organization_name", {
        header: "Организация и проект",
        cell: OrganizationCell,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, info.cell.row.original.contract_id),
      }),
      columnHelper.accessor("type", {
        header: "Вид требований",
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table,  "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
      }),
      columnHelper.accessor("pay_before", {
        header: "Оплатить до",
        cell: (info) => info.renderValue() ? dayjs(info.renderValue()).format("DD.MM.YYYY") : "",
        enableSorting: true,
      }),
    ],
    []
  );

  return (
    <>
      <Table minHeight="300px" data={requirementsList} columns={columns} dblClickDisabled={true} fetchFunction={fetchRequirementsList} rowClick={handleRowClick} />
    </>
  );
}
