import { ITab } from "../../components/lib/tabs/Tabs";

export const purchasePageTabs: ITab[] = [
  {
    id: 1,
    href: "stock-cards",
    label: "Лимитно-заборные карты",
    // notice: 1,
  },
  { id: 2, href: "order-letters", label: "Распорядительные письма" },
  {
    id: 3,
    href: "compensation-act",
    label: "Акты об экономии/компенсации",
    // notice: 1,
  },
];

export const buildingObjects = `Испанские кварталы 2 очередь, Бунинские кварталы, Скандинавия, Испанские кварталы 2 очередь, Бунинские кварталы, Скандинавия, 
Испанские кварталы 2 очередь, Бунинские кварталы, Скандинавия`;

export const singleOrderLetterPageTabs: ITab[] = [
  { id: 1, label: "Страница документа" },
  { id: 2, label: "Материалы" },
  { id: 3, label: "Дополнительно" },
];

export const singleLimitCardTabs: ITab[] = [
  { id: 1, label: "Страница документа" },
  { id: 2, label: "Материалы" },
]

export const singleOrderLetterPageTabsWithoutMaterials: ITab[] = [
  { id: 1, label: "Страница документа" },
  { id: 2, label: "Дополнительно" },
];

export const orderLetterMenuList = [
  "КС-2",
  "Счёт",
  "Счёт-фактуры",
  "Отгрузочные документы",
  "Накопительная ведомость",
  "КС-3",
];
