import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {Files, InvoicesEndpoints} from "../endpoints";
import { IInvoicesResponse, ISingleInvoiceResponse } from "./types";

export const getInvoices = (): AxiosPromise<IInvoicesResponse> =>
  axiosInstance.get(InvoicesEndpoints.INVOICES);

export const getInvoicesByContract = (
  id: number
): AxiosPromise<IInvoicesResponse> =>
  axiosInstance.get(InvoicesEndpoints.INVOICES_BY_CONTRACT + `/${id}`);

export const getSingleInvoice = (
  id: string
): AxiosPromise<ISingleInvoiceResponse> =>
  axiosInstance.get(InvoicesEndpoints.SINGLE_INVOICE + `/${id}`);

export const approveInvoice = (
  id: string
): AxiosPromise<ISingleInvoiceResponse> =>
  axiosInstance.post(InvoicesEndpoints.SINGLE_INVOICE + `/${id}` + InvoicesEndpoints.APPROVE_INVOICE);
  