import { Flex, Icon } from "@chakra-ui/react";
import { PrimaryButton } from "../lib/button/Button";
import { BlockTitle } from "../lib/title/Title";
import { PaymentTable } from "./PaymentTable";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { DownloadFilesModal } from "../Modal/ModalRight/DownloadFilesModal";
import { RootState, useAppSelector } from "../../store";


export function PaymentBlock({ filesModal, setFilesModal }: any) {

  const { currentPayment } = useAppSelector(
    (state: RootState) => state.payments
  );


  return (
    <>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between" align="center">
          <BlockTitle>Оплата</BlockTitle>
          <PrimaryButton isDisabled={!currentPayment} onClick={() => setFilesModal(true)} leftIcon={<Icon as={Document} fill={currentPayment ? "#fff" : " #AAB0C1"} />}>
            Файлы
          </PrimaryButton>
        </Flex>
        <PaymentTable />
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={currentPayment ?? []}
        fileAttach={() => {}}
      />
    </>
  );
}
