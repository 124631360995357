import { ITab } from "../../components/lib/tabs/Tabs";
import { AccessRights } from "./sidebarContent";

export const remarksPageTabs: ITab[] = [
  {
    id: 1,
    href: "construction",
    label: "При строительстве",
    permission: AccessRights.ConstructionControlNote_RW,
    // notice: 3,
  },
  { id: 2,
    href: "transmission",
    label: "При передаче ОН",
    permission: AccessRights.TransferNote_RW
    // notice: 1,
  },
];
