import { Text, TextProps } from "@chakra-ui/react";

export function Title(props: Readonly<TextProps>) {
  return (
    <Text
      w="full"
      color="#343b4c"
      fontSize="24px"
      lineHeight="28px"
      fontWeight={500}
      {...props}
    >
      {props.children}
    </Text>
  );
}

export function BlockTitle(props: Readonly<TextProps>) {
  return (
    <Text
      fontSize="20px"
      color="#343b4c"
      lineHeight="24px"
      fontWeight={500}
      {...props}
    >
      {props.children}
    </Text>
  );
}
