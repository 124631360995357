import { Box, Flex } from "@chakra-ui/react"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { Title } from "../../components/lib/title/Title"
import { PaymentSettlementsTable } from "../../components/PaymentSettlementsTable/PaymentSettlementsTable"
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission"
import { AccessRights } from "../../shared/mock/sidebarContent"

export const PaymentSettlementPage = () => {
    return (
        <MainLayout>
            <Flex direction="column" gap={4} p="6">
                <Title>Взаиморасчёты</Title>
                <PaymentSettlementsTable />
                <Box>
                    <OnboardingByPermission permission={AccessRights.MutualSettlement_RW} />
                </Box>
            </Flex>
        </MainLayout>
    )
}   