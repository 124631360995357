import axios from "axios";
import { ShowNotification } from "../components/ShowNotificationModal/ShowNotificationModal";


export const axiosInstance = axios.create({
  baseURL: "https://a101-dev-backend.globus-ltd.com/api",
  headers: {
    "Access-Control-Allow-Methods": "*",
  },
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return(response)
  },
  (error) => {
    const errorStatus = error.response.status
    ShowNotification(errorStatus)
    return Promise.reject(error);
  });