import { Center, Flex, Image } from "@chakra-ui/react";
import { Title } from "../lib/title/Title";
import { Subtitle } from "../lib/subtitle/Subtitle";
import Search from "../../images/png/search.png";

export function NoMatches() {
  return (
    <Center flex={1}>
      <Flex direction="column" align="center" gap={4}>
        <Image boxSize="100px" src={Search} alt="Not found logo" />
        <Flex direction="column" align="center" justify="center" gap={3}>
          <Title textAlign="center">Пусто</Title>
          <Subtitle text="По вашему запросу ничего не найдено" />
        </Flex>
      </Flex>
    </Center>
  );
}
