import {Modal, ModalBody, ModalContent, ModalOverlay, Spinner} from "@chakra-ui/react";
import styles from "./loader.module.scss";

interface LoaderProps {
  isLoading: boolean;
}

export function Loader(props: LoaderProps) {
  return (
    <Modal isOpen={props.isLoading} onClose={() => {} } isCentered>
      <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
      />
      <ModalContent>
        <ModalBody className={styles.centered}>
          <div>Загрузка ...</div>
          <Spinner size='xl'/>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
